import { InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { FormikValues, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { KeyboardEvent } from "react";

interface Props {
  name: string;
  width?: string;
  value?: string | number;
  placeholder?: string;
  isTransparent?: boolean;
  isWhiteBg?: boolean;
  disabled?: boolean;
}

function FormPrice({
  name,
  width = "100%",
  isTransparent,
  isWhiteBg,
  placeholder,
  disabled,
  value,
}: Props) {
  const theme = useTheme();
  const { touched, errors, values, setFieldValue, setFieldTouched } =
    useFormikContext<FormikValues>();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (evt: any) => {
    const { value } = evt.target;
    if (value.match(/\./g)) {
      const [, decimal] = value.split(".");
      if (decimal?.length > 2) {
        return;
      }
    }
    setFieldValue(name, value);
  };

  const keyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "-" || e.key === "+") {
      e.preventDefault();
    }
  };

  return (
    <TextField
      sx={{
        borderTopLeftRadius: theme.shape.borderSizes[2],
        borderTopRightRadius: theme.shape.borderSizes[2],
        borderBottomLeftRadius: theme.shape.borderSizes[2],
        borderBottomRightRadius: theme.shape.borderSizes[2],
        width,
        "& .MuiOutlinedInput-root": {
          borderTopLeftRadius: theme.shape.borderSizes[2],
          borderTopRightRadius: theme.shape.borderSizes[2],
          borderBottomLeftRadius: theme.shape.borderSizes[2],
          borderBottomRightRadius: theme.shape.borderSizes[2],
          backgroundColor: isTransparent ? "transparent" : isWhiteBg ? "white.main" : "bg.primary",
        },
        "& .MuiOutlinedInput-input": {
          padding: "10px",
        },
      }}
      error={showError}
      variant="outlined"
      helperText={showError ? String(errors[name]) : null}
      onBlur={() => setFieldTouched(name)}
      name={name}
      type="number"
      placeholder={placeholder}
      onChange={handleChange}
      onKeyDown={(event) => keyPress(event)}
      value={value ? value : values[name] === null ? "" : values[name]}
      disabled={disabled}
      InputProps={{
        inputProps: { min: 0 },
        startAdornment: (
          <InputAdornment position="start" onClick={() => console.log(values[name])}>
            <Typography>RM</Typography>
          </InputAdornment>
        ),
      }}
    />
  );
}

FormPrice.defaultProps = {
  isTransparent: false,
  width: "100%",
  placeholder: "",
  disabled: false,
};

FormPrice.propTypes = {
  name: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormPrice;
