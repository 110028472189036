import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import ConfirmationModal from "../../../components/notification/confirmation-modal.component";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import theme from "../../../infrastructure/theme";
import {
  deleteHighlightDish,
  reorderHighlightDish,
} from "../../../services/highlight-dish/highlight-dish-slice.service";
import { useAppDispatch } from "../../../services/store";

interface Props {
  dish: {
    id: number;
    order: number;
    food: {
      id: number;
      identifier: string;
      type: string;
      status: string;
      price: string;
    };
  };
  length: number;
  checkBoxSelection: number[];
  setCheckBoxSelection: React.Dispatch<React.SetStateAction<number[]>>;
}

export default function HighlightDishTableRow({
  dish,
  length,
  checkBoxSelection,
  setCheckBoxSelection,
}: Props) {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [showMenu, setShowMenu] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);
  const tempLength = length - 1;

  const handleOpenMenu = (event: any) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const onDeleteHighlightDish = () => {
    dispatch(deleteHighlightDish({ ids: [dish.id] }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowDeleteConfirmation(false);
          handleCloseMenu();
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onReorderHighlightDish = (type: string) => {
    dispatch(reorderHighlightDish({ highlightDishId: dish.id, type }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          handleCloseMenu();
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <>
      <ConfirmationModal
        showModal={showDeleteConfirmation}
        setShowModal={setShowDeleteConfirmation}
        onConfirm={onDeleteHighlightDish}
        title="Delete highlight dish"
        message={`Are you sure you wish to remove "${dish.food.identifier}" as a highlight dish?`}
      />
      <TableRow
        key={dish.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "50px" }}>
          {isCheckBoxSelected ? (
            <IconButton
              onClick={() => {
                setIsCheckBoxSelected(false);
                setCheckBoxSelection(
                  [...checkBoxSelection].filter((checked) => checked !== dish.id),
                );
              }}
            >
              <CheckBoxIcon color="primary" />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setIsCheckBoxSelected(true);
                setCheckBoxSelection([...checkBoxSelection, dish.id]);
              }}
            >
              <CheckBoxOutlineBlankIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center" }}>{dish.order}</Typography>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center" }}>{dish.food.identifier}</Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {dish.food.type}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {dish.food.status}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center" }}>RM {dish.food.price}</Typography>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              sx={{ marginTop: theme.spacing(4) }}
              id="menu-appbar"
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem key={1} onClick={() => setShowDeleteConfirmation(true)}>
                <Typography textAlign="center">Delete</Typography>
              </MenuItem>
              {dish.order !== 0 && (
                <MenuItem key={2} onClick={() => onReorderHighlightDish("move_up")}>
                  <Typography textAlign="center">Move Up</Typography>
                </MenuItem>
              )}
              {dish.order !== tempLength && (
                <MenuItem key={3} onClick={() => onReorderHighlightDish("move_down")}>
                  <Typography textAlign="center">Move Down</Typography>
                </MenuItem>
              )}
              {dish.order !== 0 && (
                <MenuItem key={4} onClick={() => onReorderHighlightDish("move_to_first")}>
                  <Typography textAlign="center">Move to First</Typography>
                </MenuItem>
              )}
              {dish.order !== tempLength && (
                <MenuItem key={5} onClick={() => onReorderHighlightDish("move_to_bottom")}>
                  <Typography textAlign="center">Move to Bottom</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
