import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormAutoCompletePaginationMultiselect from "../../../components/forms/form-auto-complete-pagination-multiselect.component";
import FormMultipleSelect from "../../../components/forms/form-multiple-select.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/tables/table-header.component";
import TableLoader from "../../../components/tables/table-loader.component";
import TableSort from "../../../components/tables/table-sort.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import GreyBgCardContainer from "../../../components/utils/grey-bg-card-container.component";
import theme from "../../../infrastructure/theme";
import {
  getOrders,
  orderSelector,
} from "../../../services/order/order-user-subscription-slice.service";
import { useAppDispatch } from "../../../services/store";
import { getUsers, userSelector } from "../../../services/user/user-slice.service";
import OrderListTableRow from "../components/order-list-table-row.component";
import { OrderListsFilterOptionFormValues } from "../type/type";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)({
  width: "100%",
  borderRadius: theme.shape.borderSizes[1],
  overflow: "hidden",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  types: Yup.array().label("Types").of(Yup.string()).required(),
  status: Yup.array().label("Status").of(Yup.string()).required(),
  userIds: Yup.array().label("Users").nullable(),
});

const TYPES_LIST = [
  { id: "1", label: "Same Day", value: "same_day" },
  { id: "2", label: "Pre-Order", value: "preorder" },
];

const STATUS_LIST = [
  { id: "1", label: "Pending", value: "pending_payment" },
  { id: "2", label: "Ongoing", value: "ongoing" },
  { id: "3", label: "Completed", value: "completed" },
];

export default function OrderUserSubscriptionScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [types, setTypes] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [userId, setUserId] = useState<number[]>([]);
  const [page, setPage] = useState(1);
  const { getUsersObj } = useSelector(userSelector);
  const { getOrdersObj } = useSelector(orderSelector);
  const [totalPages, setTotalPages] = useState(1);
  const columnMapping = {
    Order: "id",
    User: "user.name",
    Type: "type",
    Status: "status",
    Package: "food_set.identifier",
    "Total Meal": "total_meals",
    Subtotal: "payment_summary.subtotal",
    "Outstanding Total": "payment_summary.outstanding_total",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshOrders = (
    newPage: number,
    newTypes: string[],
    newStatus: string[],
    newUserIds: number[],
  ) => {
    setPage(newPage);
    setTypes(newTypes);
    setStatus(newStatus);
    setUserId(newUserIds);
    dispatch(
      getOrders({
        page: newPage,
        types: newTypes,
        status: newStatus,
        userIds: newUserIds,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTotalPages(res.data.pagination.totalPages);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const submitFilter = (values: OrderListsFilterOptionFormValues) => {
    const userId = values.userIds.map((item) => item.id);
    onRefreshOrders(1, values.types, values.status, userId);
  };

  const onPageChange = (e: any, newPage: number) => {
    onRefreshOrders(newPage, types, status, userId);
  };

  useEffect(() => {
    onRefreshOrders(page, types, status, userId);
  }, []);

  const getColumnValue = (item: any, column: string) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value: string) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getOrdersObj.data && getOrdersObj.status === "succeeded") {
      records = { ...getOrdersObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    const records = getProcessedRecord();

    if (getOrdersObj.data.items.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={9}>
            <TableEmptyBox>
              <Typography>No items</Typography>
            </TableEmptyBox>
          </TableCell>
        </TableRow>
      );
    }
    return records?.items.map((item: any) => <OrderListTableRow item={item} key={item.id} />);
  };

  return (
    <Form
      initialValues={{
        types: [],
        status: [],
        userIds: [],
      }}
      onSubmit={submitFilter}
      validationSchema={validationSchema}
    >
      <Grid
        container
        spacing={3}
        sx={{
          paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
          paddingY: "50px",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight="bold">
            Order User Subscriptions
          </Typography>
        </Grid>

        <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
          <GreyBgCardContainer>
            <SearchContainer sx={{ padding: "15px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>Types</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormMultipleSelect
                            itemList={TYPES_LIST}
                            name="types"
                            placeholder="Types"
                            isWhiteBg
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>Statuses</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormMultipleSelect
                            itemList={STATUS_LIST}
                            name="status"
                            placeholder="Status"
                            isWhiteBg
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>User</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormAutoCompletePaginationMultiselect
                            name="userIds"
                            placeholder="Enter user name or email"
                            dispatchFunction={getUsers}
                            requestObject={getUsersObj}
                            optionId="id"
                            optionLabel="email"
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                    <Grid item xs={12} sm={4}>
                      <FormSubmitButton>Search</FormSubmitButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SearchContainer>
          </GreyBgCardContainer>
        </Grid>

        <Grid item xs={12}>
          {getOrdersObj.status === "succeeded" ? (
            <TableWrapper>
              <Table>
                <TableHeader
                  headerCells={[
                    "Order",
                    "User",
                    "Type",
                    "Status",
                    "Package",
                    "Total Meal",
                    "Subtotal",
                    "Outstanding Total",
                    "Action",
                  ]}
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  onSortChange={onSortChange}
                  columnMapping={columnMapping}
                />
                <TableBody>{renderTableBody()}</TableBody>
              </Table>
            </TableWrapper>
          ) : (
            <TableLoader />
          )}
        </Grid>
        <Grid item xs={12}>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "white.main",
                },
              }}
              page={page}
              shape="rounded"
              onChange={onPageChange}
              count={getOrdersObj?.data?.pagination.totalPages || totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </Grid>
      </Grid>
    </Form>
  );
}
