import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormAutoCompletePaginationMultiselect from "../../../components/forms/form-auto-complete-pagination-multiselect.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormMultipleSelect from "../../../components/forms/form-multiple-select.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/tables/table-header.component";
import TableLoader from "../../../components/tables/table-loader.component";
import TableSort from "../../../components/tables/table-sort.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import GreyBgCardContainer from "../../../components/utils/grey-bg-card-container.component";
import theme from "../../../infrastructure/theme";
import {
  getOrderItem,
  getOrderItemCSV,
  getOrderItemPDF,
  orderItemSelector,
} from "../../../services/order/details/details-slice.service";
import { useAppDispatch } from "../../../services/store";
import { getUsers, userSelector } from "../../../services/user/user-slice.service";
import OrderItemTableRow from "../components/order-item-table-row.component";
import { OrderItemsFilterOptionFormValues } from "../type/type";
import BackgroundButton from "../../../components/button/background-button.component";
import { FormikProps } from "formik";
import { CSVLink } from "react-csv";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)({
  width: "100%",
  borderRadius: theme.shape.borderSizes[1],
  overflow: "hidden",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  date: Yup.date().label("Date").nullable(),
  types: Yup.array().label("Types").of(Yup.string()).required(),
  timeFrames: Yup.array().label("Time Frame").of(Yup.string()).required(),
  statuses: Yup.array().label("Statuses").of(Yup.string()).required(),
  userIds: Yup.array().label("Users").nullable(),
  orderType: Yup.string().nullable().label("Order Type").required(),
});

const TYPES_LIST = [
  { id: "1", label: "Delivery", value: "delivery" },
  { id: "2", label: "Pickup", value: "pickup" },
];

const TIMEFRAME_LIST = [
  { id: "1", label: "Lunch", value: "lunch" },
  { id: "2", label: "Dinner", value: "dinner" },
];

const STATUS_LIST = [
  { id: "1", label: "Pending", value: "pending" },
  { id: "2", label: "Completed", value: "completed" },
];

const ORDER_TYPES_LIST = [
  { id: "1", label: "Preorder", value: "preorder" },
  { id: "2", label: "Same Day", value: "same_day" },
];

export default function OrderDetailsScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [date, setDate] = useState(dayjs(new Date()));
  const [types, setTypes] = useState<string[]>([]);
  const [timeFrames, setTimeFrames] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [userId, setUserId] = useState<number[]>([]);
  const [orderType, setOrderType] = useState<string>("preorder");
  const [page, setPage] = useState(1);
  const { getUsersObj } = useSelector(userSelector);
  const { getOrderItemObj, getOrderItemCSVObj } = useSelector(orderItemSelector);
  const [totalPages, setTotalPages] = useState(1);
  const formRef = useRef<FormikProps<OrderItemsFilterOptionFormValues>>();
  const columnMapping = {
    Name: "user.name",
    Date: "date_time",
    Type: "type",
    Address: "delivery.address.location",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);
  const [pdfLink, setPDFLink] = useState<HTMLAnchorElement | null>(null);

  const onRefreshOrderItems = (
    newPage: number,
    newDate: Dayjs,
    newTypes: string[],
    newTimeframe: string[],
    newStatus: string[],
    newUserIds: number[],
    newOrderType: string,
  ) => {
    onHandleDownloadPDF();
    setPage(newPage);
    setDate(newDate);
    setTypes(newTypes);
    setTimeFrames(newTimeframe);
    setStatus(newStatus);
    setUserId(newUserIds);
    setOrderType(newOrderType);
    dispatch(
      getOrderItem({
        page: newPage,
        date: newDate.format("YYYY-MM-DD"),
        types: newTypes,
        timeFrames: newTimeframe,
        statuses: newStatus,
        userIds: newUserIds,
        orderType: newOrderType,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTotalPages(res.data.pagination.totalPages);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });

    dispatch(
      getOrderItemCSV({
        date: dayjs(newDate).format("YYYY-MM-DD"),
        types: newTypes,
        timeFrames: newTimeframe,
        statuses: newStatus,
        userIds: newUserIds,
        orderType: newOrderType,
      }),
    );
  };

  const submitFilter = (values: OrderItemsFilterOptionFormValues) => {
    const userId = values.userIds.map((item) => item.id);

    onRefreshOrderItems(
      1,
      dayjs(values.date),
      values.types,
      values.timeFrames,
      values.statuses,
      userId,
      values.orderType,
    );
  };

  const onPageChange = (e: any, newPage: number) => {
    onRefreshOrderItems(newPage, date, types, timeFrames, status, userId, orderType);
  };

  useEffect(() => {
    onRefreshOrderItems(page, date, types, timeFrames, status, userId, orderType);
    // dispatch(getOrderSummaryCSV({ date, types, timeFrame }));
  }, []);

  const getColumnValue = (item: any, column: string) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value: string) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getOrderItemObj.data && getOrderItemObj.status === "succeeded") {
      records = { ...getOrderItemObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    const records = getProcessedRecord();

    if (getOrderItemObj.data.items.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={9}>
            <TableEmptyBox>
              <Typography>No items</Typography>
            </TableEmptyBox>
          </TableCell>
        </TableRow>
      );
    }
    return records?.items.map((item: any) => <OrderItemTableRow item={item} key={item.id} />);
  };

  const renderTimeframeName = () => {
    if (formRef.current?.values.timeFrames) {
      if (formRef.current?.values.timeFrames.length === 1) {
        if (formRef.current?.values.timeFrames.includes("lunch")) return "-lunch";
        if (formRef.current?.values.timeFrames.includes("dinner")) return "-dinner";
      } else return "-lunch-dinner";
    }
    return "";
  };

  const onHandleDownloadPDF = () => {
    dispatch(
      getOrderItemPDF({
        ...formRef.current?.values,
        userIds: formRef.current?.values.userIds.map((item) => item.id),
        date: dayjs(formRef.current?.values.date).format("YYYY-MM-DD"),
      }),
    ).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.payload], { type: "application/pdf" }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${dayjs(formRef.current?.values.date).format("YYYY-MM-DD")}${renderTimeframeName()}.pdf`,
      ); //or any other extension
      document.body.appendChild(link);
      setPDFLink(link);
    });
  };

  return (
    <Form
      initialValues={{
        date: dayjs(new Date()),
        types: [],
        timeFrames: [],
        statuses: [],
        userIds: [],
        orderType: "preorder",
      }}
      innerRef={formRef}
      onSubmit={submitFilter}
      validationSchema={validationSchema}
    >
      <Grid
        container
        spacing={3}
        sx={{
          paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
          paddingY: "50px",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight="bold">
            Order Details
          </Typography>
        </Grid>

        <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
          <GreyBgCardContainer>
            <SearchContainer sx={{ padding: "15px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>Date</Typography>
                        </Grid>

                        <Grid item xs={9}>
                          <FormDatePicker name="date" disablePast={false} />
                        </Grid>
                      </FormContainer>
                    </Grid>
                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>Delivery / Pickup</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormMultipleSelect
                            itemList={TYPES_LIST}
                            name="types"
                            placeholder="Delivery / Pickup"
                            isWhiteBg
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>Order Type</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormSelectFieldText
                            selections={ORDER_TYPES_LIST}
                            name="orderType"
                            placeholder="Order Type"
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>Timeframes</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormMultipleSelect
                            itemList={TIMEFRAME_LIST}
                            name="timeFrames"
                            placeholder="Timeframes"
                            isWhiteBg
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>Statuses</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormMultipleSelect
                            itemList={STATUS_LIST}
                            name="statuses"
                            placeholder="Status"
                            isWhiteBg
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>User</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormAutoCompletePaginationMultiselect
                            name="userIds"
                            placeholder="Enter user name or email"
                            dispatchFunction={getUsers}
                            requestObject={getUsersObj}
                            optionId="id"
                            optionLabel="email"
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                    <Grid item xs={12} sm={4}>
                      <FormSubmitButton>Search</FormSubmitButton>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {getOrderItemCSVObj.status === "succeeded" &&
                      getOrderItemObj.status === "succeeded" &&
                      getOrderItemObj.data.items.length !== 0 ? (
                        <CSVLink
                          data={getOrderItemCSVObj.data}
                          filename={`${dayjs(date).format("YYYY-MM-DD")}${renderTimeframeName()}.csv`}
                          style={{ textDecoration: "none" }}
                        >
                          <BackgroundButton
                            disabled={getOrderItemCSVObj.status !== "succeeded"}
                            onClick={() => null}
                          >
                            Download CSV
                          </BackgroundButton>
                        </CSVLink>
                      ) : (
                        <>
                          <BackgroundButton disabled={true} onClick={() => null}>
                            Download CSV
                          </BackgroundButton>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <BackgroundButton
                        onClick={() => pdfLink?.click()}
                        disabled={
                          getOrderItemObj.status === "pending" ||
                          (getOrderItemObj.status === "succeeded" &&
                            getOrderItemObj.data.items.length == 0)
                        }
                      >
                        Download PDF
                      </BackgroundButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SearchContainer>
          </GreyBgCardContainer>
        </Grid>

        <Grid item xs={12}>
          {getOrderItemObj.status === "succeeded" ? (
            <TableWrapper>
              <Table>
                <TableHeader
                  headerCells={["Name", "Date", "Type", "Address", "Action"]}
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  onSortChange={onSortChange}
                  columnMapping={columnMapping}
                />
                <TableBody>{renderTableBody()}</TableBody>
              </Table>
            </TableWrapper>
          ) : (
            <TableLoader />
          )}
        </Grid>
        <Grid item xs={12}>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "white.main",
                },
              }}
              page={page}
              shape="rounded"
              onChange={onPageChange}
              count={getOrderItemObj?.data?.pagination.totalPages || totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </Grid>
      </Grid>
    </Form>
  );
}
