import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  Box,
  Grid,
  IconButton,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormDatePicker from "../../../../components/forms/form-date-picker.component";
import FormMultipleSelect from "../../../../components/forms/form-multiple-select.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import { useSnackBar } from "../../../../components/notification/snackbar.context";
import TableHeader from "../../../../components/tables/table-header.component";
import TableLoader from "../../../../components/tables/table-loader.component";
import TableSort from "../../../../components/tables/table-sort.component";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import GreyBgCardContainer from "../../../../components/utils/grey-bg-card-container.component";
import theme from "../../../../infrastructure/theme";
import {
  createFoodCalendars,
  deleteFoodCalendar,
  foodCalendarSelector,
  getFoodCalendars,
} from "../../../../services/food/food-calendar/food-calendar-slice.service";
import { useAppDispatch } from "../../../../services/store";
import { FoodCalendarCreateFormValues, FoodCalendarFilterOptionFormValues } from "../../type/type";
import FoodCalendarTableRow from "../components/food-calendar-table-row.component";
import CreateFoodCalendarModal from "../components/create-food-calendar-modal.component";
import ConfirmationModal from "../../../../components/notification/confirmation-modal.component";
import BackgroundButton from "../../../../components/button/background-button.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)({
  width: "100%",
  borderRadius: theme.shape.borderSizes[1],
  overflow: "hidden",
  backgroundColor: theme.palette.bg.primary,
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: "10px",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  types: Yup.array().label("Types").of(Yup.string()).required(),
  startDate: Yup.date().label("Start date").nullable(),
  endDate: Yup.date()
    .label("End Date")
    .min(Yup.ref("startDate"), "End date can't be earlier than start date")
    .nullable(),
});

const TYPES_LIST = [
  { id: "1", label: "Meat", value: "meat" },
  { id: "2", label: "Vegetables", value: "vege" },
  { id: "3", label: "Sides", value: "side" },
  { id: "4", label: "Carbs", value: "carb" },
  { id: "5", label: "Addons", value: "addon" },
];

export default function FoodCalendarScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [types, setTypes] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const { getFoodCalendarsObj } = useSelector(foodCalendarSelector);
  const [totalPages, setTotalPages] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [checkBoxSelection, setCheckBoxSelection] = useState<number[]>([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const columnMapping = {
    ID: "id",
    Date: "date",
    Dish: "food.identifier",
    Type: "food.type",
    Status: "food.status",
    Price: "food.price",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshFoodCalendar = (
    newPage: number,
    newTypes: string[],
    newStartDate: Dayjs | null,
    newEndDate: Dayjs | null,
  ) => {
    setPage(newPage);
    setStartDate(newStartDate ? dayjs(newStartDate) : null);
    setEndDate(newEndDate ? dayjs(newEndDate) : null);
    setTypes(newTypes);

    dispatch(
      getFoodCalendars({
        page: newPage,
        types: newTypes,
        startDate: newStartDate ? dayjs(newStartDate).format("YYYY-MM-DD") : null,
        endDate: newEndDate ? dayjs(newEndDate).format("YYYY-MM-DD") : null,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTotalPages(res.data.pagination.totalPages);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const submitFilter = (values: FoodCalendarFilterOptionFormValues) => {
    onRefreshFoodCalendar(1, values.types, values.startDate, values.endDate);
  };

  const onPageChange = (e: any, newPage: number) => {
    onRefreshFoodCalendar(newPage, types, startDate, endDate);
  };

  useEffect(() => {
    onRefreshFoodCalendar(page, types, startDate, endDate);
  }, []);

  const getColumnValue = (item: any, column: string) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value: string) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getFoodCalendarsObj.data && getFoodCalendarsObj.status === "succeeded") {
      records = { ...getFoodCalendarsObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    const records = getProcessedRecord();

    if (getFoodCalendarsObj.data.items.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={9}>
            <TableEmptyBox>
              <Typography>No items</Typography>
            </TableEmptyBox>
          </TableCell>
        </TableRow>
      );
    }
    return records?.items.map((item: any) => (
      <FoodCalendarTableRow
        item={item}
        key={item.id}
        startDate={startDate}
        endDate={endDate}
        types={types}
        page={page}
        checkBoxSelection={checkBoxSelection}
        setCheckBoxSelection={setCheckBoxSelection}
        isSelectAll={isSelectAll}
        setIsSelectAll={setIsSelectAll}
      />
    ));
  };

  const onCreateFoodCalendars = (values: FoodCalendarCreateFormValues) => {
    const foodId = values.food_ids.map((item) => item.id);

    dispatch(
      createFoodCalendars({ date: dayjs(values.date).format("YYYY-MM-DD"), food_ids: foodId }),
    )
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowAddModal(false);
          onRefreshFoodCalendar(page, types, startDate, endDate);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onHandleDelete = () => {
    dispatch(deleteFoodCalendar({ ids: checkBoxSelection }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setCheckBoxSelection([]);
          setShowDeleteConfirmation(false);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onHandleSelectAll = () => {
    const records = getProcessedRecord();
    setCheckBoxSelection(records.items.map((item: any) => item.id));
  };

  return (
    <>
      <ConfirmationModal
        showModal={showDeleteConfirmation}
        setShowModal={setShowDeleteConfirmation}
        onConfirm={onHandleDelete}
        title="Delete Food Calendars"
        message={`Are you sure you wish to remove the selected Food Calendars?`}
      />
      <CreateFoodCalendarModal
        title="Add Food Calendar"
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        onCreateFoodCalendars={onCreateFoodCalendars}
      />
      <Form
        initialValues={{
          types: [],
          startDate: null,
          endDate: null,
        }}
        onSubmit={submitFilter}
        validationSchema={validationSchema}
      >
        <Grid
          container
          spacing={3}
          sx={{
            paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
            paddingY: "50px",
          }}
        >
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Typography variant="h4" fontWeight="bold">
                Food Calendar
              </Typography>

              <IconButton size="large" onClick={() => setShowAddModal(true)}>
                <AddBoxIcon fontSize="large" color="primary" />
              </IconButton>
            </SpaceBetweenBox>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", flex: 1, width: "100%" }}>
            <GreyBgCardContainer>
              <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Typography>Types</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <FormMultipleSelect
                              itemList={TYPES_LIST}
                              name="types"
                              placeholder="Types"
                              isWhiteBg
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Typography>Start Date</Typography>
                          </Grid>

                          <Grid item xs={9}>
                            <FormDatePicker name="startDate" disablePast={false} />
                          </Grid>
                        </FormContainer>
                      </Grid>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Typography>End Date</Typography>
                          </Grid>

                          <Grid item xs={9}>
                            <FormDatePicker name="endDate" disablePast={false} />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                      <Grid item xs={12} sm={4}>
                        <FormSubmitButton>Search</FormSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SearchContainer>
            </GreyBgCardContainer>
          </Grid>

          {checkBoxSelection.length !== 0 && (
            <Grid item container columnSpacing={2}>
              <Grid item xs={2}>
                <BackgroundButton onClick={() => setShowDeleteConfirmation(true)}>
                  Delete
                </BackgroundButton>
              </Grid>

              <Grid item xs={2}>
                <BackgroundButton
                  onClick={() => {
                    setIsSelectAll(true);
                    onHandleSelectAll();
                  }}
                >
                  Select All
                </BackgroundButton>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            {getFoodCalendarsObj.status === "succeeded" ? (
              <TableWrapper>
                <Table>
                  <TableHeader
                    headerCells={["", "ID", "Date", "Dish", "Type", "Status", "Price", "Action"]}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={onSortChange}
                    columnMapping={columnMapping}
                    nonSortableColumns={[""]}
                  />
                  <TableBody>{renderTableBody()}</TableBody>
                </Table>
              </TableWrapper>
            ) : (
              <TableLoader />
            )}
          </Grid>
          <Grid item xs={12}>
            <CustomFooter>
              <Pagination
                sx={{
                  "&& .Mui-selected": {
                    backgroundColor: "primary.main",
                    color: "white.main",
                  },
                }}
                page={page}
                shape="rounded"
                onChange={onPageChange}
                count={getFoodCalendarsObj?.data?.pagination.totalPages || totalPages}
                variant="outlined"
              />
            </CustomFooter>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}
