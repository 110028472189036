import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import TableLoader from "../../../components/tables/table-loader.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import GreyBgCardContainer from "../../../components/utils/grey-bg-card-container.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  orderSelector,
  showOrder,
} from "../../../services/order/order-user-subscription-slice.service";
import { useAppDispatch } from "../../../services/store";
import OrderListDetailTableRow from "../components/order-list-detail-table-row.component";
import Form from "../../../components/forms/form.component";
import theme from "../../../infrastructure/theme";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import dayjs from "dayjs";
import { OrderListDetailFilterOptionFormValues } from "../type/type";

const FlexRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: "10px",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)({
  width: "100%",
  borderRadius: theme.shape.borderSizes[1],
  overflow: "hidden",
  backgroundColor: theme.palette.bg.primary,
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  date: Yup.date().label("Date").nullable(),
});

export default function OrderListDetailScreen() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId");
  const snackBar = useSnackBar();
  const [orderListDetailItems, setOrderListDetailItems] = useState<object[]>([]);
  const { showOrderObj } = useSelector(orderSelector);

  useEffect(() => {
    dispatch(showOrder({ orderId }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          setOrderListDetailItems(res.data.items);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  }, []);

  const submitFilter = (values: OrderListDetailFilterOptionFormValues) => {
    if (values.date === null) {
      return setOrderListDetailItems(showOrderObj.data.items);
    }

    let records = null;
    if (showOrderObj.data && showOrderObj.status === "succeeded") {
      records = { ...showOrderObj.data };

      const filteredData = [...records.items].filter((item) =>
        item.date_time.includes(dayjs(values.date).format("YYYY-MM-DD")),
      );

      // Replace the original items array with the filteredData
      records.items = filteredData;
      setOrderListDetailItems(records.items);
    }
  };

  const renderTableBody = () => {
    if (orderListDetailItems.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={9}>
            <TableEmptyBox>
              <Typography>No items</Typography>
            </TableEmptyBox>
          </TableCell>
        </TableRow>
      );
    }

    return orderListDetailItems?.map((item: any) => (
      <OrderListDetailTableRow item={item} key={item.id} />
    ));
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
        paddingY: "50px",
      }}
    >
      <Grid item xs={12}>
        <SpaceBetweenBox>
          <Typography variant="h4" fontWeight="bold">
            {`Order ID: ${orderId} Details`}
          </Typography>
          <Button
            onClick={() => navigate(routes.ORDER_SUBSCRIPTION)}
            sx={{
              textTransform: "none",
              padding: "0px",
              ":hover": { backgroundColor: "transparent" },
            }}
          >
            <Typography>Back</Typography>
          </Button>
        </SpaceBetweenBox>
      </Grid>

      <Grid item xs={12}>
        <Form
          initialValues={{
            date: null,
          }}
          onSubmit={submitFilter}
          validationSchema={validationSchema}
        >
          <Grid item xs={12} sx={{ display: "flex", flex: 1, width: "100%" }}>
            <GreyBgCardContainer>
              <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Typography>Date</Typography>
                          </Grid>

                          <Grid item xs={9}>
                            <FormDatePicker name="date" disablePast={false} />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                      <Grid item xs={12} sm={4}>
                        <FormSubmitButton>Search</FormSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SearchContainer>
            </GreyBgCardContainer>
          </Grid>
        </Form>
      </Grid>

      {showOrderObj.status === "succeeded" && (
        <Grid item sx={{ display: "flex", flex: 1 }}>
          <GreyBgCardContainer>
            <Grid item container>
              <Grid item xs={isSmallScreen ? 6 : 4}>
                <FlexRowBox>
                  <Typography fontWeight="bold">User:</Typography>
                  <Spacer position="left" />
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {showOrderObj.data.user.name}
                  </Typography>
                </FlexRowBox>
              </Grid>

              <Grid item xs={isSmallScreen ? 6 : 4}>
                <FlexRowBox>
                  <Typography fontWeight="bold">Type:</Typography>
                  <Spacer position="left" />
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {showOrderObj.data.type === "same_day" ? "Same Day" : showOrderObj.data.type}
                  </Typography>
                </FlexRowBox>
              </Grid>

              <Grid item xs={isSmallScreen ? 6 : 4}>
                <FlexRowBox>
                  <Typography fontWeight="bold">Status:</Typography>
                  <Spacer position="left" />
                  <Typography sx={{ textTransform: "capitalize" }}>
                    {showOrderObj.data.status.replace("_", " ")}
                  </Typography>
                </FlexRowBox>
              </Grid>

              <Grid item xs={isSmallScreen ? 6 : 4}>
                <FlexRowBox>
                  <Typography fontWeight="bold">Total Meal:</Typography>
                  <Spacer position="left" />
                  <Typography>
                    {showOrderObj.data.type === "preorder"
                      ? `${showOrderObj.data.total_ordered_meals} / ${showOrderObj.data.total_meals}`
                      : "-"}
                  </Typography>
                </FlexRowBox>
              </Grid>

              <Grid item xs={isSmallScreen ? 6 : 4}>
                <FlexRowBox>
                  <Typography fontWeight="bold">Subtotal:</Typography>
                  <Spacer position="left" />
                  <Typography>RM {showOrderObj.data.payment_summary.subtotal}</Typography>
                </FlexRowBox>
              </Grid>
              <Grid item xs={isSmallScreen ? 6 : 4}>
                <FlexRowBox>
                  <Typography fontWeight="bold">Outstanding Total:</Typography>
                  <Spacer position="left" />
                  <Typography>RM {showOrderObj.data.payment_summary.outstanding_total}</Typography>
                </FlexRowBox>
              </Grid>
            </Grid>
          </GreyBgCardContainer>
        </Grid>
      )}

      <Grid item xs={12}>
        {showOrderObj.status === "succeeded" ? (
          <TableWrapper>
            <Table>
              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          </TableWrapper>
        ) : (
          <TableLoader />
        )}
      </Grid>
    </Grid>
  );
}
