export default Object.freeze({
  LOGIN: "/login",
  SIGN_UP: "/sign_up",
  FORGOT_PASSWORD: "/forgot_password",
  EMAIL_VERIFICATION_CODE: "/email_verification_code",
  FORGOT_PASSWORD_VERIFICATION_CODE: "/forgot_password_verification_code",

  LANDING: "/",
  HOME: "/home",
  ORDER: "/order",
  ORDER_SUBSCRIPTION: "/order/subscription",
  ORDER_SUBSCRIPTION_DETAIL: "/order/subscription/detail",
  ORDER_PRODUCTION_SUMMARY: "/order/production/summary",
  ORDER_DETAILS: "/order/details",
  FOOD_DISHES_LIST: "/food/dishes/list",
  FOOD_DISHES_EDIT: "/food/dishes/edit",
  FOOD_CALENDAR: "/food/calendar",
  FOOD_SET_PACKAGES: "/food/set/packages",
  FOOD_SET_CREATE: "/food/set/create",
  FOOD_SET_PACKAGES_EDIT: "/food/set/packages/edit",
  FOOD_SET_ITEM_LIST: "/food/set/item/list",
  USER: "/user",
  USER_EDIT_DETAIL: "/user/edit/detail",
  USER_EDIT_ADDRESS: "/user/edit/address",
  HIGHLIGHT_DISH: "/highlight_dish",
  IMAGE_BANNER: "/image_banner",
  DELIVERY_ZONE: "/delivery/zone",
});
