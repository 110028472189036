import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import ConfirmationModal from "../../../../components/notification/confirmation-modal.component";
import { useSnackBar } from "../../../../components/notification/snackbar.context";
import theme from "../../../../infrastructure/theme";
import {
  deleteFoodCalendar,
  getFoodCalendars,
} from "../../../../services/food/food-calendar/food-calendar-slice.service";
import { useAppDispatch } from "../../../../services/store";

interface Props {
  item: {
    id: number;
    date: string;
    food: {
      id: number;
      identifier: string;
      type: string;
      status: string;
      price: string;
    };
    deletable: boolean;
  };
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  types: string[];
  page: number;
  checkBoxSelection: number[];
  setCheckBoxSelection: React.Dispatch<React.SetStateAction<number[]>>;
  isSelectAll: boolean;
  setIsSelectAll: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FoodCalendarTableRow({
  item,
  startDate,
  endDate,
  types,
  page,
  checkBoxSelection,
  setCheckBoxSelection,
  isSelectAll,
  setIsSelectAll,
}: Props) {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [showMenu, setShowMenu] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isCheckBoxSelected, setIsCheckBoxSelected] = useState(false);

  const handleOpenMenu = (event: any) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const onDeleteFoodCalendar = () => {
    dispatch(deleteFoodCalendar({ ids: [item.id] }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowDeleteModal(false);
          dispatch(
            getFoodCalendars({
              page,
              startDate: startDate ? startDate.format("YYYY-MM-DD") : null,
              endDate: endDate ? endDate.format("YYYY-MM-DD") : null,
              types,
            }),
          );
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  useEffect(() => {
    if (isSelectAll) {
      setIsCheckBoxSelected(true);
      setIsSelectAll(false);
    }
  }, [isSelectAll]);

  return (
    <>
      <ConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={onDeleteFoodCalendar}
        title="Delete Food Calendar"
        message={`Are you sure you wish to remove food calendar ID: ${item.id}?`}
      />
      <TableRow
        key={item.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "50px" }}>
          {isCheckBoxSelected ? (
            <IconButton
              onClick={() => {
                setIsCheckBoxSelected(false);
                setCheckBoxSelection(
                  [...checkBoxSelection].filter((checked) => checked !== item.id),
                );
              }}
            >
              <CheckBoxIcon color="primary" />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setIsCheckBoxSelected(true);
                setCheckBoxSelection([...checkBoxSelection, item.id]);
              }}
            >
              <CheckBoxOutlineBlankIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.id}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.date}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.food.identifier}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.food.type}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.food.status}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            RM {item.food.price}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              sx={{ marginTop: theme.spacing(4) }}
              id="menu-appbar"
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              {item.deletable && (
                <MenuItem
                  key={1}
                  onClick={() => {
                    setShowDeleteModal(true);
                    handleCloseMenu();
                  }}
                >
                  <Typography textAlign="center">Delete</Typography>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
