import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import BackgroundButton from "../../../components/button/background-button.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormMultipleSelect from "../../../components/forms/form-multiple-select.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/tables/table-header.component";
import TableLoader from "../../../components/tables/table-loader.component";
import TableSort from "../../../components/tables/table-sort.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import theme from "../../../infrastructure/theme";
import {
  getOrderSummary,
  getOrderSummaryCSV,
  orderSummarySelector,
} from "../../../services/order/summary/production-summary-slice.service";
import { useAppDispatch } from "../../../services/store";
import OrderSummaryTableRow from "../components/order-summary-table-row.component";
import { OrderSummaryFilterOptionFormValues } from "../type/type";
import GreyBgCardContainer from "../../../components/utils/grey-bg-card-container.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)({
  width: "100%",
  borderRadius: theme.shape.borderSizes[1],
  overflow: "hidden",
  backgroundColor: theme.palette.bg.primary,
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  date: Yup.date().label("Date").nullable(),
  types: Yup.array().label("Types").of(Yup.string()).required(),
  timeFrames: Yup.array().label("Time Frame").of(Yup.string()).required(),
});

const TYPES_LIST = [
  { id: "1", label: "Meat", value: "meat" },
  { id: "2", label: "Vegetables", value: "vege" },
  { id: "3", label: "Sides", value: "side" },
  { id: "4", label: "Carbs", value: "carb" },
  { id: "5", label: "Addons", value: "addon" },
];

const TIMEFRAME_LIST = [
  { id: "1", label: "Lunch", value: "lunch" },
  { id: "2", label: "Dinner", value: "dinner" },
];

export default function OrderProductionSummaryScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [date, setDate] = useState(dayjs(new Date()));
  const [types, setTypes] = useState<string[]>([]);
  const [timeFrames, setTimeFrames] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const { getOrderSummaryObj, getOrderSummaryCSVObj } = useSelector(orderSummarySelector);
  const [totalPages, setTotalPages] = useState(1);
  const columnMapping = {
    Dish: "identifier",
    Type: "type",
    "No. of Pax": "noOfPax",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshOrderSummary = (
    newPage: number,
    newDate: Dayjs,
    newTypes: string[],
    newTimeframe: string[],
  ) => {
    setPage(newPage);
    setDate(dayjs(newDate));
    setTypes(newTypes);
    setTimeFrames(newTimeframe);
    dispatch(
      getOrderSummary({
        page: newPage,
        date: dayjs(newDate).format("YYYY-MM-DD"),
        types: newTypes,
        timeFrames: newTimeframe,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTotalPages(res.data.pagination.totalPages);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });

    dispatch(
      getOrderSummaryCSV({
        date: dayjs(newDate).format("YYYY-MM-DD"),
        types: newTypes,
        timeFrames: newTimeframe,
      }),
    );
  };

  const submitFilter = (values: OrderSummaryFilterOptionFormValues) => {
    onRefreshOrderSummary(1, dayjs(values.date), values.types, values.timeFrames);
  };

  const onPageChange = (e: any, newPage: number) => {
    onRefreshOrderSummary(newPage, date, types, timeFrames);
  };

  useEffect(() => {
    onRefreshOrderSummary(page, date, types, timeFrames);
  }, []);

  const getColumnValue = (item: any, column: string) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value: string) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getOrderSummaryObj.data && getOrderSummaryObj.status === "succeeded") {
      records = { ...getOrderSummaryObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    const records = getProcessedRecord();

    if (getOrderSummaryObj.data.items.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={9}>
            <TableEmptyBox>
              <Typography>No items</Typography>
            </TableEmptyBox>
          </TableCell>
        </TableRow>
      );
    }
    return records?.items.map((item: any) => (
      <OrderSummaryTableRow summary={item} key={item.identifier} />
    ));
  };

  return (
    <Form
      initialValues={{
        date: dayjs(new Date()),
        types: [],
        timeFrames: [],
      }}
      onSubmit={submitFilter}
      validationSchema={validationSchema}
    >
      <Grid
        container
        spacing={3}
        sx={{
          paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
          paddingY: "50px",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight="bold">
            Order Production Summary
          </Typography>
        </Grid>

        <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
          <GreyBgCardContainer>
            <SearchContainer sx={{ padding: isSmallScreen ? "15px" : "25px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>Date</Typography>
                        </Grid>

                        <Grid item xs={9}>
                          <FormDatePicker name="date" disablePast={false} />
                        </Grid>
                      </FormContainer>
                    </Grid>
                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>Types</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormMultipleSelect
                            itemList={TYPES_LIST}
                            name="types"
                            placeholder="Types"
                            isWhiteBg
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>Timeframes</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormMultipleSelect
                            itemList={TIMEFRAME_LIST}
                            name="timeFrames"
                            placeholder="Timeframes"
                            isWhiteBg
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                    <Grid item xs={12} sm={4}>
                      <FormSubmitButton>Search</FormSubmitButton>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      {getOrderSummaryCSVObj.status === "succeeded" ? (
                        <CSVLink
                          data={getOrderSummaryCSVObj.data}
                          filename={`order_summary_${dayjs(date).format("YYYY-MM-DD")}.csv`}
                          style={{ textDecoration: "none" }}
                        >
                          <BackgroundButton
                            disabled={getOrderSummaryCSVObj.status !== "succeeded"}
                            onClick={() => null}
                          >
                            Download CSV
                          </BackgroundButton>
                        </CSVLink>
                      ) : (
                        <>
                          <BackgroundButton disabled={true} onClick={() => null}>
                            Download CSV
                          </BackgroundButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SearchContainer>
          </GreyBgCardContainer>
        </Grid>

        <Grid item xs={12}>
          {getOrderSummaryObj.status === "succeeded" ? (
            <TableWrapper>
              <Table>
                <TableHeader
                  headerCells={["Dish", "Type", "No. of Pax"]}
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  onSortChange={onSortChange}
                  columnMapping={columnMapping}
                />
                <TableBody>{renderTableBody()}</TableBody>
              </Table>
            </TableWrapper>
          ) : (
            <TableLoader />
          )}
        </Grid>
        <Grid item xs={12}>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "white.main",
                },
              }}
              page={page}
              shape="rounded"
              onChange={onPageChange}
              count={getOrderSummaryObj?.data?.pagination.totalPages || totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </Grid>
      </Grid>
    </Form>
  );
}
