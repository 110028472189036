import AddBoxIcon from "@mui/icons-material/AddBox";
import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Grid,
  IconButton,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormMultipleSelect from "../../../../../components/forms/form-multiple-select.component";
import FormSearchBar from "../../../../../components/forms/form-search-bar.component";
import FormSubmitButton from "../../../../../components/forms/form-submit-button.component";
import Form from "../../../../../components/forms/form.component";
import { useSnackBar } from "../../../../../components/notification/snackbar.context";
import TableHeader from "../../../../../components/tables/table-header.component";
import TableLoader from "../../../../../components/tables/table-loader.component";
import TableSort from "../../../../../components/tables/table-sort.component";
import TableWrapper from "../../../../../components/tables/table-wrapper.component";
import GreyBgCardContainer from "../../../../../components/utils/grey-bg-card-container.component";
import theme from "../../../../../infrastructure/theme";
import {
  createFoodSetItems,
  foodSetItemSelector,
  getFoodSetItems,
} from "../../../../../services/food/food-set/item/item-slice.service";
import { useAppDispatch } from "../../../../../services/store";
import {
  FoodSetItemCreateFormValues,
  FoodSetItemListsFilterOptionFormValues,
} from "../../../type/type";
import CreateFoodSetItemModal from "../components/create-food-set-item-modal.component";
import FoodSetItemListTableRow from "../components/food-set-item-list-table-row.component";
import Spacer from "../../../../../components/utils/spacer.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)({
  width: "100%",
  borderRadius: theme.shape.borderSizes[1],
  overflow: "hidden",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: "10px",
});

const StyledBigInfoIcon = styled(InfoIcon)(({ theme }) => ({
  fontSize: "36px",
  color: theme.palette.primary.main,
}));

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable(),
  types: Yup.array().label("Types").of(Yup.string()),
  statuses: Yup.array().label("Statuses").of(Yup.string()),
});

const TYPES_LIST = [
  { id: "1", label: "Meat", value: "meat" },
  { id: "2", label: "Vegetables", value: "vege" },
  { id: "3", label: "Sides", value: "side" },
  { id: "4", label: "Carbs", value: "carb" },
  { id: "5", label: "Addons", value: "addon" },
];

export default function FoodSetItemListScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const foodSetId = new URLSearchParams(location.search).get("foodSetId");
  const [q, setQ] = useState("");
  const [types, setTypes] = useState(["meat", "vege", "side", "carb", "addon"]);
  const [page, setPage] = useState(1);
  const { getFoodSetItemsObj } = useSelector(foodSetItemSelector);
  const [totalPages, setTotalPages] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);
  const columnMapping = {
    ID: "id",
    Dish: "food.identifier",
    Type: "food.type",
    Status: "food.status",
    Price: "food.price",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshFoodSetItemList = (newPage: number, newQ: string, newTypes: string[]) => {
    setPage(newPage);
    setQ(newQ);
    setTypes(newTypes);
    dispatch(
      getFoodSetItems({
        foodSetId,
        page: newPage,
        q: newQ,
        types: newTypes,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTotalPages(res.data.pagination.totalPages);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onChangeSearch = (keyword: string) => {
    onRefreshFoodSetItemList(1, keyword, types);
  };

  const submitFilter = (values: FoodSetItemListsFilterOptionFormValues) => {
    onRefreshFoodSetItemList(1, values.q, values.types);
  };

  const onPageChange = (e: any, newPage: number) => {
    onRefreshFoodSetItemList(newPage, q, types);
  };

  useEffect(() => {
    onRefreshFoodSetItemList(page, q, types);
  }, []);

  const getColumnValue = (item: any, column: string) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value: string) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getFoodSetItemsObj.data && getFoodSetItemsObj.status === "succeeded") {
      records = { ...getFoodSetItemsObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    const records = getProcessedRecord();

    if (getFoodSetItemsObj.data.items.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={9}>
            <TableEmptyBox>
              <Typography>No items</Typography>
            </TableEmptyBox>
          </TableCell>
        </TableRow>
      );
    }
    return records?.items.map((item: any) => (
      <FoodSetItemListTableRow item={item} key={item.id} q={q} page={page} types={types} />
    ));
  };

  const onCreateFoodSetItems = (values: FoodSetItemCreateFormValues) => {
    const foodId = values.food_ids.map((item) => item.id);
    dispatch(createFoodSetItems({ foodSetId, food_ids: foodId }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowAddModal(false);
          onRefreshFoodSetItemList(page, q, types);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <>
      <CreateFoodSetItemModal
        title="Add Food Set Item"
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        onCreateFoodSetItems={onCreateFoodSetItems}
      />
      <Form
        initialValues={{
          q: "",
          types: ["meat", "vege", "side", "carb", "addon"],
        }}
        onSubmit={submitFilter}
        validationSchema={validationSchema}
      >
        <Grid
          container
          spacing={3}
          sx={{
            paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
            paddingY: "50px",
          }}
        >
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Typography variant="h4" fontWeight="bold">
                Food Set Item List
              </Typography>

              <IconButton size="large" onClick={() => setShowAddModal(true)}>
                <AddBoxIcon fontSize="large" color="primary" />
              </IconButton>
            </SpaceBetweenBox>
          </Grid>

          <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
            <GreyBgCardContainer>
              <SearchContainer sx={{ padding: "15px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Typography>Search</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <FormSearchBar
                              placeholder="Search by food name"
                              name="q"
                              searchKeyword={q}
                              setSearchKeyword={onChangeSearch}
                              isWhiteBg
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>

                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Typography>Types</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <FormMultipleSelect
                              itemList={TYPES_LIST}
                              name="types"
                              placeholder="Types"
                              isWhiteBg
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
                      <Grid item xs={12} sm={6}>
                        <SpaceBetweenBox>
                          <StyledBigInfoIcon />
                          <Spacer position="left" />
                          <Typography fontWeight="bold">
                            Please ensure to add at least one of each meat, vege and sides of food.
                          </Typography>
                        </SpaceBetweenBox>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormSubmitButton>Search</FormSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SearchContainer>
            </GreyBgCardContainer>
          </Grid>

          <Grid item xs={12}>
            {getFoodSetItemsObj.status === "succeeded" ? (
              <TableWrapper>
                <Table>
                  <TableHeader
                    headerCells={["ID", "Dish", "Type", "Status", "Price", "Action"]}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={onSortChange}
                    columnMapping={columnMapping}
                  />
                  <TableBody>{renderTableBody()}</TableBody>
                </Table>
              </TableWrapper>
            ) : (
              <TableLoader />
            )}
          </Grid>
          <Grid item xs={12}>
            <CustomFooter>
              <Pagination
                sx={{
                  "&& .Mui-selected": {
                    backgroundColor: "primary.main",
                    color: "white.main",
                  },
                }}
                page={page}
                shape="rounded"
                onChange={onPageChange}
                count={getFoodSetItemsObj?.data?.pagination.totalPages || totalPages}
                variant="outlined"
              />
            </CustomFooter>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}
