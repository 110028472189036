import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormPrice from "../../../components/forms/form-price.component";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTextField from "../../../components/forms/form-text-field.component";
import Form from "../../../components/forms/form.component";
import ImageUpload from "../../../components/image/image-upload.component";
import { foodSelector } from "../../../services/food/food-dishes-slice.service";
import { FoodCreateFormValues } from "../type/type";

interface Props {
  onCreateFood: (values: FoodCreateFormValues) => void;
}

const validationSchema = Yup.object().shape({
  image: Yup.string().required().label("Image").nullable(),
  identifier: Yup.string().required().label("Dish Name"),
  type: Yup.string().required().label("Type"),
  status: Yup.string().required().label("Status"),
  priceRM: Yup.number().nullable().required().label("Price"),
});

const TYPES_LIST = [
  { id: "1", label: "Meat", value: "meat" },
  { id: "2", label: "Vegetables", value: "vege" },
  { id: "3", label: "Sides", value: "side" },
  { id: "4", label: "Carbs", value: "carb" },
  { id: "5", label: "Addons", value: "addon" },
];

const STATUS_LIST = [
  { id: "1", label: "Draft", value: "draft" },
  { id: "2", label: "Publish", value: "publish" },
  { id: "3", label: "Archive", value: "archive" },
];

export default function CreateFoodOneOff({ onCreateFood }: Props) {
  const { createFoodObj } = useSelector(foodSelector);
  const [filePreview, setFilePreview] = useState("");

  return (
    <Form
      initialValues={{
        image: "",
        identifier: "",
        type: "",
        status: "",
        priceRM: "",
      }}
      validationSchema={validationSchema}
      onSubmit={onCreateFood}
    >
      <Grid item container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Box>
            <ImageUpload name="image" filePreview={filePreview} setFilePreview={setFilePreview} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormTextField name="identifier" label="Dish Name" />
        </Grid>
        <Grid item xs={12}>
          <FormSelectFieldText selections={TYPES_LIST} name="type" placeholder="Type" />
        </Grid>
        <Grid item xs={12}>
          <FormSelectFieldText selections={STATUS_LIST} name="status" placeholder="Status" />
        </Grid>
        <Grid item xs={12}>
          <FormPrice placeholder="00.00" name="priceRM" isWhiteBg />
        </Grid>
      </Grid>

      <Grid item xs={12} paddingTop="50px">
        <FormSubmitButton isLoading={createFoodObj.status === "pending"}>Create</FormSubmitButton>
      </Grid>
    </Form>
  );
}
