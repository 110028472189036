import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import theme from "../../../infrastructure/theme";

interface Props {
  item: {
    id: number;
    user: {
      id: number;
      name: string;
    };
    type: string;
    status: string;
    no_of_pax: number;
    total_ordered_meals: number;
    total_meals: number;
    items: {
      id: number;
      type: string;
      date_time: string;
      editable: boolean;
      confirmed: boolean;
      foods: {
        meat: { id: number; identifier: string; no_of_pax: number }[];
        vege: { id: number; identifier: string; no_of_pax: number }[];
        side: { id: number; identifier: string; no_of_pax: number }[];
        carb: { id: number; identifier: string; no_of_pax: number }[];
        addon: { id: number; identifier: string; no_of_pax: number }[];
      };
      delivery: null | {
        address: {
          id: number;
          identifier: string;
          location: string;
          pic: string;
          pic_contact_no: string;
        };
        polygon: {
          id: number;
          identifier: string;
          fee: string;
        };
      };
    };
    food_set: {
      identifier: string;
    };
    payment_summary: {
      subtotal: string;
      outstanding_total: string;
    };
  };
}

export default function OrderListTableRow({ item }: Props) {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(null);

  const handleOpenMenu = (event: any) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  return (
    <>
      <TableRow
        key={item.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "150px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.id}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.user.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.type === "same_day" ? "Same day" : item.type}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.status.replace("_", " ")}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item?.food_set?.identifier ? item?.food_set?.identifier : "-"}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.type === "preorder" ? `${item.total_ordered_meals} / ${item.total_meals}` : "-"}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            RM {item.payment_summary.subtotal}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            RM {item.payment_summary.outstanding_total}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              sx={{ marginTop: theme.spacing(4) }}
              id="menu-appbar"
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                key={1}
                onClick={() => {
                  navigate(`${routes.ORDER_SUBSCRIPTION_DETAIL}?orderId=${item.id}`);
                  handleCloseMenu();
                }}
              >
                <Typography textAlign="center">Show Details</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
