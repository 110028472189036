import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, patchReq } from "../../api.services";
import { idleObject } from "../../idle-object";
import { RequestObject, RootState } from "../../store";

export const getOrderItem = createAsyncThunk("employees/order/item/index", async (payload: any) => {
  const { date, page, types, timeFrames, statuses, userIds, orderType } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/orders/items?page=${page}${
      date ? "&date=".concat(date) : ""
    }${types.length ? "&types=".concat(types) : ""}${timeFrames.length ? "&timeFrames=".concat(timeFrames) : ""}${statuses.length ? "&statuses=".concat(statuses) : ""}${userIds.length ? "&userIds=".concat(userIds) : ""}${orderType.length ? "&orderType=".concat(orderType) : ""}`,
  );
  return response;
});

export const getOrderItemPDF = createAsyncThunk(
  "employees/order/item/pdf",
  async (payload: any) => {
    const { date, types, timeFrames, statuses, userIds, orderType } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/orders/items/pdf?${
        date ? "date=".concat(date) : ""
      }${types.length ? "&types=".concat(types) : ""}${timeFrames.length ? "&timeFrames=".concat(timeFrames) : ""}${statuses.length ? "&statuses=".concat(statuses) : ""}${userIds.length ? "&userIds=".concat(userIds) : ""}${orderType.length ? "&orderType=".concat(orderType) : ""}`,
      true,
    );
    return response;
  },
);

export const getOrderItemCSV = createAsyncThunk(
  "employees/order/item/csv",
  async (payload: any) => {
    const { date, types, timeFrames, statuses, userIds, orderType } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/orders/items/csv?${
        date ? "date=".concat(date) : ""
      }${types.length ? "&types=".concat(types) : ""}${timeFrames.length ? "&timeFrames=".concat(timeFrames) : ""}${statuses.length ? "&statuses=".concat(statuses) : ""}${userIds.length ? "&userIds=".concat(userIds) : ""}${orderType.length ? "&orderType=".concat(orderType) : ""}`,
    );
    return response;
  },
);

export const setOrderItemComplete = createAsyncThunk(
  "employees/order/item/patch/complete",
  async (payload: any) => {
    const { orderId } = payload;
    const response = await patchReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/orders/items/${orderId}`,

      payload,
    );
    return response;
  },
);

interface initialStateType {
  getOrderItemObj: RequestObject;
  getOrderItemPDFObj: RequestObject;
  getOrderItemCSVObj: RequestObject;
  setOrderItemCompleteObj: RequestObject;
}

const initialState: initialStateType = {
  getOrderItemObj: idleObject(),
  getOrderItemPDFObj: idleObject(),
  getOrderItemCSVObj: idleObject(),
  setOrderItemCompleteObj: idleObject(),
};

const orderItemSlice = createSlice({
  name: "orderItem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderItem.pending, (state) => {
        state.getOrderItemObj.status = "pending";
      })
      .addCase(getOrderItem.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getOrderItemObj.status = "succeeded";
        state.getOrderItemObj.data = data;
        state.getOrderItemObj.successMessage = message;
      })
      .addCase(getOrderItem.rejected, (state, action) => {
        const { message } = action.error;

        state.getOrderItemObj.status = "failed";
        state.getOrderItemObj.errorMessage = message;
      })
      .addCase(getOrderItemPDF.pending, (state) => {
        state.getOrderItemPDFObj.status = "pending";
      })
      .addCase(getOrderItemPDF.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getOrderItemPDFObj.status = "succeeded";
        state.getOrderItemPDFObj.data = data;
        state.getOrderItemPDFObj.successMessage = message;
      })
      .addCase(getOrderItemPDF.rejected, (state, action) => {
        const { message } = action.error;

        state.getOrderItemPDFObj.status = "failed";
        state.getOrderItemPDFObj.errorMessage = message;
      })
      .addCase(getOrderItemCSV.pending, (state) => {
        state.getOrderItemCSVObj.status = "pending";
      })
      .addCase(getOrderItemCSV.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getOrderItemCSVObj.status = "succeeded";
        state.getOrderItemCSVObj.data = data;
        state.getOrderItemCSVObj.successMessage = message;
      })
      .addCase(getOrderItemCSV.rejected, (state, action) => {
        const { message } = action.error;

        state.getOrderItemCSVObj.status = "failed";
        state.getOrderItemCSVObj.errorMessage = message;
      })
      .addCase(setOrderItemComplete.pending, (state) => {
        state.setOrderItemCompleteObj.status = "pending";
      })
      .addCase(setOrderItemComplete.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.setOrderItemCompleteObj.status = "succeeded";
        state.setOrderItemCompleteObj.data = data;
        state.setOrderItemCompleteObj.successMessage = message;
      })
      .addCase(setOrderItemComplete.rejected, (state, action) => {
        const { message } = action.error;

        state.setOrderItemCompleteObj.status = "failed";
        state.setOrderItemCompleteObj.errorMessage = message;
      });
  },
});

export default orderItemSlice.reducer;

// state
export const orderItemSelector = (state: RootState) => state.orderItem;
