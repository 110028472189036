import { Box, Drawer, styled, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import YiJiaLogo from "../../assets/images/YiJia_logo";
import routes from "../../components/navigation/routes";
import SessionExpiredBox from "../../components/notification/session-expired-box.component";
import Spacer from "../../components/utils/spacer.component";
import { logout, resetUser } from "../../services/auth/auth-slice.service";
import { useAppDispatch } from "../../services/store";
import AvatarMenu from "./components/desktop-app-bar-components/avatar-with-menu.component";
import MobileViewAppBar from "./components/mobile-view-app-bar-components/mobile-drawer-app-bar.component";
import DrawerItems from "./drawer-items.component";

const AppBarContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: theme.dimensions.PCMaxWidth,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  padding: "15px",
  justifyContent: "space-between",
}));

const PCViewCenterBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  paddingLeft: "30px",
}));

const FlexEndBox = styled(Box)({
  display: "flex",
  height: "100%",
  justifyContent: "flex-end",
  width: "100%",
  padding: "10px",
  paddingRight: "20px",
});

const CenterBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
});

const MaxWidthBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  maxWidth: theme.dimensions.PCMaxWidth,
  minHeight: theme.dimensions.heightWithoutAppBar,
  overflow: "auto",
}));

const FullWidthRowBox = styled(Box)({ width: "100%", display: "flex", flexDirection: "row" });

function CustomizedAppBar() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showSessionExpired, setShowSessionExpired] = useState(false);

  const handleLogout = () => {
    setIsLoading(true);
    dispatch(logout({}))
      .unwrap()
      .then((res) => {
        setIsLoading(false);
        dispatch(resetUser());
        if (res.success) {
          navigate(routes.LOGIN);
        }
      })
      .catch(() => {
        setIsLoading(false);
        dispatch(resetUser());
        navigate(routes.LOGIN);
      });
  };

  return (
    <>
      <SessionExpiredBox isShow={showSessionExpired} setIsShowAlert={setShowSessionExpired} />
      <AppBar position="sticky" sx={{ backgroundColor: "bg.primary" }} elevation={isMobile ? 1 : 0}>
        {isMobile ? (
          <Toolbar disableGutters={!isMobile}>
            <AppBarContainer>
              <Box sx={{ marginLeft: -1 }}>
                <YiJiaLogo width="100px" height="35px" />
              </Box>
              <MobileViewAppBar isLoading={isLoading} handleLogout={handleLogout} />
            </AppBarContainer>
          </Toolbar>
        ) : (
          <FlexEndBox>
            <AvatarMenu handleLogout={handleLogout} />
          </FlexEndBox>
        )}
      </AppBar>
      <CenterBox>
        <FullWidthRowBox>
          {!isMobile && (
            <Box>
              <Drawer
                variant="permanent"
                anchor="left"
                open={true}
                elevation={1}
                sx={{
                  width: "200px",
                  "& .MuiDrawer-paper": {
                    width: "200px",
                    backgroundColor: "white.main",
                  },
                }}
              >
                <Spacer size="xl" />
                <PCViewCenterBox>
                  <YiJiaLogo />
                </PCViewCenterBox>
                <Spacer size="l" />
                <DrawerItems />
              </Drawer>
            </Box>
          )}

          {/* ====== Content Section ====== */}
          <CenterBox
            sx={{
              width: "100%",
              // backgroundColor: "bg.primary",
              marginTop: "40px",
            }}
          >
            <MaxWidthBox>
              <Outlet />
            </MaxWidthBox>
          </CenterBox>
        </FullWidthRowBox>
      </CenterBox>
    </>
  );
}

export default CustomizedAppBar;
