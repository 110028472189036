import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import ConfirmationModal from "../../../../components/notification/confirmation-modal.component";
import { useSnackBar } from "../../../../components/notification/snackbar.context";
import theme from "../../../../infrastructure/theme";
import {
  deleteFoodSet,
  getFoodSets,
} from "../../../../services/food/food-set/food-set-slice.service";
import { useAppDispatch } from "../../../../services/store";

interface Props {
  item: {
    id: number;
    identifier: string;
    pricePerPax: string;
    totalMeals: number;
    status: string;
    rule: {
      minNoPax: number;
      maxNoPax: number;
      total_vege_side_combination: number;
      vege_side_combination: { vege: number; side: number }[];
      meat: { min: number; max: number };
      vege: { min: number; max: number };
      side: { min: number; max: number };
      carb: { min: number; max: number };
      addon: { min: number; max: number };
    };
    createdAt: string;
    deletable: boolean;
  };
}

export default function FoodSetListTableRow({ item }: Props) {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleOpenMenu = (event: any) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const onDeleteFoodSet = () => {
    dispatch(deleteFoodSet({ foodSetId: item.id }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowDeleteModal(false);
          dispatch(getFoodSets({ page: 1 }));
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <>
      <ConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={onDeleteFoodSet}
        title="Delete Food Set"
        message={`Are you sure you wish to remove ${item.identifier}?`}
      />
      <TableRow
        key={item.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.id}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.identifier}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            RM {item.pricePerPax}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.totalMeals}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.status}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              sx={{ marginTop: theme.spacing(4) }}
              id="menu-appbar"
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                key={1}
                onClick={() => {
                  navigate(`${routes.FOOD_SET_PACKAGES_EDIT}?foodSetId=${item.id}`, {
                    state: item,
                  });
                  handleCloseMenu();
                }}
              >
                <Typography textAlign="center">Update</Typography>
              </MenuItem>
              {item.deletable && (
                <MenuItem
                  key={2}
                  onClick={() => {
                    setShowDeleteModal(true);
                    handleCloseMenu();
                  }}
                >
                  <Typography textAlign="center">Delete</Typography>
                </MenuItem>
              )}

              <MenuItem
                key={3}
                onClick={() => {
                  navigate(`${routes.FOOD_SET_ITEM_LIST}?foodSetId=${item.id}`);
                  handleCloseMenu();
                }}
              >
                <Typography textAlign="center">Items</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
