import { Box, Grid, Modal, styled, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import ImageUpload from "../../../components/image/image-upload.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import theme from "../../../infrastructure/theme";
import { imageBannerSelector } from "../../../services/image-banner/image-banner-slice.service";
import { PopularDishFormValues } from "../type/type";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24px",
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
});

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  width?: string | number;
  onCreatePopularDishes: (values: PopularDishFormValues) => void;
}

const validationSchema = Yup.object().shape({
  image: Yup.string().required().label("Image").nullable(),
});

export default function CreatePopularDishModal({
  showModal,
  title,
  setShowModal,
  width,
  onCreatePopularDishes,
}: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { createPopularDishesObj } = useSelector(imageBannerSelector);
  const [filePreview, setFilePreview] = useState("");

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "360px" : width, paddingX: isMobile ? "25px" : "40px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TopTitleBox title={title} requireCloseButton={true} setShowModal={setShowModal} />
          </Grid>

          <Form
            initialValues={{
              image: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onCreatePopularDishes}
          >
            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <Box>
                <ImageUpload
                  name="image"
                  filePreview={filePreview}
                  setFilePreview={setFilePreview}
                />
              </Box>
            </Grid>

            <Grid item xs={12} paddingTop="50px">
              <FormSubmitButton
                isLoading={createPopularDishesObj.status === "pending"}
                disabled={!filePreview}
              >
                Create
              </FormSubmitButton>
            </Grid>
          </Form>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

CreatePopularDishModal.defaultProps = {
  width: "600px",
  title: "",
  message: "",
  buttonText: "Confirm",
};
