import { useFormikContext } from "formik";
import { PropsWithChildren } from "react";
import BackgroundButton from "../button/background-button.component";
import BorderButton from "../button/border-button.component";

interface Props {
  variant?: "contained" | "outlined";
  isLoading?: boolean;
  disabled?: boolean;
}

function FormSubmitButton({ children, variant, disabled, isLoading }: PropsWithChildren<Props>) {
  const { handleSubmit } = useFormikContext();

  return variant === "contained" ? (
    <BackgroundButton onClick={handleSubmit} disabled={disabled} isLoading={isLoading}>
      {children}
    </BackgroundButton>
  ) : (
    <BorderButton onClick={handleSubmit} disabled={disabled} isLoading={isLoading}>
      {children}
    </BorderButton>
  );
}

FormSubmitButton.defaultProps = {
  variant: "contained",
  disabled: false,
  isLoading: false,
};

export default FormSubmitButton;
