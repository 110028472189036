import { Box, FormHelperText, Paper, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FormikValues, useFormikContext } from "formik";
import { KeyboardEvent, useState } from "react";
import Loader from "react-js-loader";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import PaddedView from "../utils/padded-view.component";
import Spacer from "../utils/spacer.component";

const searchOptions = {
  componentRestrictions: { country: "MY" },
};

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  isTransparent?: boolean;
}

function FormAddressAutoComplete({ name, label, disabled, isTransparent = true }: Props) {
  const { setFieldValue, values, touched, errors, setFieldTouched, handleSubmit } =
    useFormikContext<FormikValues>();
  const [address, setAddress] = useState(values[name]);
  const [focus, setFocus] = useState(false);
  const theme = useTheme();
  const showError = touched[name] && typeof errors[name] === "string";

  const keyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSelect = async (value: string, placeID: string) => {
    const result = await geocodeByAddress(value);
    const latLng = await getLatLng(result[0]);
    const [place] = await geocodeByPlaceId(placeID);

    const { long_name: postcode = "" } =
      place.address_components.find((c) => c.types.includes("postal_code")) || {};
    const { long_name: city = "" } =
      place.address_components.find(
        (c) => c.types.includes("sublocality_level_1") || c.types.includes("locality"),
      ) || {};
    const { long_name: state = "" } =
      place.address_components.find((c) => c.types.includes("administrative_area_level_1")) || {};

    setAddress(value);
    setFieldValue(name, value);
    setFieldValue("location_lat", latLng.lat.toString());
    setFieldValue("location_long", latLng.lng.toString());
    setFieldValue("state", state);
    setFieldValue("city", city);
    setFieldValue("postcode", postcode);
  };

  return (
    <PlacesAutocomplete
      value={address || ""}
      onChange={(text) => {
        setAddress(text);
        setFieldValue(name, "");
        setFieldValue("location_lat", "");
        setFieldValue("location_long", "");
        setFieldValue("state", "");
        setFieldValue("city", "");
        setFieldValue("postcode", "");
      }}
      searchOptions={searchOptions}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Box
          position="relative"
          sx={{
            "& .MuiTextField-root": {
              "& .MuiOutlinedInput-root": {
                backgroundColor: isTransparent ? "transparent" : theme.palette.common.white,
              },
            },
          }}
        >
          <TextField
            {...getInputProps()}
            multiline
            disabled={disabled}
            variant="outlined"
            onKeyDown={(event) => keyPress(event)}
            size="small"
            value={address || ""}
            error={showError}
            fullWidth
            // sx={{ bgcolor: theme.palette.common.white }}
            label={label}
            onFocus={() => setFocus(true)}
            onBlur={() => {
              setFieldTouched(name);
              setFocus(false);
            }}
            helperText={showError ? String(errors[name]) : null}
          />

          {loading && (
            <>
              <Spacer />
              <Loader type="bubble-loop" bgColor={theme.palette.primary.dark} size={35} />
            </>
          )}

          {focus && suggestions.length > 0 && (
            <Box
              sx={{
                position: "absolute",
                borderRadius: "20px",
                backgroundColor: theme.palette.common.white,
                width: "100%",
                maxHeight: "200px",
                overflowY: "hidden",
                zIndex: 2,
              }}
            >
              <Box sx={{ maxHeight: "200px", overflowY: "auto", zIndex: 99 }}>
                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active
                      ? theme.palette.white.dark
                      : theme.palette.common.white,
                    borderRadius: 0,
                    cursor: "pointer",
                    border: `1px solid ${theme.palette.white.dark}`,
                  };
                  return (
                    <Paper
                      {...getSuggestionItemProps(suggestion, { style })}
                      key={`${suggestion.description}`}
                    >
                      <PaddedView>
                        <Typography>{suggestion.description}</Typography>
                      </PaddedView>
                    </Paper>
                  );
                })}
              </Box>
            </Box>
          )}
          {!errors[name] && values[name] && (
            <>
              {errors.state && (
                <FormHelperText error sx={{ marginX: "14px" }}>
                  State is required
                </FormHelperText>
              )}
              {errors.city && (
                <FormHelperText error sx={{ marginX: "14px" }}>
                  City is required
                </FormHelperText>
              )}
              {errors.postcode && (
                <FormHelperText error sx={{ marginX: "14px" }}>
                  Postcode is required
                </FormHelperText>
              )}
            </>
          )}
        </Box>
      )}
    </PlacesAutocomplete>
  );
}

export default FormAddressAutoComplete;
