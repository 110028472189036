import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  styled,
  useMediaQuery,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BackgroundButton from "../../../../components/button/background-button.component";
import FormPrice from "../../../../components/forms/form-price.component";
import FormSelectFieldText from "../../../../components/forms/form-select-field-text.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import FormTextField from "../../../../components/forms/form-text-field.component";
import { Field, FieldArray, Formik } from "formik";
import routes from "../../../../components/navigation/routes";
import { useSnackBar } from "../../../../components/notification/snackbar.context";
import GreyBgCardContainer from "../../../../components/utils/grey-bg-card-container.component";
import Spacer from "../../../../components/utils/spacer.component";
import theme from "../../../../infrastructure/theme";
import { createFoodSet } from "../../../../services/food/food-set/food-set-slice.service";
import { useAppDispatch } from "../../../../services/store";
import { FoodSetCreateFormValues } from "../../type/type";
import { KeyboardEvent, useState } from "react";
import ImageUpload from "../../../../components/image/image-upload.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  identifier: Yup.string().required().label("Food Set Name"),
  image: Yup.string().required().label("Image").nullable(),
  priceRM: Yup.number().required().nullable().label("Price"),
  totalMeals: Yup.number().required().label("Total Meals"),
  minNoPax: Yup.number().required().label("Min. No. Pax"),
  maxNoPax: Yup.number().required().label("Max. No. Pax"),
  total_meat: Yup.number().required().label("Total No. Meat"),
  total_carb: Yup.number().required().label("Total No. Carb"),
  total_addon: Yup.number().required().label("Total No. Addon"),
  status: Yup.string().required().label("Status"),
  foodVegeSideCombination: Yup.array(
    Yup.object()
      .shape({
        total_vege: Yup.string().required().label("Total No. Vege"),
        total_side: Yup.string().required().label("Total No. Side"),
      })
      .required("Must have at least 1"),
  )
    .min(1)
    .label("Vege Side Combination"),
});

const STATUS_LIST = [
  { id: "1", label: "Draft", value: "draft" },
  { id: "2", label: "Publish", value: "publish" },
  { id: "3", label: "Archive", value: "archive" },
];

const StyledTextField = styled(Field)({
  borderRadius: theme.shape.borderSizes[2],
  padding: "10px",
  font: "inherit",
  backgroundColor: theme.palette.common.white,
  border: "1px solid #D0D0D0",
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderSizes[2],
    backgroundColor: "transparent",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
  "&:hover": {
    border: "1px solid black",
  },
});

const keyPress = (e: KeyboardEvent<HTMLDivElement>) => {
  if (e.key === "-" || e.key === "+") {
    e.preventDefault();
  }
};

type foodVegeSideCombinationType = {
  total_vege: any | number | bigint | string;
  total_side: any | number | bigint | string;
};

export default function FoodSetCreateScreen() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const snackBar = useSnackBar();
  const [filePreview, setFilePreview] = useState("");

  const initialValues: FoodSetCreateFormValues = {
    identifier: "",
    image: "",
    priceRM: "",
    totalMeals: "",
    status: "",
    minNoPax: "",
    maxNoPax: "",
    total_meat: "",
    total_carb: "",
    total_addon: "",
    foodVegeSideCombination: [{ total_vege: "", total_side: "" }],
  };

  const onCreateFoodSet = (values: FoodSetCreateFormValues) => {
    const amountCentsPerPax = values.priceRM * 100;
    const foodCombination = values.foodVegeSideCombination.map((item) => ({
      total_meat: values.total_meat,
      total_carb: values.total_carb,
      total_addon: values.total_addon,
      ...item,
    }));

    dispatch(
      createFoodSet({
        identifier: values.identifier,
        image: values.image,
        amountCentsPerPax,
        totalMeals: values.totalMeals,
        status: values.status,
        minNoPax: values.minNoPax,
        maxNoPax: values.maxNoPax,
        foodCombination,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          navigate(routes.FOOD_SET_PACKAGES);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <Formik
      validationSchema={validationSchema}
      onSubmit={onCreateFoodSet}
      initialValues={initialValues}
    >
      {({ values, errors }) => (
        <Grid
          container
          spacing={3}
          sx={{
            paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
            paddingY: "50px",
          }}
        >
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Typography variant="h4" fontWeight="bold">
                Create Food Set
              </Typography>
              <Button
                onClick={() => navigate(routes.FOOD_SET_PACKAGES)}
                sx={{
                  textTransform: "none",
                  padding: "0px",
                  ":hover": { backgroundColor: "transparent" },
                }}
              >
                <Typography>Back</Typography>
              </Button>
            </SpaceBetweenBox>
          </Grid>

          <Grid item sx={{ display: "flex", flex: 1 }}>
            <Grid item xs={12}>
              <GreyBgCardContainer>
                <Grid
                  container
                  columnSpacing={isSmallScreen ? 1 : 10}
                  rowSpacing={isSmallScreen ? 1 : 3}
                >
                  <Grid item xs={12}>
                    <Typography variant="h5" fontWeight="bold">
                      Detail
                    </Typography>
                  </Grid>

                  <Grid item container spacing={2}>
                    <Grid item xs={12}>
                      {isSmallScreen && (
                        <LabelContainer>
                          <Typography fontWeight="bold">Image</Typography>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Image</Typography>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <ImageUpload
                              name="image"
                              filePreview={filePreview}
                              setFilePreview={setFilePreview}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isSmallScreen && (
                        <LabelContainer>
                          <Typography fontWeight="bold">Food Set Name</Typography>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Food Set Name</Typography>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormTextField name="identifier" isTransparent={false} />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isSmallScreen && (
                        <LabelContainer>
                          <Typography fontWeight="bold">Total Price Per Pax</Typography>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Total Price Per Pax</Typography>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormPrice placeholder="00.00" name="priceRM" isWhiteBg />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isSmallScreen && (
                        <LabelContainer>
                          <Typography fontWeight="bold">Total Meals</Typography>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Total Meals</Typography>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormTextField
                              name="totalMeals"
                              placeholder="0"
                              type="number"
                              isTransparent={false}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12}>
                      {isSmallScreen && (
                        <LabelContainer>
                          <Typography fontWeight="bold">Status</Typography>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Status</Typography>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormSelectFieldText
                              selections={STATUS_LIST}
                              name="status"
                              placeholder="Select Status"
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={12} marginTop="20px">
                      <Typography variant="h5" fontWeight="bold">
                        Rules
                      </Typography>
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 6}>
                      {isSmallScreen && (
                        <LabelContainer>
                          <Typography fontWeight="bold">Min. No. Pax</Typography>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Min. No. Pax</Typography>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormTextField
                              name="minNoPax"
                              placeholder="0"
                              type="number"
                              isTransparent={false}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={isSmallScreen ? 12 : 6}>
                      {isSmallScreen && (
                        <LabelContainer>
                          <Typography fontWeight="bold">Max. No. Pax</Typography>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Max. No. Pax</Typography>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormTextField
                              name="maxNoPax"
                              placeholder="0"
                              type="number"
                              isTransparent={false}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={isSmallScreen ? 12 : 4}>
                      {isSmallScreen && (
                        <LabelContainer>
                          <Typography fontWeight="bold">Total No. Meat</Typography>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Total No. Meat</Typography>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormTextField
                              name="total_meat"
                              placeholder="0"
                              type="number"
                              isTransparent={false}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 4}>
                      {isSmallScreen && (
                        <LabelContainer>
                          <Typography fontWeight="bold">Total No. Carb</Typography>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Total No. Carb</Typography>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormTextField
                              name="total_carb"
                              placeholder="0"
                              type="number"
                              isTransparent={false}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>

                    <Grid item xs={isSmallScreen ? 12 : 4}>
                      {isSmallScreen && (
                        <LabelContainer>
                          <Typography fontWeight="bold">Total No. Addon</Typography>
                        </LabelContainer>
                      )}
                      <FormContainer>
                        {!isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Total No. Addon</Typography>
                          </LabelContainer>
                        )}
                        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                          <Box sx={{ width: "100%" }}>
                            <FormTextField
                              name="total_addon"
                              placeholder="0"
                              type="number"
                              isTransparent={false}
                            />
                          </Box>
                        </Box>
                      </FormContainer>
                    </Grid>
                  </Grid>

                  <FieldArray name="foodVegeSideCombination">
                    {({ push, remove }) => (
                      <>
                        <Grid item xs={12} marginTop="20px">
                          <SpaceBetweenBox>
                            <Typography variant="h5" fontWeight="bold">
                              Food Combination
                            </Typography>
                            <Box>
                              <BackgroundButton
                                onClick={() => push({ total_vege: "", total_side: "" })}
                              >
                                Add
                              </BackgroundButton>
                            </Box>
                          </SpaceBetweenBox>
                        </Grid>

                        {values.foodVegeSideCombination.map((_, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Grid container item key={index}>
                            <Grid
                              item
                              container
                              xs={11}
                              columnSpacing={isSmallScreen ? 0 : 3}
                              bgcolor="bg.secondary"
                              margin="1px"
                              padding="15px 10px"
                              borderRadius={theme.shape.borderSizes[2]}
                            >
                              <Grid item xs={isSmallScreen ? 12 : 6}>
                                {isSmallScreen && (
                                  <LabelContainer>
                                    <Typography fontWeight="bold">Total No. Vege</Typography>
                                  </LabelContainer>
                                )}
                                <FormContainer>
                                  {!isSmallScreen && (
                                    <LabelContainer>
                                      <Typography fontWeight="bold">Total No. Vege</Typography>
                                    </LabelContainer>
                                  )}
                                  <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                    <StyledTextField
                                      name={`foodVegeSideCombination[${index}].total_vege`}
                                      placeholder="0"
                                      type="number"
                                      onKeyDown={(event: any) => keyPress(event)}
                                    />
                                    {errors.foodVegeSideCombination && (
                                      <>
                                        <FormHelperText error sx={{ marginLeft: "10px" }}>
                                          {
                                            (
                                              errors?.foodVegeSideCombination[
                                                index
                                              ] as foodVegeSideCombinationType
                                            )?.total_vege
                                          }
                                        </FormHelperText>
                                      </>
                                    )}
                                  </Box>
                                </FormContainer>
                              </Grid>

                              <Grid item xs={isSmallScreen ? 12 : 6}>
                                {isSmallScreen && (
                                  <LabelContainer>
                                    <Typography fontWeight="bold">Total No. Side</Typography>
                                  </LabelContainer>
                                )}
                                <FormContainer>
                                  {!isSmallScreen && (
                                    <LabelContainer>
                                      <Typography fontWeight="bold">Total No. Side</Typography>
                                    </LabelContainer>
                                  )}
                                  <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                                    <StyledTextField
                                      name={`foodVegeSideCombination[${index}].total_side`}
                                      placeholder="0"
                                      type="number"
                                      onKeyDown={(event: any) => keyPress(event)}
                                    />
                                    {errors.foodVegeSideCombination && (
                                      <>
                                        <FormHelperText error sx={{ marginLeft: "10px" }}>
                                          {
                                            (
                                              errors?.foodVegeSideCombination[
                                                index
                                              ] as foodVegeSideCombinationType
                                            )?.total_side
                                          }
                                        </FormHelperText>
                                      </>
                                    )}
                                  </Box>
                                </FormContainer>
                              </Grid>
                            </Grid>

                            <Grid item xs={0.5} sx={{ display: "flex", alignItems: "center" }}>
                              <IconButton
                                onClick={() => {
                                  remove(index);
                                }}
                              >
                                <RemoveCircleIcon sx={{ color: theme.palette.primary.main }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                  </FieldArray>

                  <Grid item xs={12}>
                    <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                      <Grid item xs={12} sm={4}>
                        <Spacer position="top" size="xl" />
                        <FormSubmitButton>Create</FormSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </GreyBgCardContainer>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}
