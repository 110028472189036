import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const getFoods = createAsyncThunk("employees/foods/index", async (payload: any) => {
  const { q, page, types, statuses, excludeDate, excludeFoodSetId } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/foods?page=${page}${
      q ? "&q=".concat(q) : ""
    }${types ? "&types=".concat(types) : ""}${statuses ? "&statuses=".concat(statuses) : ""}${excludeDate ? "&excludeDate=".concat(excludeDate) : ""}${excludeFoodSetId ? (excludeFoodSetId?.length !== 0 ? "&excludeFoodSetId=".concat(excludeFoodSetId) : "") : ""}`,
  );
  return response;
});

export const showFood = createAsyncThunk("employees/foods/{food_id}", async (payload: any) => {
  const { foodId } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/foods/${foodId}`,
  );
  return response;
});

export const createFood = createAsyncThunk("employees/foods/create", async (payload: any) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/foods`,
    payload,
  );
  return response;
});

export const createFoodBulk = createAsyncThunk(
  "employees/foods/create/bulk",
  async (payload: any) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/foods/bulk`,
      payload,
    );
    return response;
  },
);

export const updateFood = createAsyncThunk("employees/foods/update", async (payload: any) => {
  const { foodId } = payload;
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/foods/${foodId}`,
    payload,
  );
  return response;
});

export const updateFoodImage = createAsyncThunk(
  "employees/foods/update/image",
  async (payload: any) => {
    const { foodId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/foods/${foodId}/image`,
      payload,
    );
    return response;
  },
);

export const deleteFood = createAsyncThunk("employees/foods/delete", async (payload: any) => {
  const { foodId } = payload;
  const response = await delReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/foods/${foodId}`,
  );
  return response;
});

interface initialStateType {
  getFoodsObj: RequestObject;
  showFoodObj: RequestObject;
  createFoodObj: RequestObject;
  createFoodBulkObj: RequestObject;
  updateFoodObj: RequestObject;
  updateFoodImageObj: RequestObject;
  deleteFoodObj: RequestObject;
}

const initialState: initialStateType = {
  getFoodsObj: idleObject(),
  showFoodObj: idleObject(),
  createFoodObj: idleObject(),
  createFoodBulkObj: idleObject(),
  updateFoodObj: idleObject(),
  updateFoodImageObj: idleObject(),
  deleteFoodObj: idleObject(),
};

const foodSlice = createSlice({
  name: "food",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFoods.pending, (state) => {
        state.getFoodsObj.status = "pending";
      })
      .addCase(getFoods.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getFoodsObj.status = "succeeded";
        state.getFoodsObj.data = data;
        state.getFoodsObj.successMessage = message;
      })
      .addCase(getFoods.rejected, (state, action) => {
        const { message } = action.error;

        state.getFoodsObj.status = "failed";
        state.getFoodsObj.errorMessage = message;
      })
      .addCase(showFood.pending, (state) => {
        state.showFoodObj.status = "pending";
      })
      .addCase(showFood.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.showFoodObj.status = "succeeded";
        state.showFoodObj.data = data;
        state.showFoodObj.successMessage = message;
      })
      .addCase(showFood.rejected, (state, action) => {
        const { message } = action.error;

        state.showFoodObj.status = "failed";
        state.showFoodObj.errorMessage = message;
      })
      .addCase(createFood.pending, (state) => {
        state.createFoodObj.status = "pending";
      })
      .addCase(createFood.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createFoodObj.status = "succeeded";
        state.createFoodObj.data = data;
        state.createFoodObj.successMessage = message;
      })
      .addCase(createFood.rejected, (state, action) => {
        const { message } = action.error;

        state.createFoodObj.status = "failed";
        state.createFoodObj.errorMessage = message;
      })
      .addCase(createFoodBulk.pending, (state) => {
        state.createFoodBulkObj.status = "pending";
      })
      .addCase(createFoodBulk.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createFoodBulkObj.status = "succeeded";
        state.createFoodBulkObj.data = data;
        state.createFoodBulkObj.successMessage = message;
      })
      .addCase(createFoodBulk.rejected, (state, action) => {
        const { message } = action.error;

        state.createFoodBulkObj.status = "failed";
        state.createFoodBulkObj.errorMessage = message;
      })
      .addCase(updateFood.pending, (state) => {
        state.updateFoodObj.status = "pending";
      })
      .addCase(updateFood.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateFoodObj.status = "succeeded";
        state.updateFoodObj.data = data;
        state.showFoodObj.data = data;
        state.updateFoodObj.successMessage = message;
      })
      .addCase(updateFood.rejected, (state, action) => {
        const { message } = action.error;

        state.updateFoodObj.status = "failed";
        state.updateFoodObj.errorMessage = message;
      })
      .addCase(updateFoodImage.pending, (state) => {
        state.updateFoodImageObj.status = "pending";
      })
      .addCase(updateFoodImage.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateFoodImageObj.status = "succeeded";
        state.updateFoodImageObj.data = data;
        state.updateFoodImageObj.successMessage = message;
      })
      .addCase(updateFoodImage.rejected, (state, action) => {
        const { message } = action.error;

        state.updateFoodImageObj.status = "failed";
        state.updateFoodImageObj.errorMessage = message;
      })
      .addCase(deleteFood.pending, (state) => {
        state.deleteFoodObj.status = "pending";
      })
      .addCase(deleteFood.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.deleteFoodObj.status = "succeeded";
        state.deleteFoodObj.data = data;
        state.deleteFoodObj.successMessage = message;
      })
      .addCase(deleteFood.rejected, (state, action) => {
        const { message } = action.error;

        state.deleteFoodObj.status = "failed";
        state.deleteFoodObj.errorMessage = message;
      });
  },
});

export default foodSlice.reducer;

// state
export const foodSelector = (state: RootState) => state.food;
