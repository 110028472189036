import { Box, Grid, Modal, styled, useMediaQuery } from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormAutoCompletePaginationMultiselect from "../../../../../components/forms/form-auto-complete-pagination-multiselect.component";
import FormSubmitButton from "../../../../../components/forms/form-submit-button.component";
import TopTitleBox from "../../../../../components/utils/top-title-box.component";
import theme from "../../../../../infrastructure/theme";
import { foodSetItemSelector } from "../../../../../services/food/food-set/item/item-slice.service";
import { foodSelector, getFoods } from "../../../../../services/food/food-dishes-slice.service";
import { FoodSetItemCreateFormValues } from "../../../type/type";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24px",
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
});

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  width?: string | number;
  onCreateFoodSetItems: (values: FoodSetItemCreateFormValues) => void;
}

const validationSchema = Yup.object().shape({
  food_ids: Yup.array().label("Foods").nullable().min(1),
});

export default function CreateFoodSetItemModal({
  showModal,
  title,
  setShowModal,
  width,
  onCreateFoodSetItems,
}: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getFoodsObj } = useSelector(foodSelector);
  const { createFoodSetItemsObj } = useSelector(foodSetItemSelector);
  const foodSetId = new URLSearchParams(location.search).get("foodSetId");

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "360px" : width, paddingX: isMobile ? "25px" : "40px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TopTitleBox title={title} requireCloseButton={true} setShowModal={setShowModal} />
          </Grid>

          <Grid item xs={12}>
            <Formik
              initialValues={{
                food_ids: [],
              }}
              validationSchema={validationSchema}
              onSubmit={onCreateFoodSetItems}
            >
              <Form>
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <FormAutoCompletePaginationMultiselect
                      name="food_ids"
                      placeholder="Enter dish names"
                      dispatchFunction={getFoods}
                      requestObject={getFoodsObj}
                      optionId="id"
                      optionLabel="identifier"
                      extraKeys={{
                        excludeFoodSetId: foodSetId,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} paddingTop="50px">
                  <FormSubmitButton isLoading={createFoodSetItemsObj.status === "pending"}>
                    Create
                  </FormSubmitButton>
                </Grid>
              </Form>
            </Formik>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

CreateFoodSetItemModal.defaultProps = {
  width: "600px",
  title: "",
  message: "",
  buttonText: "Confirm",
};
