import { Box, Modal, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
// import ReactCrop from "react-image-crop";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
// import CtaButton from "../../../components/button/cta-button.component";
// import Spacer from "../../../components/spacer.component";
// import Text from "../../../components/text.component";
// import CtaBorderButton from "../../../components/button/cta-border-button.component";
import { ReactCrop } from "react-image-crop";
import theme from "../../infrastructure/theme";
import BackgroundButton from "../button/background-button.component";
import BorderButton from "../button/border-button.component";
import Spacer from "../utils/spacer.component";

// const ModalBox = styled(Box)({
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   backgroundColor: theme.palette.primary.main,
//   borderRadius: theme.shape.borderSizes[1],
//   boxShadow: 24,
//   paddingTop: "25px",
//   paddingBottom: "25px",
//   paddingLeft: "20px",
//   paddingRight: "20px",
//   maxWidth: "600px",
//   outline: "none",
//   display: "flex",
//   maxHeight: "80%",
//   flexDirection: "column",
// });

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24px",
  padding: "25px 20px",
  outline: "none",
  display: "flex",
  maxHeight: "80%",
  flexDirection: "column",
});

const ImageCropperContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  height: "80%",
  flex: 1,
  overflow: "scroll",
});

const BottomButtonWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  height: "20%",
  alignItems: "center",
});

interface Props {
  isShowModal: boolean;
  setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  imageFile: string;
  setFilePreview: React.Dispatch<React.SetStateAction<string>>;
}

function ImageCropper({ isShowModal, setIsShowModal, name, imageFile, setFilePreview }: Props) {
  const [image, setImage] = useState<any>(null);
  const cropSetting = { aspect: 1 / 1, unit: "px", width: 200, height: 200, x: 0, y: 0 };
  const [crop, setCrop] = useState<any>(cropSetting);
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setCrop(cropSetting);
  }, [imageFile]);

  const handleCancel = () => {
    setCrop(cropSetting);
    setIsShowModal(false);
  };

  const getCroppedImage = () => {
    const canvas = document.createElement("canvas");
    const scaleRatio = (image?.naturalWidth / image?.naturalHeight) * 2;
    const scaleX = image?.naturalWidth / image?.width;
    const scaleY = image?.naturalHeight / image?.height;
    const ctx = canvas.getContext("2d");
    canvas.width = crop.width * scaleRatio;
    canvas.height = crop.height * scaleRatio;
    if (!ctx) {
      throw new Error("No 2d context");
    }
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleRatio,
      crop.height * scaleRatio,
    );
    const base64ImageURL = canvas.toDataURL("image/webp");
    setFieldValue(name, base64ImageURL);
    setFilePreview(base64ImageURL);
    setIsShowModal(false);
  };

  return (
    <Modal open={isShowModal} onClose={handleCancel}>
      <ModalBox>
        <ImageCropperContainer>
          <ReactCrop
            crop={crop}
            onChange={(c: any) => setCrop(c)}
            aspect={1 / 1}
            keepSelection={true}
            style={{ height: "100%", overflow: "scroll" }}
          >
            <img
              src={imageFile}
              alt="test"
              onLoad={(o: React.ChangeEvent<HTMLImageElement>) => {
                setImage(o.target);
              }}
            />
          </ReactCrop>
        </ImageCropperContainer>

        <Spacer size="l" position="top" />
        <BottomButtonWrapper>
          <Box sx={{ width: "45%" }}>
            <BackgroundButton onClick={getCroppedImage}>Upload</BackgroundButton>
          </Box>
          <Box sx={{ width: "45%" }}>
            <BorderButton onClick={handleCancel}>Cancel</BorderButton>
          </Box>
        </BottomButtonWrapper>
      </ModalBox>
    </Modal>
  );
}

ImageCropper.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
  setFilePreview: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  imageFile: PropTypes.string.isRequired,
};

export default ImageCropper;
