import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authSliceService from "./auth/auth-slice.service";
import userSliceService from "./user/user-slice.service";
import userAddressSliceService from "./user/address/address-slice.service";
import orderUserSubscriptionSliceService from "./order/order-user-subscription-slice.service";
import orderProductionSummarySliceService from "./order/summary/production-summary-slice.service";
import orderDetailsSliceService from "./order/details/details-slice.service";
import foodDishesSliceService from "./food/food-dishes-slice.service";
import foodSetSliceService from "./food/food-set/food-set-slice.service";
import foodSetItemSliceService from "./food/food-set/item/item-slice.service";
import foodCalendarSliceService from "./food/food-calendar/food-calendar-slice.service";
import highlightDishSliceService from "./highlight-dish/highlight-dish-slice.service";
import imageBannerSliceService from "./image-banner/image-banner-slice.service";
import deliveryZoneSliceService from "./delivery-zone/delivery-zone-slice.service";

const store = configureStore({
  reducer: {
    auth: authSliceService,
    user: userSliceService,
    userAddress: userAddressSliceService,
    order: orderUserSubscriptionSliceService,
    orderSummary: orderProductionSummarySliceService,
    orderItem: orderDetailsSliceService,
    food: foodDishesSliceService,
    foodSet: foodSetSliceService,
    foodSetItem: foodSetItemSliceService,
    foodCalendar: foodCalendarSliceService,
    highlightDish: highlightDishSliceService,
    imageBanner: imageBannerSliceService,
    deliveryZone: deliveryZoneSliceService,
  },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface RequestObject<T = any> {
  status: "idle" | "pending" | "succeeded" | "failed";
  data: T;
  successMessage: string | undefined | null;
  errorMessage: string | undefined | null;
}
