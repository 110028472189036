import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, putReq } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const getUsers = createAsyncThunk("employees/users/index", async (payload: any) => {
  const { q, page } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users?page=${page}${
      q ? "&q=".concat(q) : ""
    }`,
  );
  return response;
});

export const showUser = createAsyncThunk("employees/users/{user_id}", async (payload: any) => {
  const { userId } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${userId}`,
  );
  return response;
});

export const updateUserDetail = createAsyncThunk(
  "employees/users/update/detail",
  async (payload: any) => {
    const { userId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${userId}`,
      payload,
    );
    return response;
  },
);

interface initialStateType {
  getUsersObj: RequestObject;
  showUserObj: RequestObject;
  updateUserDetailObj: RequestObject;
}

const initialState: initialStateType = {
  getUsersObj: idleObject(),
  showUserObj: idleObject(),
  updateUserDetailObj: idleObject(),
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.pending, (state) => {
        state.getUsersObj.status = "pending";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getUsersObj.status = "succeeded";
        state.getUsersObj.data = data;
        state.getUsersObj.successMessage = message;
      })
      .addCase(getUsers.rejected, (state, action) => {
        const { message } = action.error;

        state.getUsersObj.status = "failed";
        state.getUsersObj.errorMessage = message;
      })
      .addCase(showUser.pending, (state) => {
        state.showUserObj.status = "pending";
      })
      .addCase(showUser.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.showUserObj.status = "succeeded";
        state.showUserObj.data = data;
        state.showUserObj.successMessage = message;
      })
      .addCase(showUser.rejected, (state, action) => {
        const { message } = action.error;

        state.showUserObj.status = "failed";
        state.showUserObj.errorMessage = message;
      })
      .addCase(updateUserDetail.pending, (state) => {
        state.updateUserDetailObj.status = "pending";
      })
      .addCase(updateUserDetail.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateUserDetailObj.status = "succeeded";
        state.updateUserDetailObj.data = data;
        state.updateUserDetailObj.successMessage = message;
      })
      .addCase(updateUserDetail.rejected, (state, action) => {
        const { message } = action.error;

        state.updateUserDetailObj.status = "failed";
        state.updateUserDetailObj.errorMessage = message;
      });
  },
});

export default userSlice.reducer;

// state
export const userSelector = (state: RootState) => state.user;
