import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const getOrders = createAsyncThunk("employees/orders/index", async (payload: any) => {
  const { types, page, userIds, status } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/orders?page=${page}${
      types ? "&types=".concat(types) : ""
    }${status ? "&statuses=".concat(status) : ""}${userIds ? "&userIds=".concat(userIds) : ""}`,
  );
  return response;
});

export const showOrder = createAsyncThunk("employees/orders/{order_id}", async (payload: any) => {
  const { orderId } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/orders/${orderId}`,
  );
  return response;
});

interface initialStateType {
  getOrdersObj: RequestObject;
  showOrderObj: RequestObject;
}

const initialState: initialStateType = {
  getOrdersObj: idleObject(),
  showOrderObj: idleObject(),
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.getOrdersObj.status = "pending";
      })
      .addCase(getOrders.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getOrdersObj.status = "succeeded";
        state.getOrdersObj.data = data;
        state.getOrdersObj.successMessage = message;
      })
      .addCase(getOrders.rejected, (state, action) => {
        const { message } = action.error;

        state.getOrdersObj.status = "failed";
        state.getOrdersObj.errorMessage = message;
      })
      .addCase(showOrder.pending, (state) => {
        state.showOrderObj.status = "pending";
      })
      .addCase(showOrder.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.showOrderObj.status = "succeeded";
        state.showOrderObj.data = data;
        state.showOrderObj.successMessage = message;
      })
      .addCase(showOrder.rejected, (state, action) => {
        const { message } = action.error;

        state.showOrderObj.status = "failed";
        state.showOrderObj.errorMessage = message;
      });
  },
});

export default orderSlice.reducer;

// state
export const orderSelector = (state: RootState) => state.order;
