import { Box, Grid, Modal, styled, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormPrice from "../../../components/forms/form-price.component";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import theme from "../../../infrastructure/theme";
import { userAddressSelector } from "../../../services/user/address/address-slice.service";
import { PolygonFormValues } from "../type/type";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24px",
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
});

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  width?: string | number;
  onUpdatePolygon: (values: PolygonFormValues) => void;
  item: {
    id: number;
    identifier: string;
    active: boolean;
    order: number;
    chargePrice: string;
  };
}

const BOOLEAN_LIST = [
  { id: "1", label: "Active", value: "true" },
  { id: "2", label: "Inactive", value: "false" },
];

const validationSchema = Yup.object().shape({
  active: Yup.string().required().label("Active"),
  chargePriceRM: Yup.string().required().label("Charge Price"),
});

export default function EditDeliveryZoneModal({
  showModal,
  title,
  setShowModal,
  width,
  onUpdatePolygon,
  item,
}: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { createUserAddressObj } = useSelector(userAddressSelector);

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "360px" : width, paddingX: isMobile ? "25px" : "40px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TopTitleBox title={title} requireCloseButton={true} setShowModal={setShowModal} />
          </Grid>

          <Form
            initialValues={{
              active: item.active,
              chargePriceRM: item.chargePrice,
            }}
            validationSchema={validationSchema}
            onSubmit={onUpdatePolygon}
          >
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <FormSelectFieldText selections={BOOLEAN_LIST} name="active" placeholder="Active" />
              </Grid>
              <Grid item xs={12}>
                <FormPrice name="chargePriceRM" isWhiteBg />
              </Grid>
            </Grid>

            <Grid item xs={12} paddingTop="50px">
              <FormSubmitButton isLoading={createUserAddressObj.status === "pending"}>
                Update
              </FormSubmitButton>
            </Grid>
          </Form>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

EditDeliveryZoneModal.defaultProps = {
  width: "450px",
  title: "",
  message: "",
  buttonText: "Confirm",
};
