import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import SearchBar from "../../../components/tables/search-bar.component";
import TableHeader from "../../../components/tables/table-header.component";
import TableLoader from "../../../components/tables/table-loader.component";
import TableSort from "../../../components/tables/table-sort.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import { useAppDispatch } from "../../../services/store";
import { getUsers, userSelector } from "../../../services/user/user-slice.service";
import UserTableRow from "../components/user-table-row.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

export default function UserListScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [q, setQ] = useState("");
  const [page, setPage] = useState(1);
  const { getUsersObj } = useSelector(userSelector);
  const [totalPages, setTotalPages] = useState(1);
  const columnMapping = {
    "Full Name": "firstName",
    "Phone Number": "phoneNumber",
    Email: "email",
    "Registered At": "registeredAt",
    "Phone Verified": "phoneVerified",
    "Email Verified": "emailVerified",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshUserList = (newPage: number, keyword: string) => {
    setPage(newPage);
    setQ(keyword);
    dispatch(getUsers({ page: newPage, q: keyword }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTotalPages(res.data.pagination.totalPages);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onChangeSearch = (keyword: string) => {
    onRefreshUserList(1, keyword);
  };

  const onPageChange = (e: any, newPage: number) => {
    onRefreshUserList(newPage, q);
  };

  useEffect(() => {
    onRefreshUserList(page, q);
  }, []);

  const getColumnValue = (item: any, column: string) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value: string) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getUsersObj.data && getUsersObj.status === "succeeded") {
      records = { ...getUsersObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    const records = getProcessedRecord();

    if (getUsersObj.data.items.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={9}>
            <TableEmptyBox>
              <Typography>No items</Typography>
            </TableEmptyBox>
          </TableCell>
        </TableRow>
      );
    }
    return records?.items.map((item: any) => <UserTableRow user={item} key={item.id} />);
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
        paddingY: "50px",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight="bold">
          User List
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <SearchBar
          searchKeyword={q}
          setSearchKeyword={onChangeSearch}
          placeholder="Search here"
          isTransparent
          width="300px"
        />
      </Grid>

      <Grid item xs={12}>
        {getUsersObj.status === "succeeded" ? (
          <TableWrapper>
            <Table>
              <TableHeader
                headerCells={[
                  "Full Name",
                  "Phone Number",
                  "Email",
                  "Registered At",
                  "Phone Verified",
                  "Email Verified",
                  "Action",
                ]}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSortChange={onSortChange}
                columnMapping={columnMapping}
              />
              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          </TableWrapper>
        ) : (
          <TableLoader />
        )}
      </Grid>
      <Grid item xs={12}>
        <CustomFooter>
          <Pagination
            sx={{
              "&& .Mui-selected": {
                backgroundColor: "primary.main",
                color: "white.main",
              },
            }}
            page={page}
            shape="rounded"
            onChange={onPageChange}
            count={getUsersObj?.data?.pagination.totalPages || totalPages}
            variant="outlined"
          />
        </CustomFooter>
      </Grid>
    </Grid>
  );
}
