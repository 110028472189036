import { Box, Grid, Modal, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { ReactNode } from "react";
import theme from "../../../infrastructure/theme";
import BackgroundButton from "../../../components/button/background-button.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import { useFormikContext } from "formik";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24px",
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
});

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  message: string;
  messageSection?: ReactNode;
  buttonText?: string;
  width: string | number;
}

export default function PublishConfirmationModal({
  showModal,
  title,
  message,
  messageSection,
  setShowModal,
  buttonText,
  width,
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { handleSubmit } = useFormikContext();

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "360px" : width, paddingX: isMobile ? "25px" : "40px" }}>
        <Grid container>
          <TopTitleBox title={title} requireCloseButton={true} setShowModal={setShowModal} />
          <Grid item xs={12}>
            <Spacer size="l" />
            {messageSection ? (
              messageSection
            ) : (
              <Typography textAlign="justify">{message}</Typography>
            )}
          </Grid>
          <Grid item xs={12} paddingTop="50px">
            <BackgroundButton onClick={handleSubmit}>{buttonText}</BackgroundButton>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

PublishConfirmationModal.defaultProps = {
  width: "450px",
  title: "",
  message: "",
  buttonText: "Confirm",
};
