import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { DebounceInput } from "react-debounce-input";
import theme from "../../infrastructure/theme";
import Spacer from "../utils/spacer.component";

interface Props {
  searchKeyword: string;
  setSearchKeyword: (keyword: string) => void;
  placeholder: string;
  width: string;
  isTransparent: boolean;
}

function SearchBar({
  searchKeyword,
  setSearchKeyword,
  placeholder,
  width = "100%",
  isTransparent = false,
}: Props) {
  return (
    <Box
      sx={{
        "& .MuiTextField-root": {
          borderTopLeftRadius: theme.shape.borderSizes[0],
          borderTopRightRadius: theme.shape.borderSizes[0],
          borderBottomLeftRadius: theme.shape.borderSizes[0],
          borderBottomRightRadius: theme.shape.borderSizes[0],
          width,
          "& .MuiOutlinedInput-root": {
            borderTopLeftRadius: theme.shape.borderSizes[0],
            borderTopRightRadius: theme.shape.borderSizes[0],
            borderBottomLeftRadius: theme.shape.borderSizes[0],
            borderBottomRightRadius: theme.shape.borderSizes[0],
            backgroundColor: isTransparent ? "transparent" : "bg.primary",
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px",
          },
        },
      }}
    >
      <DebounceInput
        debounceTimeout={500}
        onChange={(e) => setSearchKeyword(e.target.value)}
        element={TextField}
        value={searchKeyword}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
              <Spacer position="right" />
            </InputAdornment>
          ),
          endAdornment: searchKeyword && (
            <InputAdornment position="end">
              <IconButton sx={{ padding: "0px" }} onClick={() => setSearchKeyword("")}>
                <HighlightOffIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        placeholder={placeholder}
      />
    </Box>
  );
}

SearchBar.defaultProps = {
  width: "100%",
  isTransparent: false,
};

SearchBar.propTypes = {
  width: PropTypes.string,
  searchKeyword: PropTypes.string.isRequired,
  setSearchKeyword: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
};

export default SearchBar;
