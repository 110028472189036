import { Box, Grid, Modal, styled, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormAddressAutoComplete from "../../../components/forms/form-address-auto-complete.component";
import FormPhoneTextField from "../../../components/forms/form-phone-text-field.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTextField from "../../../components/forms/form-text-field.component";
import Form from "../../../components/forms/form.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import theme from "../../../infrastructure/theme";
import { userAddressSelector } from "../../../services/user/address/address-slice.service";
import { UserAddressFormValues } from "../type/type";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24px",
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
});

const REMARK_LIST = [
  { id: 1, label: "Call to pickup", value: "Call to pickup" },
  { id: 2, label: "Drop at tiang & ring bell", value: "Drop at tiang & ring bell" },
  { id: 3, label: "Drop at lobby & send photo", value: "Drop at lobby & send photo" },
  { id: 4, label: "Drop at tiang & send photo", value: "Drop at tiang & send photo" },
  { id: 5, label: "Pass to reception counter", value: "Pass to reception counter" },
  {
    id: 6,
    label: "Drop at guard house & take photo",
    value: "Drop at guard house & take photo",
  },
  { id: 7, label: "Send to unit", value: "Send to unit" },
  {
    id: 8,
    label: "Call when reaching in 5 minutes",
    value: "Call when reaching in 5 minutes",
  },
  { id: 9, label: "Exchange container", value: "Exchange container" },
  {
    id: 10,
    label: "Open Gate & Put a chair inside",
    value: "Open Gate & Put a chair inside",
  },
  {
    id: 11,
    label: "Put at shoerack and send photo",
    value: "Put at shoerack and send photo",
  },
  { id: 12, label: "Hang at gate and send photo", value: "Hang at gate and send photo" },
  {
    id: 13,
    label: "Drop at lobby and call customer",
    value: "Drop at lobby and call customer",
  },
  { id: 14, label: "Send to unit & Ring Bell", value: "Send to unit & Ring Bell" },
];

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  width?: string | number;
  onCreateAddress: (values: UserAddressFormValues) => void;
}

const validationSchema = Yup.object().shape({
  identifier: Yup.string().required().label("Address Name"),
  pic: Yup.string().required().label("Person in charge"),
  pic_contact_no: Yup.string().required().label("Contact No"),
  unit_no: Yup.string().required().label("Unit No"),
  location: Yup.string().required().label("Location"),
  location_lat: Yup.string().required().label("Lat"),
  location_long: Yup.string().required().label("Long"),
  postcode: Yup.string().required().label("Postcode"),
  city: Yup.string().required().label("City"),
  state: Yup.string().required().label("State"),
  remark: Yup.string().required().label("Remark"),
});

export default function CreateUserAddressModal({
  showModal,
  title,
  setShowModal,
  width,
  onCreateAddress,
}: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { createUserAddressObj } = useSelector(userAddressSelector);

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "360px" : width, paddingX: isMobile ? "25px" : "40px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TopTitleBox title={title} requireCloseButton={true} setShowModal={setShowModal} />
          </Grid>

          <Form
            initialValues={{
              identifier: "",
              pic: "",
              pic_contact_no: "",
              unit_no: "",
              location: "",
              location_lat: "",
              location_long: "",
              city: "",
              state: "",
              postcode: "",
              remark: "",
            }}
            validationSchema={validationSchema}
            onSubmit={onCreateAddress}
          >
            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12}>
                <FormTextField name="identifier" label="Address Name" />
              </Grid>

              <Grid item xs={12}>
                <FormTextField name="pic" label="Person In Charge" />
              </Grid>

              <Grid item xs={12}>
                <FormPhoneTextField name="pic_contact_no" label="Contact No." />
              </Grid>

              <Grid item xs={12}>
                <FormTextField name="unit_no" label="Unit No" />
              </Grid>

              <Grid item xs={12}>
                <FormAddressAutoComplete name="location" label="Address" />
              </Grid>

              <Grid item xs={12}>
                <FormSelectFieldText name="remark" placeholder="Remark" selections={REMARK_LIST} />
              </Grid>
            </Grid>

            <Grid item xs={12} paddingTop="50px">
              <FormSubmitButton isLoading={createUserAddressObj.status === "pending"}>
                Create
              </FormSubmitButton>
            </Grid>
          </Form>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

CreateUserAddressModal.defaultProps = {
  width: "450px",
  title: "",
  message: "",
  buttonText: "Confirm",
};
