import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../components/navigation/routes";
import theme from "../../../infrastructure/theme";

interface Props {
  user: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    registeredAt: string;
    phoneNumber: string;
    phoneVerified: boolean;
    emailVerified: boolean;
    gender: string;
    dateOfBirth: string;
  };
}

export default function UserTableRow({ user }: Props) {
  const [showMenu, setShowMenu] = useState(null);
  const navigate = useNavigate();

  const handleOpenMenu = (event: any) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  return (
    <>
      <TableRow
        key={user.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center" }}>
            {`${user.firstName} ${user.lastName}`}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center" }}>{user.phoneNumber}</Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center" }}>{user.email}</Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center" }}>{user.registeredAt}</Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography color={user.phoneVerified ? "green" : "red"} sx={{ textAlign: "center" }}>
            {user.phoneVerified ? "Yes" : "No"}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography color={user.emailVerified ? "green" : "red"} sx={{ textAlign: "center" }}>
            {user.emailVerified ? "Yes" : "No"}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              sx={{ marginTop: theme.spacing(4) }}
              id="menu-appbar"
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                key={1}
                onClick={() => navigate(`${routes.USER_EDIT_DETAIL}?userId=${user.id}`)}
              >
                <Typography textAlign="center">Edit Details</Typography>
              </MenuItem>

              <MenuItem
                key={2}
                onClick={() => navigate(`${routes.USER_EDIT_ADDRESS}?userId=${user.id}`)}
              >
                <Typography textAlign="center">Edit Address</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
