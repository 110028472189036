import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import ConfirmationModal from "../../../components/notification/confirmation-modal.component";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/utils/spacer.component";
import theme from "../../../infrastructure/theme";
import { setOrderItemComplete } from "../../../services/order/details/details-slice.service";
import { useAppDispatch } from "../../../services/store";

const FlexRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const StyledListItem = styled(ListItem)({
  display: "list-item",
});

interface Props {
  item: {
    id: number;
    user: {
      id: number;
      name: string;
    };
    type: string;
    date_time: string;
    remark: string;
    editable: boolean;
    confirmed: boolean;
    foods: {
      meat: { id: number; identifier: string; no_of_pax: number }[];
      vege: { id: number; identifier: string; no_of_pax: number }[];
      side: { id: number; identifier: string; no_of_pax: number }[];
      carb: { id: number; identifier: string; no_of_pax: number }[];
      addon: { id: number; identifier: string; no_of_pax: number }[];
    };
    delivery: null | {
      address: {
        id: number;
        identifier: string;
        location: string;
        pic: string;
        pic_contact_no: string;
      };
      polygon: {
        id: number;
        identifier: string;
        fee: string;
      };
    };
  };
}

export default function OrderItemTableRow({ item }: Props) {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [showMenu, setShowMenu] = useState(null);
  const [collapse, setCollapse] = useState(false);
  const [showCompleteConfirmation, setShowCompleteConfirmation] = useState(false);

  const handleOpenDetail = () => {
    setCollapse(!collapse);
  };

  const handleOpenMenu = (event: any) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const onUpdateOrderItem = () => {
    dispatch(setOrderItemComplete({ orderId: item.id }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowCompleteConfirmation(false);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <>
      <ConfirmationModal
        showModal={showCompleteConfirmation}
        setShowModal={setShowCompleteConfirmation}
        onConfirm={onUpdateOrderItem}
        title="Update Order Item"
        message={`Are you sure you wish to mark this order item as completed?`}
      />
      <TableRow
        key={item.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.user.name}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.date_time}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.type}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: item.delivery ? "start" : "center" }}>
            {item.delivery ? item.delivery.address.location : "-"}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              sx={{ marginTop: theme.spacing(4) }}
              id="menu-appbar"
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                key={1}
                onClick={() => {
                  setShowCompleteConfirmation(true);
                  handleCloseMenu();
                }}
              >
                <Typography textAlign="center">Mark Complete</Typography>
              </MenuItem>
            </Menu>
            <IconButton onClick={handleOpenDetail}>
              {collapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={collapse} unmountOnExit timeout="auto">
            <Table size="small">
              {item.remark && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <FlexRowBox>
                        <Typography fontWeight="bold" marginLeft="2%">
                          Remark:
                        </Typography>
                        <Spacer position="left" />
                        <Typography fontSize={14}>{item.remark}</Typography>
                      </FlexRowBox>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {item.foods.meat.length !== 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography fontWeight="bold" marginLeft="2%">
                        Meat
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <List sx={{ listStyleType: "decimal", pl: 5 }}>
                        {item.foods.meat.map((meat) => (
                          <StyledListItem key={meat.id}>
                            <FlexRowBox>
                              <Typography fontSize={14}>{meat.identifier}</Typography>
                              <Spacer position="left" />
                              <Typography fontWeight="bold">x{meat.no_of_pax}</Typography>
                            </FlexRowBox>
                          </StyledListItem>
                        ))}
                      </List>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {item.foods.vege.length !== 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography fontWeight="bold" marginLeft="2%">
                        Vegetable
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <List sx={{ listStyleType: "decimal", pl: 5 }}>
                        {item.foods.vege.map((vege) => (
                          <StyledListItem key={vege.id}>
                            <FlexRowBox>
                              <Typography fontSize={14}>{vege.identifier}</Typography>
                              <Spacer position="left" />
                              <Typography fontWeight="bold">x{vege.no_of_pax}</Typography>
                            </FlexRowBox>
                          </StyledListItem>
                        ))}
                      </List>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {item.foods.side.length !== 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography fontWeight="bold" marginLeft="2%">
                        Side
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <List sx={{ listStyleType: "decimal", pl: 5 }}>
                        {item.foods.side.map((side) => (
                          <StyledListItem key={side.id}>
                            <FlexRowBox>
                              <Typography fontSize={14}>{side.identifier}</Typography>
                              <Spacer position="left" />
                              <Typography fontWeight="bold">x{side.no_of_pax}</Typography>
                            </FlexRowBox>
                          </StyledListItem>
                        ))}
                      </List>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {item.foods.carb.length !== 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography fontWeight="bold" marginLeft="2%">
                        Carbs
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <List sx={{ listStyleType: "decimal", pl: 5 }}>
                        {item.foods.carb.map((carb) => (
                          <StyledListItem key={carb.id}>
                            <FlexRowBox>
                              <Typography fontSize={14}>{carb.identifier}</Typography>
                              <Spacer position="left" />
                              <Typography fontWeight="bold">x{carb.no_of_pax}</Typography>
                            </FlexRowBox>
                          </StyledListItem>
                        ))}
                      </List>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {item.foods.addon.length !== 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography fontWeight="bold" marginLeft="2%">
                        Addons
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <List sx={{ listStyleType: "decimal", pl: 5 }}>
                        {item.foods.addon.map((addon) => (
                          <StyledListItem key={addon.id}>
                            <FlexRowBox>
                              <Typography fontSize={14}>{addon.identifier}</Typography>
                              <Spacer position="left" />
                              <Typography fontWeight="bold">x{addon.no_of_pax}</Typography>
                            </FlexRowBox>
                          </StyledListItem>
                        ))}
                      </List>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
