import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { FormHelperText, Popper, TextField, Typography, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { AsyncThunk } from "@reduxjs/toolkit";
import { FormikValues, useFormikContext } from "formik";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { RequestObject, useAppDispatch } from "../../services/store";
import { useSnackBar } from "../notification/snackbar.context";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function CustomPopper(props: any) {
  return <Popper {...props} placement="bottom" />;
}

interface Props {
  name: string;
  placeholder: string;
  disabled: boolean;
  optionId: string | number;
  optionLabel: string;
  dispatchFunction: AsyncThunk<any, any, any>;
  requestObject: RequestObject;
  extraKeys?: object;
}

interface OptionType {
  id: number | string;
  option: string;
}

export default function FormAutoCompletePaginationMultiselect({
  name,
  placeholder,
  disabled,
  optionId,
  optionLabel,
  dispatchFunction,
  requestObject,
  extraKeys,
}: Props) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext<FormikValues>();
  const showError = touched[name] && typeof errors[name] === "string";
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [planKeyword, setPlanKeyword] = useState("");
  const [loadingMoreResults, setLoadingMoreResults] = useState(false);

  const listOnChange = (event: any, value: any) => {
    setFieldValue(name, typeof value === "string" ? value.split(",") : value);
  };

  const loadMoreResults = () => {
    let newPage = page;

    if (newPage < totalPage) {
      setLoadingMoreResults(true);
      const newOptions = options;
      setOptions([...newOptions, { id: "loading", option: "loading" }]);
      newPage += 1;
      setPage(newPage);
      dispatch(dispatchFunction({ q: planKeyword, page: newPage, ...extraKeys }))
        .unwrap()
        .then((res) => {
          if (res.success) {
            setLoadingMoreResults(false);
            const newPageOptions = res.data.items.map((item: any) => ({
              id: item[optionId],
              option: item[optionLabel],
            }));
            setOptions(newOptions.concat(newPageOptions));
          }
        })
        .catch((err) => {
          snackBar.createSnackBar({
            message: `Failed to get auto complete suggestion! ${err.message}`,
            type: "error",
            open: true,
          });
        });
    }
  };

  const onSearchKeywordChange = (keyword: string) => {
    setPage(1);
    setOptions([]);
    dispatch(dispatchFunction({ q: keyword, page: 1, ...extraKeys }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTotalPage(res.data.pagination.totalPages);
          setOptions(
            res.data.items.map((item: any) => ({
              id: item[optionId],
              option: item[optionLabel],
            })),
          );
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: `Failed to get auto complete suggestion! ${err.message}`,
          type: "error",
          open: true,
        });
      });
  };

  useEffect(() => {
    onSearchKeywordChange("");
  }, []);

  return (
    <>
      <Autocomplete
        filterOptions={(x) => x}
        loading={options.length === 0}
        loadingText={requestObject.status === "succeeded" ? "No options" : "Loading..."}
        ListboxProps={{
          role: "list-box",
          onScroll: (event) => {
            const listboxNode = event.currentTarget;
            if (
              !loadingMoreResults &&
              listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight
            ) {
              loadMoreResults();
            }
          },
        }}
        PopperComponent={CustomPopper}
        disabled={disabled}
        multiple
        freeSolo
        disableCloseOnSelect
        onChange={listOnChange}
        options={options}
        getOptionDisabled={(option) => option.option === "loading"}
        value={values[name]}
        getOptionLabel={(option: any) => option.option}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li key={option.id} {...props}>
            {option.option === "loading" ? (
              <Typography sx={{ color: theme.palette.text.secondary }}>Loading...</Typography>
            ) : (
              <>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.option}
              </>
            )}
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <DebounceInput
            {...params}
            debounceTimeout={500}
            error={showError}
            onBlur={() => setFieldTouched(name)}
            element={TextField}
            variant="outlined"
            style={{
              backgroundColor: theme.palette.white.main,
              borderRadius: theme.shape.borderSizes[2],
            }}
            placeholder={placeholder}
            onChange={(e) => {
              setPlanKeyword(e.target.value);
              onSearchKeywordChange(e.target.value);
            }}
          />
        )}
      />
      {showError && (
        <FormHelperText error sx={{ margin: "3px 14px 0px" }}>
          {String(errors[name])}
        </FormHelperText>
      )}
    </>
  );
}

FormAutoCompletePaginationMultiselect.defaultProps = {
  disabled: false,
};

FormAutoCompletePaginationMultiselect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
