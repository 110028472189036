import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, FormHelperText, Grid, IconButton, Typography, styled } from "@mui/material";
import { Field, FieldArray, Formik } from "formik";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import BackgroundButton from "../../../components/button/background-button.component";
import BorderButton from "../../../components/button/border-button.component";
import FormPrice from "../../../components/forms/form-price.component";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import theme from "../../../infrastructure/theme";
import { foodSelector } from "../../../services/food/food-dishes-slice.service";
import { FoodCreateBulkFormValues } from "../type/type";
import CSVReader from "./csv-reader.component";

const sampleCSV = [
  ["name", "type", "status", "amount_cents"],
  ["古老肉 Sweet & Sour Pork", "meat", "publish", "500"],
  ["蜜汁猪肉 Marmite Pork", "meat", "publish", "500"],
  ["Sambal KangKung", "vege", "publish", "400"],
  ["Rice", "addon", "publish", "300"],
  ["炸云吞 Fried Wanton", "side", "draft", "300"],
];
interface Props {
  onCreateFoodBulk: (values: FoodCreateBulkFormValues) => void;
}

const StyledTextField = styled(Field)({
  borderRadius: theme.shape.borderSizes[2],
  padding: "10px",
  font: "inherit",
  backgroundColor: theme.palette.common.white,
  border: "1px solid #D0D0D0",
  "& .MuiOutlinedInput-root": {
    borderRadius: theme.shape.borderSizes[2],
    backgroundColor: "transparent",
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px",
  },
  "&:hover": {
    border: "1px solid black",
  },
});

const validationSchema = Yup.object().shape({
  foods: Yup.array(
    Yup.object()
      .shape({
        identifier: Yup.string().required().label("Dish Name"),
        type: Yup.string().required().label("Type"),
        status: Yup.string().required().label("Status"),
        priceRM: Yup.number().nullable().required().label("Price"),
      })
      .required("Must have at least 1"),
  )
    .min(1)
    .label("Foods"),
});

const TYPES_LIST = [
  { id: "1", label: "Meat", value: "meat" },
  { id: "2", label: "Vegetables", value: "vege" },
  { id: "3", label: "Sides", value: "side" },
  { id: "4", label: "Carbs", value: "carb" },
  { id: "5", label: "Addons", value: "addon" },
];

const STATUS_LIST = [
  { id: "1", label: "Draft", value: "draft" },
  { id: "2", label: "Publish", value: "publish" },
  { id: "3", label: "Archive", value: "archive" },
];

type foodsType = {
  identifier: any | number | bigint | string;
  type: any | number | bigint | string;
  status: any | number | bigint | string;
  priceRM: any | number | bigint | string;
};

export default function CreateFoodOneUpload({ onCreateFoodBulk }: Props) {
  const { createFoodBulkObj } = useSelector(foodSelector);

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        onSubmit={onCreateFoodBulk}
        initialValues={{
          foods: [{ identifier: "", type: "", status: "", priceRM: "" }],
        }}
      >
        {({ values, errors }) => (
          <>
            <CSVReader />
            <FieldArray name="foods">
              {({ push, remove }) => (
                <>
                  <Grid item xs={12} marginY="20px">
                    <Grid container justifyContent="space-between" display="flex">
                      <Grid item xs={5.5}>
                        <Typography variant="h5" fontWeight="bold">
                          Food Items
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        display="flex"
                        xs={6.5}
                        justifyContent="end"
                        columnSpacing={1}
                      >
                        <Grid item>
                          <CSVLink
                            data={sampleCSV}
                            filename={`sample.csv`}
                            style={{ textDecoration: "none" }}
                          >
                            <BorderButton onClick={() => null}>Sample</BorderButton>
                          </CSVLink>
                        </Grid>
                        <Grid item>
                          <BackgroundButton
                            onClick={() =>
                              push({ identifier: "", type: "", status: "", priceRM: "" })
                            }
                          >
                            Add
                          </BackgroundButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {values.foods.map((food, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid container item key={index} marginBottom="10px">
                      <Grid
                        item
                        container
                        xs={11}
                        rowSpacing={0.8}
                        bgcolor="bg.secondary"
                        margin="1px"
                        padding="15px 10px"
                        borderRadius={theme.shape.borderSizes[2]}
                      >
                        <Grid item xs={12}>
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <StyledTextField
                              name={`foods[${index}].identifier`}
                              placeholder="Dish Name"
                            />

                            {errors.foods && (
                              <FormHelperText error sx={{ marginLeft: "10px" }}>
                                {(errors?.foods[index] as foodsType)?.identifier}
                              </FormHelperText>
                            )}
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Field
                              component={() => (
                                <FormSelectFieldText
                                  selections={TYPES_LIST}
                                  name={`foods[${index}].type`}
                                  value={food.type}
                                  placeholder="Type"
                                />
                              )}
                            />

                            {errors.foods && (
                              <FormHelperText error sx={{ marginLeft: "10px" }}>
                                {(errors?.foods[index] as foodsType)?.type}
                              </FormHelperText>
                            )}
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Field
                              component={() => (
                                <FormSelectFieldText
                                  selections={STATUS_LIST}
                                  name={`foods[${index}].status`}
                                  value={food.status}
                                  placeholder="Status"
                                />
                              )}
                            />

                            {errors.foods && (
                              <FormHelperText error sx={{ marginLeft: "10px" }}>
                                {(errors?.foods[index] as foodsType)?.status}
                              </FormHelperText>
                            )}
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <FormPrice
                              placeholder="00.00"
                              name={`foods[${index}].priceRM`}
                              value={food.priceRM}
                              isWhiteBg
                            />

                            {errors.foods && (
                              <FormHelperText error sx={{ marginLeft: "10px" }}>
                                {(errors?.foods[index] as foodsType)?.priceRM}
                              </FormHelperText>
                            )}
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid item xs={0.1} sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <RemoveCircleIcon sx={{ color: theme.palette.primary.main }} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}

                  <Grid item xs={12} paddingTop="50px">
                    <FormSubmitButton isLoading={createFoodBulkObj.status === "pending"}>
                      Create
                    </FormSubmitButton>
                  </Grid>
                </>
              )}
            </FieldArray>
          </>
        )}
      </Formik>
    </>
  );
}
