import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import ConfirmationModal from "../../../../../components/notification/confirmation-modal.component";
import { useSnackBar } from "../../../../../components/notification/snackbar.context";
import {
  deleteFoodSetItem,
  getFoodSetItems,
} from "../../../../../services/food/food-set/item/item-slice.service";
import { useAppDispatch } from "../../../../../services/store";

interface Props {
  item: {
    id: number;
    food: {
      id: number;
      identifier: string;
      type: string;
      status: string;
      price: string;
    };
    deletable: boolean;
  };
  page: number;
  types: string[];
  q: string;
}

export default function FoodSetItemListTableRow({ item, page, types, q }: Props) {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const foodSetId = new URLSearchParams(location.search).get("foodSetId");

  const onDeleteFoodSetItem = () => {
    dispatch(deleteFoodSetItem({ foodSetId, foodSetItemId: item.id }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowDeleteModal(false);
          dispatch(getFoodSetItems({ foodSetId, page, q, types }));
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <>
      <ConfirmationModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={onDeleteFoodSetItem}
        title="Delete Food Set Item"
        message={`Are you sure you wish to remove ${item.food.identifier} from the food set?`}
      />
      <TableRow
        key={item.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.id}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.food.identifier}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.food.type}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.food.status}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            RM {item.food.price}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={() => setShowDeleteModal(true)} disabled={!item.deletable}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
