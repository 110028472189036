import { Box, Paper, TableContainer } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import theme from "../../infrastructure/theme";

interface Props {
  children: React.ReactNode;
}

function TableWrapper({ children }: Props) {
  return (
    <Box
      sx={{
        "& .MuiPaper-root": {
          borderTopLeftRadius: theme.shape.borderSizes[1],
          borderTopRightRadius: theme.shape.borderSizes[1],
          borderBottomLeftRadius: theme.shape.borderSizes[1],
          borderBottomRightRadius: theme.shape.borderSizes[1],
        },
      }}
    >
      <TableContainer component={Paper}>{children}</TableContainer>
    </Box>
  );
}

TableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableWrapper;
