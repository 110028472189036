import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq } from "../../api.services";
import { idleObject } from "../../idle-object";
import { RequestObject, RootState } from "../../store";

export const getOrderSummary = createAsyncThunk(
  "employees/order/summary/index",
  async (payload: any) => {
    const { date, page, types, timeFrames } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/orders/summaries?page=${page}${
        date ? "&date=".concat(date) : ""
      }${types.length ? "&types=".concat(types) : ""}${timeFrames.length ? "&timeFrames=".concat(timeFrames) : ""}`,
    );
    return response;
  },
);

export const getOrderSummaryCSV = createAsyncThunk(
  "employees/order/summary/csv",
  async (payload: any) => {
    const { date, types, timeFrames } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/orders/summaries/csv?${
        date ? "date=".concat(date) : ""
      }${types.length ? "&types=".concat(types) : ""}${timeFrames.length ? "&timeFrames=".concat(timeFrames) : ""}`,
    );
    return response;
  },
);

interface initialStateType {
  getOrderSummaryObj: RequestObject;
  getOrderSummaryCSVObj: RequestObject;
}

const initialState: initialStateType = {
  getOrderSummaryObj: idleObject(),
  getOrderSummaryCSVObj: idleObject(),
};

const orderSummarySlice = createSlice({
  name: "orderSummary",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOrderSummary.pending, (state) => {
        state.getOrderSummaryObj.status = "pending";
      })
      .addCase(getOrderSummary.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getOrderSummaryObj.status = "succeeded";
        state.getOrderSummaryObj.data = data;
        state.getOrderSummaryObj.successMessage = message;
      })
      .addCase(getOrderSummary.rejected, (state, action) => {
        const { message } = action.error;

        state.getOrderSummaryObj.status = "failed";
        state.getOrderSummaryObj.errorMessage = message;
      })
      .addCase(getOrderSummaryCSV.pending, (state) => {
        state.getOrderSummaryCSVObj.status = "pending";
      })
      .addCase(getOrderSummaryCSV.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getOrderSummaryCSVObj.status = "succeeded";
        state.getOrderSummaryCSVObj.data = data;
        state.getOrderSummaryCSVObj.successMessage = message;
      })
      .addCase(getOrderSummaryCSV.rejected, (state, action) => {
        const { message } = action.error;

        state.getOrderSummaryCSVObj.status = "failed";
        state.getOrderSummaryCSVObj.errorMessage = message;
      });
  },
});

export default orderSummarySlice.reducer;

// state
export const orderSummarySelector = (state: RootState) => state.orderSummary;
