import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  Box,
  Grid,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/tables/table-header.component";
import TableLoader from "../../../components/tables/table-loader.component";
import TableSort from "../../../components/tables/table-sort.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import {
  createHighlightDishes,
  deleteHighlightDish,
  getHighlightDishes,
  highlightDishSelector,
} from "../../../services/highlight-dish/highlight-dish-slice.service";
import { useAppDispatch } from "../../../services/store";
import CreateHighlightDishModal from "../components/create-highlight-dish-modal.component";
import HighlightDishTableRow from "../components/highlight-dish-table-row.component";
import { HighlighDishFormValues } from "../type/type";
import BackgroundButton from "../../../components/button/background-button.component";
import ConfirmationModal from "../../../components/notification/confirmation-modal.component";

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: "10px",
});

export default function HighlightDishScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [showAddModal, setShowAddModal] = useState(false);
  const [checkBoxSelection, setCheckBoxSelection] = useState<number[]>([]);
  const { getHighlightDishesObj } = useSelector(highlightDishSelector);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const columnMapping = {
    Order: "order",
    Dish: "food.identifier",
    Type: "food.type",
    Status: "food.status",
    Price: "food.price",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  useEffect(() => {
    dispatch(getHighlightDishes())
      .unwrap()
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  }, []);

  const onCreateHighlightDishes = (values: HighlighDishFormValues) => {
    const foodId = values.food_ids.map((item) => item.id);
    dispatch(createHighlightDishes({ food_ids: foodId }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowAddModal(false);
          dispatch(getHighlightDishes());
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onHandleDelete = () => {
    dispatch(deleteHighlightDish({ ids: checkBoxSelection }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setCheckBoxSelection([]);
          setShowDeleteConfirmation(false);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const getColumnValue = (item: any, column: string) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value: string) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getHighlightDishesObj.data && getHighlightDishesObj.status === "succeeded") {
      records = getHighlightDishesObj.data;

      const sortedData = [...records].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    const records = getProcessedRecord();

    if (getHighlightDishesObj.data.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={6}>
            <TableEmptyBox>
              <Typography>No items</Typography>
            </TableEmptyBox>
          </TableCell>
        </TableRow>
      );
    }
    return records?.map((item: any) => (
      <HighlightDishTableRow
        dish={item}
        key={item.id}
        length={records.length}
        checkBoxSelection={checkBoxSelection}
        setCheckBoxSelection={setCheckBoxSelection}
      />
    ));
  };

  return (
    <>
      <ConfirmationModal
        showModal={showDeleteConfirmation}
        setShowModal={setShowDeleteConfirmation}
        onConfirm={onHandleDelete}
        title="Delete highlight dishes"
        message={`Are you sure you wish to remove the selected highlight dishes?`}
      />
      <CreateHighlightDishModal
        title="Add Highlight Dishes"
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        onCreateHighlightDishes={onCreateHighlightDishes}
      />
      <Grid
        container
        spacing={3}
        sx={{
          paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
          paddingY: "50px",
        }}
      >
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Typography variant="h4" fontWeight="bold">
              Highlight Dish
            </Typography>

            <IconButton size="large" onClick={() => setShowAddModal(true)}>
              <AddBoxIcon fontSize="large" color="primary" />
            </IconButton>
          </SpaceBetweenBox>
        </Grid>

        {checkBoxSelection.length !== 0 && (
          <Grid item xs={2}>
            <BackgroundButton onClick={() => setShowDeleteConfirmation(true)}>
              Delete
            </BackgroundButton>
          </Grid>
        )}

        <Grid item xs={12}>
          {getHighlightDishesObj.status === "succeeded" ? (
            <TableWrapper>
              <Table>
                <TableHeader
                  headerCells={["", "Order", "Dish", "Type", "Status", "Price", "Action"]}
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  onSortChange={onSortChange}
                  columnMapping={columnMapping}
                  nonSortableColumns={[""]}
                />
                <TableBody>{renderTableBody()}</TableBody>
              </Table>
            </TableWrapper>
          ) : (
            <TableLoader />
          )}
        </Grid>
      </Grid>
    </>
  );
}
