import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../api.services";
import { idleObject } from "../../idle-object";
import { RequestObject, RootState } from "../../store";

export const getFoodSets = createAsyncThunk("employees/food_sets/index", async (payload: any) => {
  const { q, page } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_sets?page=${page}${
      q ? "&q=".concat(q) : ""
    }`,
  );
  return response;
});

export const showFoodSet = createAsyncThunk(
  "employees/food_sets/show/{food_set_id}",
  async (payload: any) => {
    const { foodSetId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_sets/${foodSetId}`,
    );
    return response;
  },
);

export const createFoodSet = createAsyncThunk(
  "employees/food_sets/create",
  async (payload: any) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_sets`,
      payload,
    );
    return response;
  },
);

export const updateFoodSet = createAsyncThunk(
  "employees/food_sets/update",
  async (payload: any) => {
    const { foodSetId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_sets/${foodSetId}`,
      payload,
    );
    return response;
  },
);

export const updateFoodSetImage = createAsyncThunk(
  "employees/food_sets/update/image",
  async (payload: any) => {
    const { foodSetId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_sets/${foodSetId}/image`,
      payload,
    );
    return response;
  },
);

export const deleteFoodSet = createAsyncThunk(
  "employees/food_sets/delete",
  async (payload: any) => {
    const { foodSetId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_sets/${foodSetId}`,
    );
    return response;
  },
);

interface initialStateType {
  getFoodSetsObj: RequestObject;
  showFoodSetObj: RequestObject;
  createFoodSetObj: RequestObject;
  updateFoodSetObj: RequestObject;
  updateFoodSetImageObj: RequestObject;
  deleteFoodSetObj: RequestObject;
}

const initialState: initialStateType = {
  getFoodSetsObj: idleObject(),
  showFoodSetObj: idleObject(),
  createFoodSetObj: idleObject(),
  updateFoodSetObj: idleObject(),
  updateFoodSetImageObj: idleObject(),
  deleteFoodSetObj: idleObject(),
};

const foodSetSlice = createSlice({
  name: "foodSet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFoodSets.pending, (state) => {
        state.getFoodSetsObj.status = "pending";
      })
      .addCase(getFoodSets.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getFoodSetsObj.status = "succeeded";
        state.getFoodSetsObj.data = data;
        state.getFoodSetsObj.successMessage = message;
      })
      .addCase(getFoodSets.rejected, (state, action) => {
        const { message } = action.error;

        state.getFoodSetsObj.status = "failed";
        state.getFoodSetsObj.errorMessage = message;
      })
      .addCase(showFoodSet.pending, (state) => {
        state.showFoodSetObj.status = "pending";
      })
      .addCase(showFoodSet.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.showFoodSetObj.status = "succeeded";
        state.showFoodSetObj.data = data;
        state.showFoodSetObj.successMessage = message;
      })
      .addCase(showFoodSet.rejected, (state, action) => {
        const { message } = action.error;

        state.showFoodSetObj.status = "failed";
        state.showFoodSetObj.errorMessage = message;
      })
      .addCase(createFoodSet.pending, (state) => {
        state.createFoodSetObj.status = "pending";
      })
      .addCase(createFoodSet.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createFoodSetObj.status = "succeeded";
        state.createFoodSetObj.data = data;
        state.createFoodSetObj.successMessage = message;
      })
      .addCase(createFoodSet.rejected, (state, action) => {
        const { message } = action.error;

        state.createFoodSetObj.status = "failed";
        state.createFoodSetObj.errorMessage = message;
      })
      .addCase(updateFoodSet.pending, (state) => {
        state.updateFoodSetObj.status = "pending";
      })
      .addCase(updateFoodSet.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateFoodSetObj.status = "succeeded";
        state.updateFoodSetObj.data = data;
        state.updateFoodSetObj.successMessage = message;
      })
      .addCase(updateFoodSet.rejected, (state, action) => {
        const { message } = action.error;

        state.updateFoodSetObj.status = "failed";
        state.updateFoodSetObj.errorMessage = message;
      })
      .addCase(updateFoodSetImage.pending, (state) => {
        state.updateFoodSetImageObj.status = "pending";
      })
      .addCase(updateFoodSetImage.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateFoodSetImageObj.status = "succeeded";
        state.updateFoodSetImageObj.data = data;
        state.updateFoodSetImageObj.successMessage = message;
      })
      .addCase(updateFoodSetImage.rejected, (state, action) => {
        const { message } = action.error;

        state.updateFoodSetImageObj.status = "failed";
        state.updateFoodSetImageObj.errorMessage = message;
      })
      .addCase(deleteFoodSet.pending, (state) => {
        state.deleteFoodSetObj.status = "pending";
      })
      .addCase(deleteFoodSet.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.deleteFoodSetObj.status = "succeeded";
        state.deleteFoodSetObj.data = data;
        state.deleteFoodSetObj.successMessage = message;
      })
      .addCase(deleteFoodSet.rejected, (state, action) => {
        const { message } = action.error;

        state.deleteFoodSetObj.status = "failed";
        state.deleteFoodSetObj.errorMessage = message;
      });
  },
});

export default foodSetSlice.reducer;

// state
export const foodSetSelector = (state: RootState) => state.foodSet;
