import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/tables/table-header.component";
import TableLoader from "../../../components/tables/table-loader.component";
import TableSort from "../../../components/tables/table-sort.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import {
  deliveryZoneSelector,
  getPolygons,
} from "../../../services/delivery-zone/delivery-zone-slice.service";
import { useAppDispatch } from "../../../services/store";
import DeliveryZoneTableRow from "../components/delivery-zone-table-row.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

export default function DeliveryZoneScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [page, setPage] = useState(1);
  const { getPolygonsObj } = useSelector(deliveryZoneSelector);

  const [totalPages, setTotalPages] = useState(1);
  const columnMapping = {
    ID: "id",
    Name: "identifier",
    "No. of Orders": "order",
    "Charge Price": "chargePrice",
    Active: "active",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshPolygonList = (newPage: number) => {
    setPage(newPage);

    dispatch(getPolygons({ page: newPage }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTotalPages(res.data.pagination.totalPages);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onPageChange = (e: any, newPage: number) => {
    onRefreshPolygonList(newPage);
  };

  useEffect(() => {
    onRefreshPolygonList(page);
  }, []);

  const getColumnValue = (item: any, column: string) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value: string) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getPolygonsObj.data && getPolygonsObj.status === "succeeded") {
      records = { ...getPolygonsObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    const records = getProcessedRecord();

    if (getPolygonsObj.data.items.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={9}>
            <TableEmptyBox>
              <Typography>No items</Typography>
            </TableEmptyBox>
          </TableCell>
        </TableRow>
      );
    }
    return records?.items.map((item: any) => <DeliveryZoneTableRow item={item} key={item.id} />);
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
        paddingY: "50px",
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight="bold">
          Delivery Zone
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {getPolygonsObj.status === "succeeded" ? (
          <TableWrapper>
            <Table>
              <TableHeader
                headerCells={["ID", "Name", "No. of Orders", "Charge Price", "Active", "Action"]}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                onSortChange={onSortChange}
                columnMapping={columnMapping}
              />
              <TableBody>{renderTableBody()}</TableBody>
            </Table>
          </TableWrapper>
        ) : (
          <TableLoader />
        )}
      </Grid>
      <Grid item xs={12}>
        <CustomFooter>
          <Pagination
            sx={{
              "&& .Mui-selected": {
                backgroundColor: "primary.main",
                color: "white.main",
              },
            }}
            page={page}
            shape="rounded"
            onChange={onPageChange}
            count={getPolygonsObj?.data?.pagination.totalPages || totalPages}
            variant="outlined"
          />
        </CustomFooter>
      </Grid>
    </Grid>
  );
}
