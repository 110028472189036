import {
  Box,
  Grid,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import Spacer from "../../../components/utils/spacer.component";
import theme from "../../../infrastructure/theme";

const FlexRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const StyledListItem = styled(ListItem)({
  display: "list-item",
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  color: theme.palette.primary.main,
});

interface Props {
  item: {
    id: number;
    type: string;
    date_time: string;
    editable: boolean;
    confirmed: boolean;
    status: string;
    remark: string;
    foods: {
      meat: { id: number; identifier: string; no_of_pax: number }[];
      vege: { id: number; identifier: string; no_of_pax: number }[];
      side: { id: number; identifier: string; no_of_pax: number }[];
      carb: { id: number; identifier: string; no_of_pax: number }[];
      addon: { id: number; identifier: string; no_of_pax: number }[];
    };
    delivery: null | {
      address: {
        id: number;
        identifier: string;
        location: string;
        pic: string;
        pic_contact_no: string;
      };
      polygon: {
        id: number;
        identifier: string;
        fee: string;
      };
    };
  };
}

export default function OrderListDetailTableRow({ item }: Props) {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));

  return (
    <TableRow key={item.id}>
      <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell colSpan={5}>
                <Grid item container color={theme.palette.primary.main}>
                  <Grid item xs={isSmallScreen ? 12 : 4}>
                    <FlexRowBox>
                      <Typography fontWeight="bold">Date:</Typography>
                      <Spacer position="left" />
                      <Typography sx={{ textTransform: "capitalize" }}>{item.date_time}</Typography>
                    </FlexRowBox>
                  </Grid>

                  <Grid item xs={isSmallScreen ? 6 : 4}>
                    <FlexRowBox>
                      <Typography fontWeight="bold">Type:</Typography>
                      <Spacer position="left" />
                      <Typography sx={{ textTransform: "capitalize" }}>
                        {item.type === "same_day" ? "Same Day" : item.type}
                      </Typography>
                    </FlexRowBox>
                  </Grid>

                  <Grid item xs={isSmallScreen ? 6 : 4}>
                    <FlexRowBox>
                      <Typography fontWeight="bold">Status:</Typography>
                      <Spacer position="left" />
                      <Typography sx={{ textTransform: "capitalize" }}>{item.status}</Typography>
                    </FlexRowBox>
                  </Grid>
                </Grid>

                {item.remark && (
                  <SpaceBetweenBox>
                    <FlexRowBox>
                      <Typography fontWeight="bold">Remark:</Typography>
                      <Spacer position="left" />
                      <Typography>{item.remark}</Typography>
                    </FlexRowBox>
                  </SpaceBetweenBox>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
          {item.foods.meat.length !== 0 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography fontWeight="bold" marginLeft="2%">
                    Meat
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5}>
                  <List sx={{ listStyleType: "decimal", pl: 5 }}>
                    {item.foods.meat.map((meat) => (
                      <StyledListItem key={meat.id}>
                        <FlexRowBox>
                          <Typography fontSize={14}>{meat.identifier}</Typography>
                          <Spacer position="left" />
                          <Typography fontWeight="bold">x{meat.no_of_pax}</Typography>
                        </FlexRowBox>
                      </StyledListItem>
                    ))}
                  </List>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {item.foods.vege.length !== 0 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography fontWeight="bold" marginLeft="2%">
                    Vegetable
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5}>
                  <List sx={{ listStyleType: "decimal", pl: 5 }}>
                    {item.foods.vege.map((vege) => (
                      <StyledListItem key={vege.id}>
                        <FlexRowBox>
                          <Typography fontSize={14}>{vege.identifier}</Typography>
                          <Spacer position="left" />
                          <Typography fontWeight="bold">x{vege.no_of_pax}</Typography>
                        </FlexRowBox>
                      </StyledListItem>
                    ))}
                  </List>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {item.foods.side.length !== 0 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography fontWeight="bold" marginLeft="2%">
                    Side
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5}>
                  <List sx={{ listStyleType: "decimal", pl: 5 }}>
                    {item.foods.side.map((side) => (
                      <StyledListItem key={side.id}>
                        <FlexRowBox>
                          <Typography fontSize={14}>{side.identifier}</Typography>
                          <Spacer position="left" />
                          <Typography fontWeight="bold">x{side.no_of_pax}</Typography>
                        </FlexRowBox>
                      </StyledListItem>
                    ))}
                  </List>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {item.foods.carb.length !== 0 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography fontWeight="bold" marginLeft="2%">
                    Carbs
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5}>
                  <List sx={{ listStyleType: "decimal", pl: 5 }}>
                    {item.foods.carb.map((carb) => (
                      <StyledListItem key={carb.id}>
                        <FlexRowBox>
                          <Typography fontSize={14}>{carb.identifier}</Typography>
                          <Spacer position="left" />
                          <Typography fontWeight="bold">x{carb.no_of_pax}</Typography>
                        </FlexRowBox>
                      </StyledListItem>
                    ))}
                  </List>
                </TableCell>
              </TableRow>
            </TableBody>
          )}

          {item.foods.addon.length !== 0 && (
            <TableBody>
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography fontWeight="bold" marginLeft="2%">
                    Addons
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5}>
                  <List sx={{ listStyleType: "decimal", pl: 5 }}>
                    {item.foods.addon.map((addon) => (
                      <StyledListItem key={addon.id}>
                        <FlexRowBox>
                          <Typography fontSize={14}>{addon.identifier}</Typography>
                          <Spacer position="left" />
                          <Typography fontWeight="bold">x{addon.no_of_pax}</Typography>
                        </FlexRowBox>
                      </StyledListItem>
                    ))}
                  </List>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableCell>
    </TableRow>
  );
}
