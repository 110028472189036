import { Box, Grid, Typography, styled } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import BackgroundButton from "../../../components/button/background-button.component";
import BorderButton from "../../../components/button/border-button.component";
import FormAddressAutoComplete from "../../../components/forms/form-address-auto-complete.component";
import FormPhoneTextField from "../../../components/forms/form-phone-text-field.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTextField from "../../../components/forms/form-text-field.component";
import Form from "../../../components/forms/form.component";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import ConfirmationModal from "../../../components/notification/confirmation-modal.component";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/utils/spacer.component";
import theme from "../../../infrastructure/theme";
import { useAppDispatch } from "../../../services/store";
import {
  deleteUserAddress,
  getUserAddresses,
  updateUserAddress,
  userAddressSelector,
} from "../../../services/user/address/address-slice.service";
import { UserAddressFormValues, UserAddressesResource } from "../type/type";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: "10px",
});

const validationSchema = Yup.object().shape({
  identifier: Yup.string().required().label("Address Name"),
  pic: Yup.string().required().label("Person in charge"),
  pic_contact_no: Yup.string().required().label("Contact No"),
  unit_no: Yup.string().required().label("Unit No"),
  location: Yup.string().required().label("Location"),
  location_lat: Yup.string().required().label("Lat"),
  location_long: Yup.string().required().label("Long"),
  postcode: Yup.string().required().label("Postcode"),
  city: Yup.string().required().label("City"),
  state: Yup.string().required().label("State"),
});

const REMARK_LIST = [
  { id: 1, label: "Call to pickup", value: "Call to pickup" },
  { id: 2, label: "Drop at tiang & ring bell", value: "Drop at tiang & ring bell" },
  { id: 3, label: "Drop at lobby & send photo", value: "Drop at lobby & send photo" },
  { id: 4, label: "Drop at tiang & send photo", value: "Drop at tiang & send photo" },
  { id: 5, label: "Pass to reception counter", value: "Pass to reception counter" },
  {
    id: 6,
    label: "Drop at guard house & take photo",
    value: "Drop at guard house & take photo",
  },
  { id: 7, label: "Send to unit", value: "Send to unit" },
  {
    id: 8,
    label: "Call when reaching in 5 minutes",
    value: "Call when reaching in 5 minutes",
  },
  { id: 9, label: "Exchange container", value: "Exchange container" },
  {
    id: 10,
    label: "Open Gate & Put a chair inside",
    value: "Open Gate & Put a chair inside",
  },
  {
    id: 11,
    label: "Put at shoerack and send photo",
    value: "Put at shoerack and send photo",
  },
  { id: 12, label: "Hang at gate and send photo", value: "Hang at gate and send photo" },
  {
    id: 13,
    label: "Drop at lobby and call customer",
    value: "Drop at lobby and call customer",
  },
  { id: 14, label: "Send to unit & Ring Bell", value: "Send to unit & Ring Bell" },
];

interface Props {
  address: UserAddressesResource;
  index: number;
}

export default function UserAddressEditForm({ address, index }: Props) {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const { updateUserAddressObj } = useSelector(userAddressSelector);
  const userId = new URLSearchParams(location.search).get("userId");
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const renderButton = () => {
    if (isEditing) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <BackgroundButton
            onClick={() => {
              setIsEditing(false);
            }}
            isLoading={updateUserAddressObj.status === "pending"}
          >
            Cancel
          </BackgroundButton>
          <Spacer position="left" size="s" />

          <FormSubmitButton isLoading={updateUserAddressObj.status === "pending"}>
            Save
          </FormSubmitButton>
        </Box>
      );
    }
    return (
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <BorderButton width="50%" onClick={() => setIsEditing(true)}>
          Edit
        </BorderButton>
        <Spacer position="left" size="s" />

        <BorderButton
          width="50%"
          onClick={() => setShowDeleteConfirmation(true)}
          disabled={!address.deletable}
        >
          Delete
        </BorderButton>
      </Box>
    );
  };

  const onUpdateAddress = (values: UserAddressFormValues) => {
    setIsLoading(true);
    dispatch(updateUserAddress({ userId, ...values }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setIsEditing(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
        setIsLoading(false);
      });
  };

  const onRemoveAddress = () => {
    setIsLoading(true);
    dispatch(deleteUserAddress({ userId, addressId: address.id }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowDeleteConfirmation(false);
          setIsLoading(false);
          dispatch(getUserAddresses({ userId, page: 1 }));
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        key={address.id}
        spacing={1}
        bgcolor="bg.primary"
        sx={{
          padding: "10px",
          marginY: "10px",
          borderRadius: theme.shape.borderSizes[1],
        }}
      >
        <Form
          initialValues={{
            addressId: address.id,
            identifier: address?.identifier,
            pic: address?.pic,
            pic_contact_no: address?.pic_contact_no,
            unit_no: address?.location.unit_no,
            location: address?.location.address,
            location_lat: address?.location.lat,
            location_long: address?.location.long,
            city: address?.location.city,
            state: address?.location.state,
            postcode: address?.location.postcode,
            remark: address?.remark,
          }}
          validationSchema={validationSchema}
          onSubmit={onUpdateAddress}
        >
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Typography variant="h5" fontWeight="bold">
                Address {index + 1}
              </Typography>
              {renderButton()}
            </SpaceBetweenBox>
          </Grid>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12}>
              <FormTextField
                name="identifier"
                label="Address Name"
                disabled={!isEditing}
                isTransparent={!isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="pic"
                label="Person In Charge"
                disabled={!isEditing}
                isTransparent={!isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <FormPhoneTextField
                name="pic_contact_no"
                label="Contact No."
                disabled={!isEditing}
                isTransparent={!isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="unit_no"
                label="Unit No"
                disabled={!isEditing}
                isTransparent={!isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <FormAddressAutoComplete
                name="location"
                label="Address"
                disabled={!isEditing}
                isTransparent={!isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <FormSelectFieldText
                name="remark"
                placeholder="Remark"
                selections={REMARK_LIST}
                disabled={!isEditing}
                isTransparent={!isEditing}
              />
            </Grid>
          </Grid>
        </Form>
      </Grid>
      <BackdropLoading isLoading={isLoading} />
      <ConfirmationModal
        showModal={showDeleteConfirmation}
        setShowModal={setShowDeleteConfirmation}
        onConfirm={onRemoveAddress}
        title="Remove Address"
        message={`Are you sure you wish to remove the address named "${address?.identifier}"?`}
      />
    </>
  );
}
