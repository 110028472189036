import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../../api.services";
import { idleObject } from "../../idle-object";
import { RequestObject, RootState } from "../../store";

export const getUserAddresses = createAsyncThunk(
  "employees/users/{user_id}/addresses",
  async (payload: any) => {
    const { q, page, userId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${userId}/addresses?page=${page}${
        q ? "&q=".concat(q) : ""
      }`,
    );
    return response;
  },
);

export const createUserAddress = createAsyncThunk(
  "employees/users/{user_id}/addresses/create",
  async (payload: any) => {
    const { userId } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${userId}/addresses`,
      payload,
    );
    return response;
  },
);

export const updateUserAddress = createAsyncThunk(
  "employees/users/{user_id}/address/update/{address_id}",
  async (payload: any) => {
    const { userId, addressId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${userId}/addresses/${addressId}`,
      payload,
    );
    return response;
  },
);

export const deleteUserAddress = createAsyncThunk(
  "employees/users/{user_id}/address/delete/{address_id}",
  async (payload: any) => {
    const { userId, addressId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/users/${userId}/addresses/${addressId}`,
    );
    return response;
  },
);

interface initialStateType {
  getUserAddressesObj: RequestObject;
  createUserAddressObj: RequestObject;
  updateUserAddressObj: RequestObject;
  deleteUserAddressObj: RequestObject;
}

const initialState: initialStateType = {
  getUserAddressesObj: idleObject(),
  createUserAddressObj: idleObject(),
  updateUserAddressObj: idleObject(),
  deleteUserAddressObj: idleObject(),
};

const userAddressSlice = createSlice({
  name: "userAddress",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserAddresses.pending, (state) => {
        state.getUserAddressesObj.status = "pending";
      })
      .addCase(getUserAddresses.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getUserAddressesObj.status = "succeeded";
        state.getUserAddressesObj.data = data;
        state.getUserAddressesObj.successMessage = message;
      })
      .addCase(getUserAddresses.rejected, (state, action) => {
        const { message } = action.error;

        state.getUserAddressesObj.status = "failed";
        state.getUserAddressesObj.errorMessage = message;
      })
      .addCase(createUserAddress.pending, (state) => {
        state.createUserAddressObj.status = "pending";
      })
      .addCase(createUserAddress.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createUserAddressObj.status = "succeeded";
        state.createUserAddressObj.data = data;
        state.createUserAddressObj.successMessage = message;
      })
      .addCase(createUserAddress.rejected, (state, action) => {
        const { message } = action.error;

        state.createUserAddressObj.status = "failed";
        state.createUserAddressObj.errorMessage = message;
      })
      .addCase(updateUserAddress.pending, (state) => {
        state.updateUserAddressObj.status = "pending";
      })
      .addCase(updateUserAddress.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updateUserAddressObj.status = "succeeded";
        state.updateUserAddressObj.data = data;
        state.updateUserAddressObj.successMessage = message;
      })
      .addCase(updateUserAddress.rejected, (state, action) => {
        const { message } = action.error;

        state.updateUserAddressObj.status = "failed";
        state.updateUserAddressObj.errorMessage = message;
      })
      .addCase(deleteUserAddress.pending, (state) => {
        state.deleteUserAddressObj.status = "pending";
      })
      .addCase(deleteUserAddress.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.deleteUserAddressObj.status = "succeeded";
        state.deleteUserAddressObj.data = data;
        state.deleteUserAddressObj.successMessage = message;
      })
      .addCase(deleteUserAddress.rejected, (state, action) => {
        const { message } = action.error;

        state.deleteUserAddressObj.status = "failed";
        state.deleteUserAddressObj.errorMessage = message;
      });
  },
});

export default userAddressSlice.reducer;

// state
export const userAddressSelector = (state: RootState) => state.userAddress;
