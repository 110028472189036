import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq } from "../../../api.services";
import { idleObject } from "../../../idle-object";
import { RequestObject, RootState } from "../../../store";

export const getFoodSetItems = createAsyncThunk(
  "employees/food_sets/{food_set_id}/items/index",
  async (payload: any) => {
    const { q, page, types, foodSetId } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_sets/${foodSetId}/items?page=${page}${
        q ? "&q=".concat(q) : ""
      }${types ? "&types=".concat(types) : ""}`,
    );
    return response;
  },
);

export const createFoodSetItems = createAsyncThunk(
  "employees/food_sets/{food_set_id}/items/create",
  async (payload: any) => {
    const { foodSetId } = payload;
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_sets/${foodSetId}/items`,
      payload,
    );
    return response;
  },
);

export const deleteFoodSetItem = createAsyncThunk(
  "employees/food_sets/{food_set_id}/items/{food_set_item_id}/delete",
  async (payload: any) => {
    const { foodSetId, foodSetItemId } = payload;
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_sets/${foodSetId}/items/${foodSetItemId}`,
    );
    return response;
  },
);

interface initialStateType {
  getFoodSetItemsObj: RequestObject;
  createFoodSetItemsObj: RequestObject;
  deleteFoodSetItemObj: RequestObject;
}

const initialState: initialStateType = {
  getFoodSetItemsObj: idleObject(),
  createFoodSetItemsObj: idleObject(),
  deleteFoodSetItemObj: idleObject(),
};

const foodSetItemSlice = createSlice({
  name: "foodSetItem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFoodSetItems.pending, (state) => {
        state.getFoodSetItemsObj.status = "pending";
      })
      .addCase(getFoodSetItems.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getFoodSetItemsObj.status = "succeeded";
        state.getFoodSetItemsObj.data = data;
        state.getFoodSetItemsObj.successMessage = message;
      })
      .addCase(getFoodSetItems.rejected, (state, action) => {
        const { message } = action.error;

        state.getFoodSetItemsObj.status = "failed";
        state.getFoodSetItemsObj.errorMessage = message;
      })
      .addCase(createFoodSetItems.pending, (state) => {
        state.createFoodSetItemsObj.status = "pending";
      })
      .addCase(createFoodSetItems.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createFoodSetItemsObj.status = "succeeded";
        state.createFoodSetItemsObj.data = data;
        state.createFoodSetItemsObj.successMessage = message;
      })
      .addCase(createFoodSetItems.rejected, (state, action) => {
        const { message } = action.error;

        state.createFoodSetItemsObj.status = "failed";
        state.createFoodSetItemsObj.errorMessage = message;
      })
      .addCase(deleteFoodSetItem.pending, (state) => {
        state.deleteFoodSetItemObj.status = "pending";
      })
      .addCase(deleteFoodSetItem.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.deleteFoodSetItemObj.status = "succeeded";
        state.deleteFoodSetItemObj.data = data;
        state.deleteFoodSetItemObj.successMessage = message;
      })
      .addCase(deleteFoodSetItem.rejected, (state, action) => {
        const { message } = action.error;

        state.deleteFoodSetItemObj.status = "failed";
        state.deleteFoodSetItemObj.errorMessage = message;
      });
  },
});

export default foodSetItemSlice.reducer;

// state
export const foodSetItemSelector = (state: RootState) => state.foodSetItem;
