import {
  Box,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormSearchBar from "../../../../components/forms/form-search-bar.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import Form from "../../../../components/forms/form.component";
import { useSnackBar } from "../../../../components/notification/snackbar.context";
import TableHeader from "../../../../components/tables/table-header.component";
import TableLoader from "../../../../components/tables/table-loader.component";
import TableSort from "../../../../components/tables/table-sort.component";
import TableWrapper from "../../../../components/tables/table-wrapper.component";
import GreyBgCardContainer from "../../../../components/utils/grey-bg-card-container.component";
import theme from "../../../../infrastructure/theme";
import {
  foodSetSelector,
  getFoodSets,
} from "../../../../services/food/food-set/food-set-slice.service";
import { useAppDispatch } from "../../../../services/store";
import { FoodSetListsFilterOptionFormValues } from "../../type/type";
import FoodSetListTableRow from "../components/food-set-list-table-row.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)({
  width: "100%",
  borderRadius: theme.shape.borderSizes[1],
  overflow: "hidden",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable(),
});

export default function FoodSetPackagesScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [q, setQ] = useState("");
  const [page, setPage] = useState(1);
  const { getFoodSetsObj } = useSelector(foodSetSelector);
  const [totalPages, setTotalPages] = useState(1);
  const columnMapping = {
    ID: "id",
    Set: "identifier",
    "Total Price Per Pax": "pricePerPax",
    "Total Meals": "totalMeals",
    Status: "status",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshFoodSetList = (newPage: number, newQ: string) => {
    setPage(newPage);
    setQ(newQ);

    dispatch(
      getFoodSets({
        page: newPage,
        q: newQ,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTotalPages(res.data.pagination.totalPages);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onChangeSearch = (keyword: string) => {
    onRefreshFoodSetList(1, keyword);
  };

  const submitFilter = (values: FoodSetListsFilterOptionFormValues) => {
    onRefreshFoodSetList(1, values.q);
  };

  const onPageChange = (e: any, newPage: number) => {
    onRefreshFoodSetList(newPage, q);
  };

  useEffect(() => {
    onRefreshFoodSetList(page, q);
  }, []);

  const getColumnValue = (item: any, column: string) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value: string) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getFoodSetsObj.data && getFoodSetsObj.status === "succeeded") {
      records = { ...getFoodSetsObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    const records = getProcessedRecord();

    if (getFoodSetsObj.data.items.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={9}>
            <TableEmptyBox>
              <Typography>No items</Typography>
            </TableEmptyBox>
          </TableCell>
        </TableRow>
      );
    }
    return records?.items.map((item: any) => <FoodSetListTableRow item={item} key={item.id} />);
  };

  return (
    <Form
      initialValues={{
        q: "",
      }}
      onSubmit={submitFilter}
      validationSchema={validationSchema}
    >
      <Grid
        container
        spacing={3}
        sx={{
          paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
          paddingY: "50px",
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight="bold">
            Food Set Packages
          </Typography>
        </Grid>

        <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
          <GreyBgCardContainer>
            <SearchContainer sx={{ padding: "15px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormContainer>
                        <Grid item xs={3}>
                          <Typography>Search</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormSearchBar
                            placeholder="Search by set name"
                            name="q"
                            searchKeyword={q}
                            setSearchKeyword={onChangeSearch}
                            isWhiteBg
                          />
                        </Grid>
                      </FormContainer>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                    <Grid item xs={12} sm={4}>
                      <FormSubmitButton>Search</FormSubmitButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </SearchContainer>
          </GreyBgCardContainer>
        </Grid>

        <Grid item xs={12}>
          {getFoodSetsObj.status === "succeeded" ? (
            <TableWrapper>
              <Table>
                <TableHeader
                  headerCells={[
                    "ID",
                    "Set",
                    "Total Price Per Pax",
                    "Total Meals",
                    "Status",
                    "Action",
                  ]}
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  onSortChange={onSortChange}
                  columnMapping={columnMapping}
                />
                <TableBody>{renderTableBody()}</TableBody>
              </Table>
            </TableWrapper>
          ) : (
            <TableLoader />
          )}
        </Grid>
        <Grid item xs={12}>
          <CustomFooter>
            <Pagination
              sx={{
                "&& .Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "white.main",
                },
              }}
              page={page}
              shape="rounded"
              onChange={onPageChange}
              count={getFoodSetsObj?.data?.pagination.totalPages || totalPages}
              variant="outlined"
            />
          </CustomFooter>
        </Grid>
      </Grid>
    </Form>
  );
}
