import { Box, FormHelperText, Grid, Modal, styled, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormAutoCompletePaginationMultiselect from "../../../../components/forms/form-auto-complete-pagination-multiselect.component";
import FormDatePicker from "../../../../components/forms/form-date-picker.component";
import FormSubmitButton from "../../../../components/forms/form-submit-button.component";
import { Form, Formik } from "formik";
import TopTitleBox from "../../../../components/utils/top-title-box.component";
import theme from "../../../../infrastructure/theme";
import { foodCalendarSelector } from "../../../../services/food/food-calendar/food-calendar-slice.service";
import { foodSelector, getFoods } from "../../../../services/food/food-dishes-slice.service";
import { FoodCalendarCreateFormValues } from "../../type/type";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24px",
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
});

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  width?: string | number;
  onCreateFoodCalendars: (values: FoodCalendarCreateFormValues) => void;
}

const validationSchema = Yup.object().shape({
  date: Yup.date().label("Date").nullable(),
  food_ids: Yup.array().label("Foods").nullable().min(1),
});

export default function CreateFoodCalendarModal({
  showModal,
  title,
  setShowModal,
  width,
  onCreateFoodCalendars,
}: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getFoodsObj } = useSelector(foodSelector);
  const { createFoodCalendarsObj } = useSelector(foodCalendarSelector);
  const [date, setDate] = useState(null);

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "360px" : width, paddingX: isMobile ? "25px" : "40px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TopTitleBox title={title} requireCloseButton={true} setShowModal={setShowModal} />
          </Grid>

          <Grid item xs={12}>
            <Formik
              initialValues={{
                date: date,
                food_ids: [],
              }}
              validationSchema={validationSchema}
              onSubmit={onCreateFoodCalendars}
            >
              {({ setFieldValue }) => (
                <Form>
                  <Grid item container spacing={2}>
                    <Grid item xs={12}>
                      <FormDatePicker
                        name="date"
                        disablePast={false}
                        onChangeAction={(date) => {
                          setDate(null);
                          setTimeout(() => setDate(date), 50);
                          setFieldValue("food_ids", []);
                        }}
                      />
                    </Grid>

                    {date && (
                      <Grid item xs={12}>
                        <FormAutoCompletePaginationMultiselect
                          name="food_ids"
                          placeholder="Enter dish names"
                          dispatchFunction={getFoods}
                          requestObject={getFoodsObj}
                          optionId="id"
                          optionLabel="identifier"
                          extraKeys={{
                            excludeDate: date ? dayjs(date).format("YYYY-MM-DD") : null,
                          }}
                          disabled={getFoodsObj?.data?.items.length === 0}
                        />
                        {getFoodsObj?.data?.items.length === 0 && (
                          <FormHelperText error sx={{ marginX: "14px" }}>
                            All food has been added on the selected date.
                          </FormHelperText>
                        )}
                      </Grid>
                    )}
                  </Grid>

                  <Grid item xs={12} paddingTop="50px">
                    <FormSubmitButton isLoading={createFoodCalendarsObj.status === "pending"}>
                      Create
                    </FormSubmitButton>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

CreateFoodCalendarModal.defaultProps = {
  width: "600px",
  title: "",
  message: "",
  buttonText: "Confirm",
};
