import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, FormHelperText, Grid, IconButton, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FormikValues, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import theme from "../../infrastructure/theme";
import ImageCropper from "./image-cropper.component";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const StyledButtonIcon = styled(IconButton)({
  position: "absolute",
  padding: 0,
  top: 5,
  right: 5,
  backgroundColor: "red",
  width: 25,
  height: 25,
});

const StyledCloseIcon = styled(CloseIcon)({
  width: 17,
  height: 17,
  stroke: theme.palette.common.white,
  strokeWidth: 3,
});

interface Props {
  name: string;
  filePreview: string;
  setFilePreview: React.Dispatch<React.SetStateAction<string>>;
  setOnImageChange: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
}

function ImageUpload({ name, filePreview, setFilePreview, setOnImageChange, disabled }: Props) {
  const theme = useTheme();
  const [image, setImage] = useState("");
  const [showCropper, setShowCropper] = useState(false);
  const { setFieldValue, errors, setFieldError, setFieldTouched } =
    useFormikContext<FormikValues>();
  const showError = typeof errors[name] === "string";
  const handleRemoveImage = () => {
    setFieldValue(name, "");
    setFieldError(name, "");
    setFilePreview("");
    setOnImageChange(true);
  };

  return (
    <Grid container flexDirection="column">
      {filePreview ? (
        <Grid item>
          <Box
            sx={{
              position: "relative",
              width: "166px",
              "& .MuiIconButton-root": {
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              },
            }}
          >
            <img
              src={filePreview}
              alt="gym sites"
              style={{
                height: "166px",
                width: "166px",
                borderRadius: theme.shape.borderSizes[1],
                backgroundSize: "100% 100%",
              }}
            />
            {!disabled && (
              <StyledButtonIcon onClick={() => handleRemoveImage()}>
                <StyledCloseIcon />
              </StyledButtonIcon>
            )}
          </Box>
        </Grid>
      ) : (
        <Grid item>
          <Button
            onClick={() => {
              setFieldError(name, "");
            }}
            onBlur={() => setFieldTouched(name)}
            disabled={disabled}
            component="label"
            sx={{
              backgroundColor: "transparent",
              borderRadius: theme.shape.borderSizes[1],
              border: `2px dashed ${theme.palette.text.disabled}`,
              "&:hover": {
                background: "transparent",
              },
            }}
          >
            <Box
              sx={{
                height: "150px",
                width: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: theme.palette.text.disabled,
              }}
            >
              <AddCircleOutlineRoundedIcon />
            </Box>
            <input
              type="file"
              name={name}
              hidden
              accept="image/*"
              disabled={disabled}
              onClick={(event: any) => {
                event.target.value = null;
              }}
              onBlur={() => setFieldTouched(name)}
              onChange={(e) => {
                const { files } = e.target;
                if (files) {
                  const myFile = files[0]; // single file upload only
                  const allowedFileType = myFile && SUPPORTED_FORMATS.includes(myFile.type);

                  if (myFile && allowedFileType) {
                    setImage(URL.createObjectURL(myFile));
                    setShowCropper(true);
                  } else {
                    if (!allowedFileType) {
                      setFieldError(name, `Only ${SUPPORTED_FORMATS} are allowed`);
                    }
                    setFilePreview("");
                  }
                }
              }}
            />
          </Button>
        </Grid>
      )}
      <Grid item>
        {showError && errors[name] && (
          <FormHelperText error sx={{ margin: "3px 14px 0px" }}>
            {String(errors[name])}
          </FormHelperText>
        )}
      </Grid>
      <ImageCropper
        name={name}
        setIsShowModal={setShowCropper}
        isShowModal={showCropper}
        imageFile={image}
        setFilePreview={setFilePreview}
      />
    </Grid>
  );
}

ImageUpload.defaultProps = {
  filePreview: "",
  setOnImageChange: () => {},
  disabled: false,
};

ImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  filePreview: PropTypes.string,
  setFilePreview: PropTypes.func.isRequired,
  setOnImageChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ImageUpload;
