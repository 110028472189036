import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const getPopularDishes = createAsyncThunk("employees/popular_dishes/index", async () => {
  const response = await getReq(`${process.env.REACT_APP_API_PRIVATE_V1}/employees/popular_dishes`);
  return response;
});

export const createPopularDishes = createAsyncThunk(
  "employees/popular_dishes/create",
  async (payload: any) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/popular_dishes`,
      payload,
    );
    return response;
  },
);

export const deletePopularDish = createAsyncThunk(
  "employees/popular_dishes/delete",
  async (payload: any) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/popular_dishes`,
      payload,
    );
    return response;
  },
);

export const reorderPopularDish = createAsyncThunk(
  "employees/popular_dishes/reorder",
  async (payload: any) => {
    const { popularDishId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/popular_dishes/${popularDishId}/reorder`,
      payload,
    );
    return response;
  },
);

interface initialStateType {
  getPopularDishesObj: RequestObject;
  createPopularDishesObj: RequestObject;
  deletePopularDishObj: RequestObject;
  reorderPopularDishObj: RequestObject;
}

const initialState: initialStateType = {
  getPopularDishesObj: idleObject(),
  createPopularDishesObj: idleObject(),
  deletePopularDishObj: idleObject(),
  reorderPopularDishObj: idleObject(),
};

const imageBannerSlice = createSlice({
  name: "imageBanner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPopularDishes.pending, (state) => {
        state.getPopularDishesObj.status = "pending";
      })
      .addCase(getPopularDishes.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getPopularDishesObj.status = "succeeded";
        state.getPopularDishesObj.data = data;
        state.getPopularDishesObj.successMessage = message;
      })
      .addCase(getPopularDishes.rejected, (state, action) => {
        const { message } = action.error;

        state.getPopularDishesObj.status = "failed";
        state.getPopularDishesObj.errorMessage = message;
      })
      .addCase(createPopularDishes.pending, (state) => {
        state.createPopularDishesObj.status = "pending";
      })
      .addCase(createPopularDishes.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createPopularDishesObj.status = "succeeded";
        state.createPopularDishesObj.data = data;
        state.getPopularDishesObj.data = data;
        state.createPopularDishesObj.successMessage = message;
      })
      .addCase(createPopularDishes.rejected, (state, action) => {
        const { message } = action.error;

        state.createPopularDishesObj.status = "failed";
        state.createPopularDishesObj.errorMessage = message;
      })
      .addCase(deletePopularDish.pending, (state) => {
        state.deletePopularDishObj.status = "pending";
      })
      .addCase(deletePopularDish.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.deletePopularDishObj.status = "succeeded";
        state.deletePopularDishObj.data = data;
        state.getPopularDishesObj.data = data;
        state.deletePopularDishObj.successMessage = message;
      })
      .addCase(deletePopularDish.rejected, (state, action) => {
        const { message } = action.error;

        state.deletePopularDishObj.status = "failed";
        state.deletePopularDishObj.errorMessage = message;
      })
      .addCase(reorderPopularDish.pending, (state) => {
        state.reorderPopularDishObj.status = "pending";
      })
      .addCase(reorderPopularDish.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.reorderPopularDishObj.status = "succeeded";
        state.reorderPopularDishObj.data = data;
        state.getPopularDishesObj.data = data;
        state.reorderPopularDishObj.successMessage = message;
      })
      .addCase(reorderPopularDish.rejected, (state, action) => {
        const { message } = action.error;

        state.reorderPopularDishObj.status = "failed";
        state.reorderPopularDishObj.errorMessage = message;
      });
  },
});

export default imageBannerSlice.reducer;

// state
export const imageBannerSelector = (state: RootState) => state.imageBanner;
