import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  Box,
  Grid,
  IconButton,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormMultipleSelect from "../../../components/forms/form-multiple-select.component";
import FormSearchBar from "../../../components/forms/form-search-bar.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import TableHeader from "../../../components/tables/table-header.component";
import TableLoader from "../../../components/tables/table-loader.component";
import TableSort from "../../../components/tables/table-sort.component";
import TableWrapper from "../../../components/tables/table-wrapper.component";
import GreyBgCardContainer from "../../../components/utils/grey-bg-card-container.component";
import theme from "../../../infrastructure/theme";
import {
  createFood,
  createFoodBulk,
  foodSelector,
  getFoods,
} from "../../../services/food/food-dishes-slice.service";
import { useAppDispatch } from "../../../services/store";
import FoodListTableRow from "../components/food-list-table-row.component";
import {
  FoodCreateBulkFormValues,
  FoodCreateFormValues,
  FoodListsFilterOptionFormValues,
} from "../type/type";
import CreateFoodModal from "../components/create-food-modal.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

const TableEmptyBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  height: "100px",
  alignItems: "center",
});

const SearchContainer = styled(Box)({
  width: "100%",
  borderRadius: theme.shape.borderSizes[1],
  overflow: "hidden",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
});

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: "10px",
});

const validationSchema = Yup.object().shape({
  q: Yup.string().nullable(),
  types: Yup.array().label("Types").of(Yup.string()),
  statuses: Yup.array().label("Statuses").of(Yup.string()),
});

const TYPES_LIST = [
  { id: "1", label: "Meat", value: "meat" },
  { id: "2", label: "Vegetables", value: "vege" },
  { id: "3", label: "Sides", value: "side" },
  { id: "4", label: "Carbs", value: "carb" },
  { id: "5", label: "Addons", value: "addon" },
];

const STATUS_LIST = [
  { id: "1", label: "Draft", value: "draft" },
  { id: "2", label: "Publish", value: "publish" },
  { id: "3", label: "Archive", value: "archive" },
];

export default function FoodDishesListScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [q, setQ] = useState("");
  const [types, setTypes] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const { getFoodsObj } = useSelector(foodSelector);
  const [totalPages, setTotalPages] = useState(1);
  const [showAddModal, setShowAddModal] = useState(false);

  const columnMapping = {
    ID: "id",
    Dish: "identifier",
    Type: "type",
    Status: "status",
    Price: "price",
    "Created At": "createdAt",
  };
  const { sortColumn, sortOrder, onSortChange } = TableSort(columnMapping);

  const onRefreshFoodList = (
    newPage: number,
    newQ: string,
    newTypes: string[],
    newStatus: string[],
  ) => {
    setPage(newPage);
    setQ(newQ);
    setTypes(newTypes);
    setStatus(newStatus);
    dispatch(
      getFoods({
        page: newPage,
        q: newQ,
        types: newTypes,
        statuses: newStatus,
        excludeDate: null,
        excludeFoodSetId: [],
      }),
    )
      .unwrap()
      .then((res) => {
        if (res.success) {
          setTotalPages(res.data.pagination.totalPages);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onChangeSearch = (keyword: string) => {
    onRefreshFoodList(1, keyword, types, status);
  };

  const submitFilter = (values: FoodListsFilterOptionFormValues) => {
    onRefreshFoodList(1, values.q, values.types, values.statuses);
  };

  const onPageChange = (e: any, newPage: number) => {
    onRefreshFoodList(newPage, q, types, status);
  };

  useEffect(() => {
    onRefreshFoodList(page, q, types, status);
  }, []);

  const getColumnValue = (item: any, column: string) => {
    const nestedProperties = column.split(".");

    return nestedProperties.reduce(
      (value, prop) => (value && value[prop] !== undefined ? value[prop] : null),
      item,
    );
  };

  const parseValue = (value: string) => {
    if (typeof value === "string") {
      // Handle string values: Remove commas and dashes then parse the string as a number
      return parseFloat(value.replace(/[, -]/g, "")) || value;
    }
    return value;
  };

  const getProcessedRecord = () => {
    let records = null;

    if (getFoodsObj.data && getFoodsObj.status === "succeeded") {
      records = { ...getFoodsObj.data };

      const sortedData = [...records.items].sort((a, b) => {
        if (sortColumn) {
          const columnA = parseValue(getColumnValue(a, sortColumn));
          const columnB = parseValue(getColumnValue(b, sortColumn));

          // Handle boolean values
          if (typeof columnA === "boolean" && typeof columnB === "boolean") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle numeric values without converting to strings
          if (typeof columnA === "number" && typeof columnB === "number") {
            return sortOrder === "asc" ? columnA - columnB : columnB - columnA;
          }

          // Handle string values
          const stringColumnA = typeof columnA === "string" ? columnA : "";
          const stringColumnB = typeof columnB === "string" ? columnB : "";

          return sortOrder === "asc"
            ? stringColumnA.localeCompare(stringColumnB)
            : stringColumnB.localeCompare(stringColumnA);
        }
        return 0; // No sorting if sortColumn is null
      });

      // Replace the original items array with the sortedData
      records.items = sortedData;
    }
    return records;
  };

  const renderTableBody = () => {
    const records = getProcessedRecord();

    if (getFoodsObj.data.items.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={9}>
            <TableEmptyBox>
              <Typography>No items</Typography>
            </TableEmptyBox>
          </TableCell>
        </TableRow>
      );
    }
    return records?.items.map((item: any) => <FoodListTableRow item={item} key={item.id} />);
  };

  const onCreateFood = (values: FoodCreateFormValues) => {
    const amountCents = values.priceRM * 100;
    dispatch(createFood({ amountCents, ...values }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowAddModal(false);
          onRefreshFoodList(page, q, types, status);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onCreateFoodBulk = (values: FoodCreateBulkFormValues) => {
    values.foods.forEach((food) => {
      const amountCents = food.priceRM * 100;
      food.amountCents = amountCents;
    });
    dispatch(createFoodBulk({ ...values }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowAddModal(false);
          onRefreshFoodList(page, q, types, status);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <>
      <CreateFoodModal
        title="Add Food Item"
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        onCreateFood={onCreateFood}
        onCreateFoodBulk={onCreateFoodBulk}
      />
      <Form
        initialValues={{
          q: "",
          types: [],
          statuses: [],
        }}
        onSubmit={submitFilter}
        validationSchema={validationSchema}
      >
        <Grid
          container
          spacing={3}
          sx={{
            paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
            paddingY: "50px",
          }}
        >
          <Grid item xs={12}>
            <SpaceBetweenBox>
              <Typography variant="h4" fontWeight="bold">
                Dishes List
              </Typography>

              <IconButton size="large" onClick={() => setShowAddModal(true)}>
                <AddBoxIcon fontSize="large" color="primary" />
              </IconButton>
            </SpaceBetweenBox>
          </Grid>

          <Grid item sx={{ display: "flex", flex: 1, width: "100%" }}>
            <GreyBgCardContainer>
              <SearchContainer sx={{ padding: "15px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Typography>Search</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <FormSearchBar
                              placeholder="Search by food name"
                              name="q"
                              searchKeyword={q}
                              setSearchKeyword={onChangeSearch}
                              isWhiteBg
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>

                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Typography>Types</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <FormMultipleSelect
                              itemList={TYPES_LIST}
                              name="types"
                              placeholder="Types"
                              isWhiteBg
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>

                      <Grid item xs={12}>
                        <FormContainer>
                          <Grid item xs={3}>
                            <Typography>Statuses</Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <FormMultipleSelect
                              itemList={STATUS_LIST}
                              name="statuses"
                              placeholder="Status"
                              isWhiteBg
                            />
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} sx={{ justifyContent: "flex-end" }}>
                      <Grid item xs={12} sm={4}>
                        <FormSubmitButton>Search</FormSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SearchContainer>
            </GreyBgCardContainer>
          </Grid>

          <Grid item xs={12}>
            {getFoodsObj.status === "succeeded" ? (
              <TableWrapper>
                <Table>
                  <TableHeader
                    headerCells={["ID", "Dish", "Type", "Status", "Price", "Created At", "Action"]}
                    sortColumn={sortColumn}
                    sortOrder={sortOrder}
                    onSortChange={onSortChange}
                    columnMapping={columnMapping}
                  />
                  <TableBody>{renderTableBody()}</TableBody>
                </Table>
              </TableWrapper>
            ) : (
              <TableLoader />
            )}
          </Grid>
          <Grid item xs={12}>
            <CustomFooter>
              <Pagination
                sx={{
                  "&& .Mui-selected": {
                    backgroundColor: "primary.main",
                    color: "white.main",
                  },
                }}
                page={page}
                shape="rounded"
                onChange={onPageChange}
                count={getFoodsObj?.data?.pagination.totalPages || totalPages}
                variant="outlined"
              />
            </CustomFooter>
          </Grid>
        </Grid>
      </Form>
    </>
  );
}
