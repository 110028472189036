import { Box, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import { useSelector } from "react-redux";
import BackgroundButton from "../../../components/button/background-button.component";
import BorderButton from "../../../components/button/border-button.component";
import FormDatePicker from "../../../components/forms/form-date-picker.component";
import FormPhoneTextField from "../../../components/forms/form-phone-text-field.component";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";
import FormTextField from "../../../components/forms/form-text-field.component";
import Spacer from "../../../components/utils/spacer.component";
import { userSelector } from "../../../services/user/user-slice.service";

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const SpacebetweenRowBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  marginBottom: "10px",
});

interface Props {
  name: string;
  label: string;
  placeholder: string;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UserDetailEditForm({
  name,
  label,
  placeholder,
  isEditing,
  setIsEditing,
}: Props) {
  const { handleSubmit, setFieldValue } = useFormikContext();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const { showUserObj, updateUserDetailObj } = useSelector(userSelector);

  const renderButton = () => {
    if (isEditing) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <BackgroundButton
            onClick={() => {
              setFieldValue(name, showUserObj.data[name] === null ? "" : showUserObj.data[name]);
              setIsEditing(false);
            }}
            isLoading={updateUserDetailObj.status === "pending"}
          >
            Cancel
          </BackgroundButton>
          <Spacer position="left" size="s" />
          <BackgroundButton
            onClick={() => handleSubmit()}
            isLoading={updateUserDetailObj.status === "pending"}
          >
            Save
          </BackgroundButton>
        </Box>
      );
    }
    return (
      <BorderButton width="80px" onClick={() => setIsEditing(true)}>
        Edit
      </BorderButton>
    );
  };

  const renderField = () => {
    if (name === "phone") {
      return <FormPhoneTextField name={name} label={placeholder} disabled={!isEditing} />;
    }

    if (name === "gender") {
      return (
        <FormSelectFieldText
          selections={[
            { label: "Male", value: "male", id: 1 },
            { label: "Female", value: "female", id: 2 },
          ]}
          name={name}
          placeholder={placeholder}
          disabled={!isEditing}
          isTransparent={!isEditing}
        />
      );
    }

    if (name === "dateOfBirth") {
      return (
        <FormDatePicker
          name={name}
          disabled={!isEditing}
          disablePast={false}
          isWhiteBg={isEditing}
        />
      );
    }

    return (
      <FormTextField
        name={name}
        label={placeholder}
        disabled={!isEditing}
        isTransparent={!isEditing}
      />
    );
  };

  return (
    <>
      {isSmallScreen && (
        <SpacebetweenRowBox>
          <LabelContainer>
            <Typography fontWeight="bold">{label}</Typography>
          </LabelContainer>
          {renderButton()}
        </SpacebetweenRowBox>
      )}

      <FormContainer>
        {!isSmallScreen && (
          <LabelContainer>
            <Typography fontWeight="bold">{label}</Typography>
          </LabelContainer>
        )}
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <Box sx={{ width: "100%" }}>{renderField()}</Box>
        </Box>

        {!isSmallScreen && (
          <>
            <Spacer position="left" size="m" />
            {renderButton()}
          </>
        )}
      </FormContainer>
    </>
  );
}
