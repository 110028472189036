import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import { useAppDispatch } from "../../../services/store";
import { showUser } from "../../../services/user/user-slice.service";
import UserDetail from "../components/user-detail.component";

export default function UserEditDetailScreen() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const location = useLocation();
  const userId = new URLSearchParams(location.search).get("userId");
  const snackBar = useSnackBar();

  useEffect(() => {
    dispatch(showUser({ userId }))
      .unwrap()
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  }, []);

  return (
    <Grid
      container
      spacing={3}
      sx={{
        paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
        paddingY: "50px",
      }}
    >
      <Grid item sx={{ display: "flex", flex: 1 }}>
        <UserDetail />
      </Grid>
    </Grid>
  );
}
