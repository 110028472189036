import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, IconButton, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import theme from "../../../infrastructure/theme";
import { updatePolygon } from "../../../services/delivery-zone/delivery-zone-slice.service";
import { useAppDispatch } from "../../../services/store";
import { PolygonFormValues } from "../type/type";
import EditDeliveryZoneModal from "./edit-delivery-zone-modal.component";

interface Props {
  item: {
    id: number;
    identifier: string;
    active: boolean;
    order: number;
    chargePrice: string;
  };
}

export default function DeliveryZoneTableRow({ item }: Props) {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const [showMenu, setShowMenu] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleOpenMenu = (event: any) => {
    setShowMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setShowMenu(null);
  };

  const onHandleUpdate = (values: PolygonFormValues) => {
    const chargeAmountCents = values.chargePriceRM * 100;
    let activeTemp = values.active;
    if (typeof activeTemp === "string") {
      activeTemp = activeTemp === "true" ? true : false;
    }

    dispatch(updatePolygon({ polygonId: item.id, chargeAmountCents, active: activeTemp }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowEditModal(false);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  return (
    <>
      <EditDeliveryZoneModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        onUpdatePolygon={onHandleUpdate}
        title="Update Delivery Zone"
        item={item}
      />
      <TableRow
        key={item.id}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "100px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.id}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.identifier}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {item.order}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            RM {item.chargePrice}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Typography color={item.active ? "green" : "red"} sx={{ textAlign: "center" }}>
            {item.active ? "Yes" : "No"}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <IconButton onClick={handleOpenMenu}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              sx={{ marginTop: theme.spacing(4) }}
              id="menu-appbar"
              anchorEl={showMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={Boolean(showMenu)}
              onClose={handleCloseMenu}
            >
              <MenuItem
                key={1}
                onClick={() => {
                  setShowEditModal(true);
                  handleCloseMenu();
                }}
              >
                <Typography textAlign="center">Update</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}
