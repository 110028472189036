import { Box, Button, Grid, Pagination, Typography, styled } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BorderButton from "../../../components/button/border-button.component";
import routes from "../../../components/navigation/routes";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import Spacer from "../../../components/utils/spacer.component";
import { useAppDispatch } from "../../../services/store";
import {
  createUserAddress,
  getUserAddresses,
  userAddressSelector,
} from "../../../services/user/address/address-slice.service";
import { UserAddressFormValues } from "../type/type";
import CreateUserAddressModal from "./create-user-address-modal.component";
import UserAddressEditForm from "./user-address-edit-form.component";

const CustomFooter = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  minHeight: "70px",
  alignItems: "center",
});

export default function UserAddress() {
  const { getUserAddressesObj } = useSelector(userAddressSelector);
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const userId = new URLSearchParams(location.search).get("userId");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [page, setPage] = useState(1);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    dispatch(getUserAddresses({ userId, page: newPage }))
      .unwrap()
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onCreateAddress = (values: UserAddressFormValues) => {
    dispatch(createUserAddress({ userId, ...values }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setShowCreateModal(false);
          onPageChange(page);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const renderContent = () => {
    if (getUserAddressesObj.status === "succeeded") {
      return (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: " 30px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography variant="h4" fontWeight="bold">
                User Address List
              </Typography>
              <Spacer position="left" size="l" />
              <BorderButton width="100px" onClick={() => setShowCreateModal(true)}>
                Create
              </BorderButton>
            </Box>
            <Button
              onClick={() => navigate(routes.USER)}
              sx={{
                textTransform: "none",
                padding: "0px",
                ":hover": { backgroundColor: "transparent" },
              }}
            >
              <Typography>Back</Typography>
            </Button>
          </Box>

          <Grid container sx={{ paddingY: "20px" }}>
            {getUserAddressesObj?.data?.items.length !== 0 ? (
              getUserAddressesObj?.data?.items.map((item: any, index: number) => (
                <UserAddressEditForm address={item} key={item.id} index={index} />
              ))
            ) : (
              <Grid item xs={12}>
                <Typography>No addresses found for this user.</Typography>
              </Grid>
            )}
          </Grid>
        </>
      );
    }
  };

  return (
    <>
      <Grid item xs={12}>
        {renderContent()}

        {getUserAddressesObj?.data?.items.length !== 0 && (
          <Grid item xs={12}>
            <CustomFooter>
              <Pagination
                sx={{
                  "&& .Mui-selected": {
                    backgroundColor: "primary.main",
                    color: "white.main",
                  },
                }}
                page={page}
                shape="rounded"
                onChange={(event, newPage) => onPageChange(newPage)}
                count={getUserAddressesObj?.data?.pagination.totalPages}
                variant="outlined"
              />
            </CustomFooter>
          </Grid>
        )}
      </Grid>
      <CreateUserAddressModal
        title="New Address"
        showModal={showCreateModal}
        setShowModal={setShowCreateModal}
        onCreateAddress={onCreateAddress}
      />
    </>
  );
}
