import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { PropsWithChildren } from "react";
import theme from "../../infrastructure/theme";

export default function GreyBgCardContainer({ children, ...props }: PropsWithChildren) {
  return (
    <Box
      {...props}
      sx={{
        width: "100%",
        borderTopLeftRadius: theme.shape.borderSizes[1],
        borderTopRightRadius: theme.shape.borderSizes[1],
        borderBottomLeftRadius: theme.shape.borderSizes[1],
        borderBottomRightRadius: theme.shape.borderSizes[1],
        overflow: "hidden",
        backgroundColor: theme.palette.bg.primary,
        padding: "15px",
      }}
    >
      {children}
    </Box>
  );
}

GreyBgCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
