import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface Props {
  link: string;
  label: string;
  pushTo: string;
}

function LabelLink({ link, label, pushTo }: Props) {
  return (
    <Grid container justifyContent="center" columnSpacing={0.5}>
      <Grid item>
        <Typography variant="body2">{label}</Typography>
      </Grid>
      <Grid item>
        <Link to={pushTo} style={{ textDecoration: "none" }}>
          <Typography variant="body2" color="primary">
            {link}
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
}

export default LabelLink;
