import { TableCell, TableRow, Typography } from "@mui/material";

interface Props {
  summary: {
    identifier: string;
    type: string;
    noOfPax: number;
  };
}

export default function OrderSummaryTableRow({ summary }: Props) {
  return (
    <>
      <TableRow
        key={summary.identifier}
        sx={{
          "& .MuiTableCell-root": {
            borderBottom: "no-set",
          },
        }}
      >
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {summary.identifier}
          </Typography>
        </TableCell>

        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center", textTransform: "capitalize" }}>
            {summary.type}
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "200px" }}>
          <Typography sx={{ textAlign: "center" }}>{summary.noOfPax}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
}
