import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  List,
  ListItem,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../components/navigation/routes";

const NavigationButtonBox = styled(Box)({
  padding: "0x",
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  textTransform: "none",
  marginTop: "5px",
});

const StyledListItem = styled(ListItem)({
  ":hover": { backgroundColor: "transparent" },
  cursor: "pointer",
});

const AccordionDetailsWithoutPadding = styled(AccordionDetails)(({ theme }) => ({
  padding: "0px",
  paddingLeft: theme.spacing(4),
}));

function DrawerItems() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <List sx={{ paddingBottom: "50px", paddingTop: "25px" }}>
      <Grid container columnSpacing={2} display="block">
        <Grid item xs={12}>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{}} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                color={
                  location.pathname.toUpperCase().includes("ORDER")
                    ? "primary.main"
                    : "text.secondary"
                }
                variant="h6"
                fontWeight="bold"
              >
                Order
              </Typography>
            </AccordionSummary>

            <AccordionDetailsWithoutPadding>
              <StyledListItem
                onClick={() => {
                  navigate(routes.ORDER_SUBSCRIPTION);
                }}
              >
                <Typography
                  color={
                    location.pathname.toUpperCase().includes("ORDER/SUBSCRIPTION")
                      ? "primary.main"
                      : "text.secondary"
                  }
                  variant="h6"
                  fontWeight="bold"
                >
                  User Subscription
                </Typography>
              </StyledListItem>
              <StyledListItem
                onClick={() => {
                  navigate(routes.ORDER_PRODUCTION_SUMMARY);
                }}
              >
                <Typography
                  color={
                    location.pathname.toUpperCase().includes("ORDER/PRODUCTION/SUMMARY")
                      ? "primary.main"
                      : "text.secondary"
                  }
                  variant="h6"
                  fontWeight="bold"
                >
                  Production Summary
                </Typography>
              </StyledListItem>
              <StyledListItem
                onClick={() => {
                  navigate(routes.ORDER_DETAILS);
                }}
              >
                <Typography
                  color={
                    location.pathname.toUpperCase().includes("ORDER/DETAILS")
                      ? "primary.main"
                      : "text.secondary"
                  }
                  variant="h6"
                  fontWeight="bold"
                >
                  Order Details
                </Typography>
              </StyledListItem>
            </AccordionDetailsWithoutPadding>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <StyledListItem
            onClick={() => {
              navigate(routes.USER);
            }}
          >
            <NavigationButtonBox>
              <Typography
                color={
                  location.pathname.toUpperCase().includes("USER")
                    ? "primary.main"
                    : "text.secondary"
                }
                variant="h6"
                fontWeight="bold"
              >
                User
              </Typography>
            </NavigationButtonBox>
          </StyledListItem>
        </Grid>
        <Grid item xs={12}>
          <StyledListItem
            onClick={() => {
              navigate(routes.FOOD_DISHES_LIST);
            }}
          >
            <NavigationButtonBox>
              <Typography
                color={
                  location.pathname.toUpperCase().includes("FOOD/DISHES/LIST")
                    ? "primary.main"
                    : "text.secondary"
                }
                variant="h6"
                fontWeight="bold"
              >
                Dishes
              </Typography>
            </NavigationButtonBox>
          </StyledListItem>
        </Grid>
        <Grid item xs={12}>
          <StyledListItem
            onClick={() => {
              navigate(routes.FOOD_CALENDAR);
            }}
          >
            <NavigationButtonBox>
              <Typography
                color={
                  location.pathname.toUpperCase().includes("FOOD/CALENDAR")
                    ? "primary.main"
                    : "text.secondary"
                }
                variant="h6"
                fontWeight="bold"
              >
                Food Calendar
              </Typography>
            </NavigationButtonBox>
          </StyledListItem>
        </Grid>
        <Grid item xs={12}>
          <Accordion
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{}} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                color={
                  location.pathname.toUpperCase().includes("FOOD/SETS")
                    ? "primary.main"
                    : "text.secondary"
                }
                variant="h6"
                fontWeight="bold"
              >
                Food Sets
              </Typography>
            </AccordionSummary>

            <AccordionDetailsWithoutPadding>
              <StyledListItem
                onClick={() => {
                  navigate(routes.FOOD_SET_PACKAGES);
                }}
              >
                <Typography
                  color={
                    location.pathname.toUpperCase().includes("FOOD/SET/PACKAGES")
                      ? "primary.main"
                      : "text.secondary"
                  }
                  variant="h6"
                  fontWeight="bold"
                >
                  Packages
                </Typography>
              </StyledListItem>

              <StyledListItem
                onClick={() => {
                  navigate(routes.FOOD_SET_CREATE);
                }}
              >
                <Typography
                  color={
                    location.pathname.toUpperCase().includes("FOOD/SET/CREATE")
                      ? "primary.main"
                      : "text.secondary"
                  }
                  variant="h6"
                  fontWeight="bold"
                >
                  Create
                </Typography>
              </StyledListItem>
            </AccordionDetailsWithoutPadding>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          <StyledListItem
            onClick={() => {
              navigate(routes.HIGHLIGHT_DISH);
            }}
          >
            <NavigationButtonBox>
              <Typography
                color={
                  location.pathname.toUpperCase().includes("HIGHLIGHT_DISH")
                    ? "primary.main"
                    : "text.secondary"
                }
                variant="h6"
                fontWeight="bold"
              >
                Highlight Dish
              </Typography>
            </NavigationButtonBox>
          </StyledListItem>
        </Grid>
        <Grid item xs={12}>
          <StyledListItem
            onClick={() => {
              navigate(routes.IMAGE_BANNER);
            }}
          >
            <NavigationButtonBox>
              <Typography
                color={
                  location.pathname.toUpperCase().includes("IMAGE_BANNER")
                    ? "primary.main"
                    : "text.secondary"
                }
                variant="h6"
                fontWeight="bold"
              >
                Image Banner
              </Typography>
            </NavigationButtonBox>
          </StyledListItem>
        </Grid>
        <Grid item xs={12}>
          <StyledListItem
            onClick={() => {
              navigate(routes.DELIVERY_ZONE);
            }}
          >
            <NavigationButtonBox>
              <Typography
                color={
                  location.pathname.toUpperCase().includes("DELIVERY/ZONE")
                    ? "primary.main"
                    : "text.secondary"
                }
                variant="h6"
                fontWeight="bold"
              >
                Delivery Zone
              </Typography>
            </NavigationButtonBox>
          </StyledListItem>
        </Grid>
      </Grid>
    </List>
  );
}

export default DrawerItems;
