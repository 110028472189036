import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq } from "../../api.services";
import { idleObject } from "../../idle-object";
import { RequestObject, RootState } from "../../store";

export const getFoodCalendars = createAsyncThunk(
  "employees/food_calendars/index",
  async (payload: any) => {
    const { types, page, startDate, endDate } = payload;
    const response = await getReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_calendars?page=${page}${
        types ? "&types=".concat(types) : ""
      }${startDate ? "&startDate=".concat(startDate) : ""}${
        endDate ? "&endDate=".concat(endDate) : ""
      }`,
    );
    return response;
  },
);

export const createFoodCalendars = createAsyncThunk(
  "employees/food_calendars/create",
  async (payload: any) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_calendars`,
      payload,
    );
    return response;
  },
);

export const deleteFoodCalendar = createAsyncThunk(
  "employees/food_calendars/delete",
  async (payload: any) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/food_calendars`,
      payload,
    );
    return response;
  },
);

interface initialStateType {
  getFoodCalendarsObj: RequestObject;
  createFoodCalendarsObj: RequestObject;
  deleteFoodCalendarObj: RequestObject;
}

const initialState: initialStateType = {
  getFoodCalendarsObj: idleObject(),
  createFoodCalendarsObj: idleObject(),
  deleteFoodCalendarObj: idleObject(),
};

const foodCalendarSlice = createSlice({
  name: "foodCalendar",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFoodCalendars.pending, (state) => {
        state.getFoodCalendarsObj.status = "pending";
      })
      .addCase(getFoodCalendars.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getFoodCalendarsObj.status = "succeeded";
        state.getFoodCalendarsObj.data = data;
        state.getFoodCalendarsObj.successMessage = message;
      })
      .addCase(getFoodCalendars.rejected, (state, action) => {
        const { message } = action.error;

        state.getFoodCalendarsObj.status = "failed";
        state.getFoodCalendarsObj.errorMessage = message;
      })
      .addCase(createFoodCalendars.pending, (state) => {
        state.createFoodCalendarsObj.status = "pending";
      })
      .addCase(createFoodCalendars.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createFoodCalendarsObj.status = "succeeded";
        state.createFoodCalendarsObj.data = data;
        state.createFoodCalendarsObj.successMessage = message;
      })
      .addCase(createFoodCalendars.rejected, (state, action) => {
        const { message } = action.error;

        state.createFoodCalendarsObj.status = "failed";
        state.createFoodCalendarsObj.errorMessage = message;
      })
      .addCase(deleteFoodCalendar.pending, (state) => {
        state.deleteFoodCalendarObj.status = "pending";
      })
      .addCase(deleteFoodCalendar.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.deleteFoodCalendarObj.status = "succeeded";
        state.deleteFoodCalendarObj.data = data;
        state.getFoodCalendarsObj.data = data;
        state.deleteFoodCalendarObj.successMessage = message;
      })
      .addCase(deleteFoodCalendar.rejected, (state, action) => {
        const { message } = action.error;

        state.deleteFoodCalendarObj.status = "failed";
        state.deleteFoodCalendarObj.errorMessage = message;
      });
  },
});

export default foodCalendarSlice.reducer;

// state
export const foodCalendarSelector = (state: RootState) => state.foodCalendar;
