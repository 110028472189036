import { RequestObject } from "./store";

export const idleObject = (): RequestObject => {
  return {
    status: "idle",
    data: null,
    successMessage: null,
    errorMessage: null,
  };
};

export interface PaginatedCollection<T> {
  items: T[];
  pagination: {
    currentPage: number;
    totalPages: number;
    perPage: number;
    from: number;
    to: number;
    totalItems: number;
  };
}
