import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReq, putReq } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const getPolygons = createAsyncThunk("employees/polygons/index", async (payload: any) => {
  const { page } = payload;
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/polygons?page=${page}`,
  );
  return response;
});

export const updatePolygon = createAsyncThunk("employees/polygons/update", async (payload: any) => {
  const { polygonId } = payload;
  const response = await putReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/polygons/${polygonId}`,
    payload,
  );
  return response;
});

interface initialStateType {
  getPolygonsObj: RequestObject;
  updatePolygonObj: RequestObject;
}

const initialState: initialStateType = {
  getPolygonsObj: idleObject(),
  updatePolygonObj: idleObject(),
};

const deliveryZoneSlice = createSlice({
  name: "deliveryZone",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPolygons.pending, (state) => {
        state.getPolygonsObj.status = "pending";
      })
      .addCase(getPolygons.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getPolygonsObj.status = "succeeded";
        state.getPolygonsObj.data = data;
        state.getPolygonsObj.successMessage = message;
      })
      .addCase(getPolygons.rejected, (state, action) => {
        const { message } = action.error;

        state.getPolygonsObj.status = "failed";
        state.getPolygonsObj.errorMessage = message;
      })
      .addCase(updatePolygon.pending, (state) => {
        state.updatePolygonObj.status = "pending";
      })
      .addCase(updatePolygon.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.updatePolygonObj.status = "succeeded";
        state.updatePolygonObj.data = data;
        state.getPolygonsObj.data = data;
        state.updatePolygonObj.successMessage = message;
      })
      .addCase(updatePolygon.rejected, (state, action) => {
        const { message } = action.error;

        state.updatePolygonObj.status = "failed";
        state.updatePolygonObj.errorMessage = message;
      });
  },
});

export default deliveryZoneSlice.reducer;

// state
export const deliveryZoneSelector = (state: RootState) => state.deliveryZone;
