import { FormControlLabel, FormGroup, Typography } from "@mui/material";
import CheckBox from "@mui/material/Checkbox";
import { FormikValues, useFormikContext } from "formik";
import { ChangeEvent } from "react";
import theme from "../../infrastructure/theme";

interface Props {
  name: string;
  label?: string;
  disabled?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

declare module "@mui/material" {
  interface CheckboxPropsColorOverrides {
    white: true;
  }
}

function FormCheckBox({ name, label, disabled, handleChange, checked }: Props) {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.checked);
  };

  return (
    <FormGroup sx={{ justifyContent: "center", display: "flex" }}>
      <FormControlLabel
        disabled={disabled}
        sx={{ display: "flex", margin: 0 }}
        label={
          <Typography
            variant="body2"
            textAlign="justify"
            sx={{
              color: theme.palette.primary.main,
            }}
          >
            {label}
          </Typography>
        }
        name={name}
        control={
          <CheckBox
            checked={checked ? checked : values[name]}
            onChange={handleChange ? handleChange : handleOnChange}
          />
        }
      />
    </FormGroup>
  );
}

FormCheckBox.defaultProps = {
  disabled: false,
};

export default FormCheckBox;
