import { Box, Grid, Modal, styled, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormAutoCompletePaginationMultiselect from "../../../components/forms/form-auto-complete-pagination-multiselect.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import theme from "../../../infrastructure/theme";
import { foodSelector, getFoods } from "../../../services/food/food-dishes-slice.service";
import { highlightDishSelector } from "../../../services/highlight-dish/highlight-dish-slice.service";
import { HighlighDishFormValues } from "../type/type";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24px",
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
});

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  width?: string | number;
  onCreateHighlightDishes: (values: HighlighDishFormValues) => void;
}

const validationSchema = Yup.object().shape({
  food_ids: Yup.array().label("Foods").nullable().min(1),
});

export default function CreateHighlightDishModal({
  showModal,
  title,
  setShowModal,
  width,
  onCreateHighlightDishes,
}: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getFoodsObj } = useSelector(foodSelector);
  const { createHighlightDishesObj } = useSelector(highlightDishSelector);

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "360px" : width, paddingX: isMobile ? "25px" : "40px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TopTitleBox title={title} requireCloseButton={true} setShowModal={setShowModal} />
          </Grid>

          <Form
            initialValues={{
              food_ids: [],
            }}
            validationSchema={validationSchema}
            onSubmit={onCreateHighlightDishes}
          >
            <Grid item xs={12}>
              <FormAutoCompletePaginationMultiselect
                name="food_ids"
                placeholder="Enter dish names"
                dispatchFunction={getFoods}
                requestObject={getFoodsObj}
                optionId="id"
                optionLabel="identifier"
              />
            </Grid>

            <Grid item xs={12} paddingTop="50px">
              <FormSubmitButton isLoading={createHighlightDishesObj.status === "pending"}>
                Create
              </FormSubmitButton>
            </Grid>
          </Form>
        </Grid>
      </ModalBox>
    </Modal>
  );
}

CreateHighlightDishModal.defaultProps = {
  width: "600px",
  title: "",
  message: "",
  buttonText: "Confirm",
};
