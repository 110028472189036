import { Box, Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Form from "../../../components/forms/form.component";
import routes from "../../../components/navigation/routes";
import BackdropLoading from "../../../components/notification/backdrop-loading.component";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import { useAppDispatch } from "../../../services/store";
import { updateUserDetail, userSelector } from "../../../services/user/user-slice.service";
import UserDetailEditForm from "./user-detail-edit-form.component";
import GreyBgCardContainer from "../../../components/utils/grey-bg-card-container.component";

const firstNameValidationSchema = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  type: Yup.string().required(),
});

const lastNameValidationSchema = Yup.object().shape({
  lastName: Yup.string().required().label("First Name"),
  type: Yup.string().required(),
});

const emailValidationSchema = Yup.object().shape({
  email: Yup.string().email().required().label("Email"),
  type: Yup.string().required(),
});

const genderValidationSchema = Yup.object().shape({
  gender: Yup.string().nullable().label("Gender"),
  type: Yup.string().required(),
});

const dobValidationSchema = Yup.object().shape({
  dateOfBirth: Yup.date().nullable().label("Date of birth"),
  type: Yup.string().required(),
});

const phoneValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required()
    .label("Phone number")
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{8}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Phone number is not valid.",
    ),
  type: Yup.string().required(),
});

export default function UserDetail() {
  const { showUserObj } = useSelector(userSelector);
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const userId = new URLSearchParams(location.search).get("userId");
  const [isLoading, setIsLoading] = useState(false);
  const [firstNameEditing, setFirstNameEditing] = useState(false);
  const [lastNameEditing, setLastNameEditing] = useState(false);
  const [phoneEditing, setPhoneEditing] = useState(false);
  const [emailEditing, setEmailEditing] = useState(false);
  const [genderEditing, setGenderEditing] = useState(false);
  const [dobEditing, setDobEditing] = useState(false);

  const onUpdateDetail = (values: any) => {
    setIsLoading(true);
    dispatch(updateUserDetail({ userId, ...showUserObj.data, ...values }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          if (values.type === "firstName") {
            setFirstNameEditing(false);
          } else if (values.type === "lastName") {
            setLastNameEditing(false);
          } else if (values.type === "phone") {
            setPhoneEditing(false);
          } else if (values.type === "email") {
            setEmailEditing(false);
          } else if (values.type === "gender") {
            setGenderEditing(false);
          } else if (values.type === "dob") {
            setDobEditing(false);
          }
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const renderContent = () => {
    if (showUserObj.status === "succeeded") {
      return (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: " 30px",
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              User Details
            </Typography>
            <Button
              onClick={() => navigate(routes.USER)}
              sx={{
                textTransform: "none",
                padding: "0px",
                ":hover": { backgroundColor: "transparent" },
              }}
            >
              <Typography>Back</Typography>
            </Button>
          </Box>

          <GreyBgCardContainer>
            <Grid container sx={{ paddingY: "20px" }} spacing={3}>
              <Grid item xs={12}>
                <Form
                  initialValues={{
                    type: "firstName",
                    firstName: showUserObj.data.firstName,
                  }}
                  validationSchema={firstNameValidationSchema}
                  onSubmit={onUpdateDetail}
                >
                  <UserDetailEditForm
                    name="firstName"
                    placeholder="Enter first name"
                    label="First Name"
                    isEditing={firstNameEditing}
                    setIsEditing={setFirstNameEditing}
                  />
                </Form>
              </Grid>

              <Grid item xs={12}>
                <Form
                  initialValues={{
                    type: "lastName",
                    lastName: showUserObj.data.lastName,
                  }}
                  validationSchema={lastNameValidationSchema}
                  onSubmit={onUpdateDetail}
                >
                  <UserDetailEditForm
                    name="lastName"
                    placeholder="Enter last name"
                    label="Last Name"
                    isEditing={lastNameEditing}
                    setIsEditing={setLastNameEditing}
                  />
                </Form>
              </Grid>

              <Grid item xs={12}>
                <Form
                  initialValues={{
                    type: "phone",
                    phoneNumber: showUserObj.data.phoneNumber,
                  }}
                  validationSchema={phoneValidationSchema}
                  onSubmit={onUpdateDetail}
                >
                  <UserDetailEditForm
                    name="phoneNumber"
                    placeholder="Enter contact number"
                    label="Contact Number"
                    isEditing={phoneEditing}
                    setIsEditing={setPhoneEditing}
                  />
                </Form>
              </Grid>

              <Grid item xs={12}>
                <Form
                  initialValues={{
                    type: "email",
                    email: showUserObj.data.email === null ? "" : showUserObj.data.email,
                  }}
                  validationSchema={emailValidationSchema}
                  onSubmit={onUpdateDetail}
                >
                  <UserDetailEditForm
                    name="email"
                    placeholder="Enter email"
                    label="Email"
                    isEditing={emailEditing}
                    setIsEditing={setEmailEditing}
                  />
                </Form>
              </Grid>

              <Grid item xs={12}>
                <Form
                  initialValues={{
                    type: "gender",
                    gender: showUserObj.data.gender,
                  }}
                  validationSchema={genderValidationSchema}
                  onSubmit={onUpdateDetail}
                >
                  <UserDetailEditForm
                    name="gender"
                    placeholder="Select gender"
                    label="Gender"
                    isEditing={genderEditing}
                    setIsEditing={setGenderEditing}
                  />
                </Form>
              </Grid>

              <Grid item xs={12}>
                <Form
                  initialValues={{
                    type: "dob",
                    dateOfBirth: showUserObj.data.dateOfBirth
                      ? dayjs(showUserObj.data.dateOfBirth)
                      : null,
                  }}
                  validationSchema={dobValidationSchema}
                  onSubmit={onUpdateDetail}
                >
                  <UserDetailEditForm
                    name="dateOfBirth"
                    placeholder="Select date"
                    label="Date of birth"
                    isEditing={dobEditing}
                    setIsEditing={setDobEditing}
                  />
                </Form>
              </Grid>
            </Grid>
          </GreyBgCardContainer>
        </>
      );
    }
    // return <StaffDetailLoader />;
  };

  return (
    <Grid item xs={12}>
      <BackdropLoading isLoading={isLoading} />
      {renderContent()}
    </Grid>
  );
}
