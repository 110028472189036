import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { postReq, setAxiosToken } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const login = createAsyncThunk("employees/login", async (payload: any) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/login`,
    payload,
  );
  return response;
});

export const resendVerificationCode = createAsyncThunk(
  "employees/resend_verification_code",
  async (payload: any) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/resend_verification_code`,
      payload,
    );
    return response;
  },
);

export const forgotPassword = createAsyncThunk(
  "employees/forgot_password",
  async (payload: any) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/forgot_password`,
      payload,
    );
    return response;
  },
);

export const resetPassword = createAsyncThunk("employees/reset_password", async (payload: any) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/reset_password`,
    payload,
  );
  return response;
});

export const logout = createAsyncThunk("employees/logout", async (payload: any) => {
  const response = await postReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/logout`,
    payload,
  );
  return response;
});

interface initialStateType {
  user: any;
  loginObj: RequestObject;
  logoutObj: RequestObject;
  forgotPasswordObj: RequestObject;
  resendVerificationCodeObj: RequestObject;
  resetPasswordObj: RequestObject;
}

const initialState: initialStateType = {
  user: null,
  loginObj: idleObject(),
  logoutObj: idleObject(),
  forgotPasswordObj: idleObject(),
  resendVerificationCodeObj: idleObject(),
  resetPasswordObj: idleObject(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetUser: (state) => {
      state.user = null;
      state.loginObj.data = null;
      setAxiosToken("");
      localStorage.removeItem("YiJiaEmployeeAuthToken");
      localStorage.removeItem("YiJiaEmployeeExpireAt");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loginObj.status = "pending";
      })
      .addCase(login.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.loginObj.status = "succeeded";
        state.loginObj.data = data;
        state.user = data;
        localStorage.setItem("YiJiaEmployeeAuthToken", data.authToken);
        // localStorage.setItem("YiJiaEmployeeExpireAt", data.auth.expireAt);
        setAxiosToken(data.authToken);
        state.loginObj.successMessage = message;
      })
      .addCase(login.rejected, (state, action) => {
        const { message } = action.error;

        localStorage.removeItem("YiJiaEmployeeAuthToken");
        // localStorage.removeItem("YiJiaEmployeeExpireAt");
        state.loginObj.status = "failed";
        state.loginObj.errorMessage = message;
      })
      .addCase(resendVerificationCode.pending, (state) => {
        state.resendVerificationCodeObj.status = "pending";
      })
      .addCase(resendVerificationCode.fulfilled, (state, action) => {
        const { message, data } = action.payload;

        state.resendVerificationCodeObj.status = "succeeded";
        state.resendVerificationCodeObj.successMessage = message;
        state.resendVerificationCodeObj.data = data;
      })
      .addCase(resendVerificationCode.rejected, (state, action) => {
        const { message } = action.error;

        state.resendVerificationCodeObj.status = "failed";
        state.resendVerificationCodeObj.errorMessage = message;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordObj.status = "pending";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        const { message, data } = action.payload;

        state.forgotPasswordObj.status = "succeeded";
        state.forgotPasswordObj.successMessage = message;
        state.forgotPasswordObj.data = data;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        const { message } = action.error;

        state.forgotPasswordObj.status = "failed";
        state.forgotPasswordObj.errorMessage = message;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordObj.status = "pending";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        const { message, data } = action.payload;

        state.resetPasswordObj.status = "succeeded";
        state.resetPasswordObj.successMessage = message;
        state.resetPasswordObj.data = data;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        const { message } = action.error;

        state.resetPasswordObj.status = "failed";
        state.resetPasswordObj.errorMessage = message;
      })
      .addCase(logout.pending, (state) => {
        state.logoutObj.status = "pending";
      })
      .addCase(logout.fulfilled, (state, action) => {
        const { message } = action.payload;

        state.logoutObj.status = "succeeded";
        state.logoutObj.successMessage = message;

        state.user = null;
        state.logoutObj.data = null;
        setAxiosToken(null);
        localStorage.removeItem("YiJiaEmployeeAuthToken");
        // localStorage.removeItem("YiJiaEmployeeExpireAt");
      })
      .addCase(logout.rejected, (state, action) => {
        const { message } = action.error;

        state.logoutObj.status = "failed";
        state.logoutObj.errorMessage = message;
      });
  },
});

export default authSlice.reducer;

// state
export const authSelector = (state: RootState) => state.auth;

export const { resetUser } = authSlice.actions;
