import { BrowserRouter, Route, Routes } from "react-router-dom";
import RestrictedRoute from "./restricted.route";
import CustomizedAppBar from "../../infrastructure/layout/appbar.layout";
import PrivateRoute from "./private.route";
import PublicRoute from "./public.route";
import routes from "./routes";
import LoginScreen from "../../features/auth/screens/login.screen";
import UserListScreen from "../../features/user/screens/user-list.screen";
import UserEditDetailScreen from "../../features/user/screens/user-edit-detail.screen";
import UserEditAddressScreen from "../../features/user/screens/user-edit-address.screen";
import OrderProductionSummaryScreen from "../../features/order/screens/order-production-summary.screen";
import OrderDetailsScreen from "../../features/order/screens/order-details.screen";
import OrderUserSubscriptionScreen from "../../features/order/screens/order-user-subscription.screen";
import OrderListDetailScreen from "../../features/order/screens/order-list-detail.screen";
import HighlightDishScreen from "../../features/highlight_dish/screens/highlight-dish.screen";
import FoodDishesListScreen from "../../features/food/screens/food-dishes-list.screen";
import FoodDishesEditScreen from "../../features/food/screens/food-dishes-edit.screen";
import FoodCalendarScreen from "../../features/food/calendar/screens/food-calendar.screen";
import FoodSetPackagesScreen from "../../features/food/set/screens/food-set-packages.screen";
import FoodSetPackagesCreateScreen from "../../features/food/set/screens/food-set-packages-create.screen";
import FoodSetPackagesEditScreen from "../../features/food/set/screens/food-set-packages-edit.screen";
import FoodSetItemListScreen from "../../features/food/set/item/screens/food-set-item-list.screen";
import DeliveryZoneScreen from "../../features/delivery-zone/screens/delivery-zone.screen";
import ImageBannerScreen from "../../features/image_banner/screens/image_banner.screen";

const index = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<CustomizedAppBar />}>
          <Route element={<PrivateRoute />}>
            <Route path="/user" element={<UserListScreen />} />
            <Route path="/user/edit/detail" element={<UserEditDetailScreen />} />
            <Route path="/user/edit/address" element={<UserEditAddressScreen />} />
            <Route path="/order/subscription" element={<OrderUserSubscriptionScreen />} />
            <Route path="/order/subscription/detail" element={<OrderListDetailScreen />} />
            <Route path="/order/production/summary" element={<OrderProductionSummaryScreen />} />
            <Route path="/order/details" element={<OrderDetailsScreen />} />
            <Route path="/food/dishes/list" element={<FoodDishesListScreen />} />
            <Route path="/food/dishes/edit" element={<FoodDishesEditScreen />} />
            <Route path="/food/calendar" element={<FoodCalendarScreen />} />
            <Route path="/food/set/packages" element={<FoodSetPackagesScreen />} />
            <Route path="/food/set/packages/edit" element={<FoodSetPackagesEditScreen />} />
            <Route path="/food/set/create" element={<FoodSetPackagesCreateScreen />} />
            <Route path="/food/set/item/list" element={<FoodSetItemListScreen />} />
            <Route path="/highlight_dish" element={<HighlightDishScreen />} />
            <Route path="/image_banner" element={<ImageBannerScreen />} />
            <Route path="/delivery/zone" element={<DeliveryZoneScreen />} />
            <Route path="/" element={<OrderUserSubscriptionScreen />} />
          </Route>
          <Route element={<PublicRoute />}></Route>
        </Route>

        <Route element={<RestrictedRoute />}>
          <Route path={routes.LOGIN} element={<LoginScreen />} />
          {/* <Route path={routes.SIGN_UP} element={<SignUpScreen />} /> */}
          {/* <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default index;
