import {
  Box,
  Grid,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import theme from "../../../infrastructure/theme";
import { FoodCreateBulkFormValues, FoodCreateFormValues } from "../type/type";
import CreateFoodOneBulk from "./create-food-bulk.component";
import CreateFoodOneOff from "./create-food-one-off.component";
import CreateFoodOneUpload from "./create-food-upload.component";

const ModalBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: theme.shape.borderSizes[1],
  boxShadow: "24px",
  paddingTop: "25px",
  paddingBottom: "25px",
  outline: "none",
  maxHeight: "700px",
  overflow: "scroll",
});

interface Props {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  width?: string | number;
  onCreateFood: (values: FoodCreateFormValues) => void;
  onCreateFoodBulk: (values: FoodCreateBulkFormValues) => void;
}

export default function CreateFoodModal({
  showModal,
  title,
  setShowModal,
  width,
  onCreateFood,
  onCreateFoodBulk,
}: Props) {
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [createType, setCreateType] = useState("one-off");

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal open={showModal} onClose={handleCancel}>
      <ModalBox sx={{ width: isMobile ? "360px" : width, paddingX: isMobile ? "25px" : "40px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ToggleButtonGroup
              value={createType}
              exclusive
              onChange={(e: any) => {
                setCreateType(e.target.value);
              }}
            >
              <ToggleButton value="one-off" sx={{ whiteSpace: "nowrap" }}>
                One-Off
              </ToggleButton>
              <ToggleButton value="bulk" sx={{ whiteSpace: "nowrap" }}>
                Bulk
              </ToggleButton>
              <ToggleButton value="upload" sx={{ whiteSpace: "nowrap" }}>
                Upload
              </ToggleButton>
            </ToggleButtonGroup>
            <TopTitleBox title={title} requireCloseButton={true} setShowModal={setShowModal} />
          </Grid>

          {createType === "one-off" && (
            <Grid item xs={12}>
              <CreateFoodOneOff onCreateFood={onCreateFood} />
            </Grid>
          )}

          {createType === "bulk" && (
            <Grid item xs={12}>
              <CreateFoodOneBulk onCreateFoodBulk={onCreateFoodBulk} />
            </Grid>
          )}
          {createType === "upload" && (
            <Grid item xs={12}>
              <CreateFoodOneUpload onCreateFoodBulk={onCreateFoodBulk} />
            </Grid>
          )}
        </Grid>
      </ModalBox>
    </Modal>
  );
}

CreateFoodModal.defaultProps = {
  width: "600px",
  title: "",
  message: "",
  buttonText: "Confirm",
};
