import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

interface Props {
  isLoading: boolean;
}

export default function Loading({ isLoading }: Props) {
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: 99 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
