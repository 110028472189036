import { Box, Button, Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BackgroundButton from "../../../components/button/background-button.component";
import BorderButton from "../../../components/button/border-button.component";
import FormPrice from "../../../components/forms/form-price.component";
import FormSelectFieldText from "../../../components/forms/form-select-field-text.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import FormTextField from "../../../components/forms/form-text-field.component";
import Form from "../../../components/forms/form.component";
import routes from "../../../components/navigation/routes";
import { useSnackBar } from "../../../components/notification/snackbar.context";
import GreyBgCardContainer from "../../../components/utils/grey-bg-card-container.component";
import Spacer from "../../../components/utils/spacer.component";
import {
  foodSelector,
  showFood,
  updateFood,
  updateFoodImage,
} from "../../../services/food/food-dishes-slice.service";
import { useAppDispatch } from "../../../services/store";
import { FoodEditFormValues, FoodEditImageFormValues } from "../type/type";
import ImageUpload from "../../../components/image/image-upload.component";
import PublishConfirmationModal from "../components/publish-confirmation-modal.component";
import { FormikProps } from "formik";

const SpaceBetweenBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginBottom: "10px",
});

const LabelContainer = styled(Box)({
  display: "flex",
  height: "41.56px",
  alignItems: "center",
  width: "150px",
});

const FormContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  width: "100%",
});

const validationSchema = Yup.object().shape({
  identifier: Yup.string().required().label("Dish Name"),
  type: Yup.string().required().label("Type"),
  status: Yup.string().required().label("Status"),
  priceRM: Yup.number().nullable().label("Price"),
});

const validationSchemaImage = Yup.object().shape({
  image: Yup.string().required().label("Image").nullable(),
});

const TYPES_LIST = [
  { id: "1", label: "Meat", value: "meat" },
  { id: "2", label: "Vegetables", value: "vege" },
  { id: "3", label: "Sides", value: "side" },
  { id: "4", label: "Carbs", value: "carb" },
  { id: "5", label: "Addons", value: "addon" },
];

const STATUS_LIST = [
  { id: "1", label: "Draft", value: "draft" },
  { id: "2", label: "Publish", value: "publish" },
];

export default function FoodDishesEditScreen() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<FoodEditFormValues>>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(800));
  const location = useLocation();
  const foodId = new URLSearchParams(location.search).get("foodId");
  const snackBar = useSnackBar();
  const { showFoodObj, updateFoodObj } = useSelector(foodSelector);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingImage, setIsEditingImage] = useState(false);
  const [filePreview, setFilePreview] = useState(location?.state?.image_path);
  const [onImageChange, setOnImageChange] = useState(false);
  const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);

  useEffect(() => {
    dispatch(showFood({ foodId }))
      .unwrap()
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  }, []);

  const onUpdateFood = (values: FoodEditFormValues) => {
    const amountCents = values.priceRM * 100;
    dispatch(updateFood({ foodId: foodId, amountCents, ...values }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setIsEditing(false);
          setShowPublishConfirmation(false);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const onUpdateFoodImage = (values: FoodEditImageFormValues) => {
    dispatch(updateFoodImage({ foodId: foodId, ...values }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          snackBar.createSnackBar({
            message: res.message,
            type: "success",
            open: true,
          });
          setIsEditingImage(false);
        }
      })
      .catch((err) => {
        snackBar.createSnackBar({
          message: err.message,
          type: "error",
          open: true,
        });
      });
  };

  const renderButton = () => {
    if (isEditing) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <BackgroundButton
            onClick={() => {
              setIsEditing(false);
            }}
            isLoading={updateFoodObj.status === "pending"}
          >
            Cancel
          </BackgroundButton>
          <Spacer position="left" size="s" />

          <BackgroundButton
            onClick={() => {
              formRef.current?.values.status === "publish" && showFoodObj.data?.status !== "publish"
                ? setShowPublishConfirmation(true)
                : formRef.current?.handleSubmit();
            }}
            isLoading={updateFoodObj.status === "pending"}
          >
            Save
          </BackgroundButton>
        </Box>
      );
    }
    return (
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <BorderButton width="50%" onClick={() => setIsEditing(true)}>
          Edit
        </BorderButton>
      </Box>
    );
  };

  const renderImageButton = () => {
    if (isEditingImage) {
      return (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <BackgroundButton
            onClick={() => {
              setIsEditingImage(false);
            }}
            isLoading={updateFoodObj.status === "pending"}
          >
            Cancel
          </BackgroundButton>
          <Spacer position="left" size="s" />

          <FormSubmitButton
            isLoading={updateFoodObj.status === "pending"}
            disabled={!onImageChange}
          >
            Save
          </FormSubmitButton>
        </Box>
      );
    }
    return (
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <BorderButton width="50%" onClick={() => setIsEditingImage(true)}>
          Edit
        </BorderButton>
      </Box>
    );
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          paddingX: isSmallScreen ? "15px" : theme.dimensions.ScreenPaddingX,
          paddingY: "50px",
        }}
      >
        <Grid item xs={12}>
          <SpaceBetweenBox>
            <Typography variant="h4" fontWeight="bold">
              {`Food ID: ${foodId}`}
            </Typography>
            <Button
              onClick={() => navigate(routes.FOOD_DISHES_LIST)}
              sx={{
                textTransform: "none",
                padding: "0px",
                ":hover": { backgroundColor: "transparent" },
              }}
            >
              <Typography>Back</Typography>
            </Button>
          </SpaceBetweenBox>
        </Grid>

        {showFoodObj.status === "succeeded" && (
          <Grid item sx={{ display: "flex", flexDirection: "column" }}>
            <Form
              initialValues={{
                image: showFoodObj.data?.image_path,
              }}
              validationSchema={validationSchemaImage}
              onSubmit={onUpdateFoodImage}
            >
              <Grid item xs={12}>
                <GreyBgCardContainer>
                  <Grid
                    container
                    columnSpacing={isSmallScreen ? 1 : 10}
                    rowSpacing={isSmallScreen ? 1 : 3}
                  >
                    <Grid item xs={12}>
                      <SpaceBetweenBox>
                        <Typography variant="h5" fontWeight="bold">
                          Image
                        </Typography>
                        {renderImageButton()}
                      </SpaceBetweenBox>
                    </Grid>

                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        {isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Image</Typography>
                          </LabelContainer>
                        )}
                        <FormContainer>
                          {!isSmallScreen && (
                            <LabelContainer>
                              <Typography fontWeight="bold">Image</Typography>
                            </LabelContainer>
                          )}
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Box sx={{ width: "100%" }}>
                              <ImageUpload
                                name="image"
                                filePreview={filePreview}
                                setFilePreview={setFilePreview}
                                disabled={!isEditingImage}
                                setOnImageChange={setOnImageChange}
                              />
                            </Box>
                          </Box>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </GreyBgCardContainer>
              </Grid>
            </Form>

            <Form
              initialValues={{
                identifier: showFoodObj.data?.identifier,
                type: showFoodObj.data?.type,
                status: showFoodObj.data?.status,
                priceRM: showFoodObj.data?.price,
              }}
              innerRef={formRef}
              validationSchema={validationSchema}
              onSubmit={onUpdateFood}
            >
              <PublishConfirmationModal
                showModal={showPublishConfirmation}
                setShowModal={setShowPublishConfirmation}
                title="Publish"
                message={`Once published, the status of the dish can no longer be updated.`}
                buttonText="Continue"
              />
              <Grid item xs={12}>
                <GreyBgCardContainer>
                  <Grid
                    container
                    columnSpacing={isSmallScreen ? 1 : 10}
                    rowSpacing={isSmallScreen ? 1 : 3}
                  >
                    <Grid item xs={12}>
                      <SpaceBetweenBox>
                        <Typography variant="h5" fontWeight="bold">
                          Detail
                        </Typography>
                        {renderButton()}
                      </SpaceBetweenBox>
                    </Grid>

                    <Grid item container spacing={2}>
                      <Grid item xs={12}>
                        {isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Dish Name</Typography>
                          </LabelContainer>
                        )}
                        <FormContainer>
                          {!isSmallScreen && (
                            <LabelContainer>
                              <Typography fontWeight="bold">Dish Name</Typography>
                            </LabelContainer>
                          )}
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Box sx={{ width: "100%" }}>
                              <FormTextField
                                name="identifier"
                                label="Dish Name"
                                disabled={!isEditing}
                                isTransparent={!isEditing}
                              />
                            </Box>
                          </Box>
                        </FormContainer>
                      </Grid>

                      <Grid item xs={12}>
                        {isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Type</Typography>
                          </LabelContainer>
                        )}
                        <FormContainer>
                          {!isSmallScreen && (
                            <LabelContainer>
                              <Typography fontWeight="bold">Type</Typography>
                            </LabelContainer>
                          )}
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Box sx={{ width: "100%" }}>
                              <FormSelectFieldText
                                selections={TYPES_LIST}
                                name="type"
                                disabled={!isEditing}
                                isTransparent={!isEditing}
                              />
                            </Box>
                          </Box>
                        </FormContainer>
                      </Grid>

                      <Grid item xs={12}>
                        {isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Status</Typography>
                          </LabelContainer>
                        )}
                        <FormContainer>
                          {!isSmallScreen && (
                            <LabelContainer>
                              <Typography fontWeight="bold">Status</Typography>
                            </LabelContainer>
                          )}
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Box sx={{ width: "100%" }}>
                              <FormSelectFieldText
                                selections={STATUS_LIST}
                                name="status"
                                disabled={!isEditing || showFoodObj.data?.status === "publish"}
                                isTransparent={!isEditing}
                              />
                            </Box>
                          </Box>
                        </FormContainer>
                      </Grid>

                      <Grid item xs={12}>
                        {isSmallScreen && (
                          <LabelContainer>
                            <Typography fontWeight="bold">Price</Typography>
                          </LabelContainer>
                        )}
                        <FormContainer>
                          {!isSmallScreen && (
                            <LabelContainer>
                              <Typography fontWeight="bold">Price</Typography>
                            </LabelContainer>
                          )}
                          <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
                            <Box sx={{ width: "100%" }}>
                              <FormPrice
                                placeholder="00.00"
                                name="priceRM"
                                disabled={!isEditing}
                                isWhiteBg={isEditing}
                              />
                            </Box>
                          </Box>
                        </FormContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </GreyBgCardContainer>
              </Grid>
            </Form>
          </Grid>
        )}
      </Grid>
    </>
  );
}
