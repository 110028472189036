import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { delReq, getReq, postReq, putReq } from "../api.services";
import { idleObject } from "../idle-object";
import { RequestObject, RootState } from "../store";

export const getHighlightDishes = createAsyncThunk("employees/highlight_dishes/index", async () => {
  const response = await getReq(
    `${process.env.REACT_APP_API_PRIVATE_V1}/employees/highlight_dishes`,
  );
  return response;
});

export const createHighlightDishes = createAsyncThunk(
  "employees/highlight_dishes/create",
  async (payload: any) => {
    const response = await postReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/highlight_dishes`,
      payload,
    );
    return response;
  },
);

export const deleteHighlightDish = createAsyncThunk(
  "employees/highlight_dishes/delete",
  async (payload: any) => {
    const response = await delReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/highlight_dishes`,
      payload,
    );
    return response;
  },
);

export const reorderHighlightDish = createAsyncThunk(
  "employees/highlight_dishes/reorder",
  async (payload: any) => {
    const { highlightDishId } = payload;
    const response = await putReq(
      `${process.env.REACT_APP_API_PRIVATE_V1}/employees/highlight_dishes/${highlightDishId}/reorder`,
      payload,
    );
    return response;
  },
);

interface initialStateType {
  getHighlightDishesObj: RequestObject;
  createHighlightDishesObj: RequestObject;
  deleteHighlightDishObj: RequestObject;
  reorderHighlightDishObj: RequestObject;
}

const initialState: initialStateType = {
  getHighlightDishesObj: idleObject(),
  createHighlightDishesObj: idleObject(),
  deleteHighlightDishObj: idleObject(),
  reorderHighlightDishObj: idleObject(),
};

const highlightDishSlice = createSlice({
  name: "highlightDish",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHighlightDishes.pending, (state) => {
        state.getHighlightDishesObj.status = "pending";
      })
      .addCase(getHighlightDishes.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.getHighlightDishesObj.status = "succeeded";
        state.getHighlightDishesObj.data = data;
        state.getHighlightDishesObj.successMessage = message;
      })
      .addCase(getHighlightDishes.rejected, (state, action) => {
        const { message } = action.error;

        state.getHighlightDishesObj.status = "failed";
        state.getHighlightDishesObj.errorMessage = message;
      })
      .addCase(createHighlightDishes.pending, (state) => {
        state.createHighlightDishesObj.status = "pending";
      })
      .addCase(createHighlightDishes.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.createHighlightDishesObj.status = "succeeded";
        state.createHighlightDishesObj.data = data;
        state.getHighlightDishesObj.data = data;
        state.createHighlightDishesObj.successMessage = message;
      })
      .addCase(createHighlightDishes.rejected, (state, action) => {
        const { message } = action.error;

        state.createHighlightDishesObj.status = "failed";
        state.createHighlightDishesObj.errorMessage = message;
      })
      .addCase(deleteHighlightDish.pending, (state) => {
        state.deleteHighlightDishObj.status = "pending";
      })
      .addCase(deleteHighlightDish.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.deleteHighlightDishObj.status = "succeeded";
        state.deleteHighlightDishObj.data = data;
        state.getHighlightDishesObj.data = data;
        state.deleteHighlightDishObj.successMessage = message;
      })
      .addCase(deleteHighlightDish.rejected, (state, action) => {
        const { message } = action.error;

        state.deleteHighlightDishObj.status = "failed";
        state.deleteHighlightDishObj.errorMessage = message;
      })
      .addCase(reorderHighlightDish.pending, (state) => {
        state.reorderHighlightDishObj.status = "pending";
      })
      .addCase(reorderHighlightDish.fulfilled, (state, action) => {
        const { data, message } = action.payload;

        state.reorderHighlightDishObj.status = "succeeded";
        state.reorderHighlightDishObj.data = data;
        state.getHighlightDishesObj.data = data;
        state.reorderHighlightDishObj.successMessage = message;
      })
      .addCase(reorderHighlightDish.rejected, (state, action) => {
        const { message } = action.error;

        state.reorderHighlightDishObj.status = "failed";
        state.reorderHighlightDishObj.errorMessage = message;
      });
  },
});

export default highlightDishSlice.reducer;

// state
export const highlightDishSelector = (state: RootState) => state.highlightDish;
