import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { FormikValues, useFormikContext } from "formik";
import theme from "../../infrastructure/theme";

export type selections = {
  label: string;
  id: number | string;
  value: string | number;
};

interface Props {
  name: string;
  placeholder?: string;
  disabled?: boolean;
  selections: selections[];
  submitOnChange?: boolean;
  isTransparent?: boolean;
  value?: string;
}

function FormSelectFieldText({
  name,
  placeholder,
  selections,
  disabled,
  submitOnChange,
  isTransparent = false,
  value,
}: Props) {
  const { setFieldValue, values, setFieldTouched, touched, errors, handleSubmit } =
    useFormikContext<FormikValues>();
  const showError = touched[name] && typeof errors[name] === "string";

  const handleChange = (e: SelectChangeEvent) => {
    setFieldValue(name, e.target.value);
    if (submitOnChange) {
      handleSubmit();
    }
  };

  return (
    <FormControl fullWidth>
      <Select
        size="small"
        onBlur={() => setFieldTouched(name)}
        displayEmpty
        disabled={disabled}
        defaultValue=""
        value={value ? value : values[name] === null ? "" : values[name]}
        onChange={handleChange}
        error={showError}
        sx={{ bgcolor: isTransparent ? "transparent" : theme.palette.common.white }}
      >
        <MenuItem value="" disabled>
          <Typography>{placeholder}</Typography>
        </MenuItem>
        {selections.map((selection) => (
          <MenuItem value={selection.value} key={`${selection.id}-${selection.value}`}>
            {selection.label}
          </MenuItem>
        ))}
      </Select>
      {showError && <FormHelperText error>{String(errors[name])}</FormHelperText>}
    </FormControl>
  );
}

FormSelectFieldText.defaultProps = {
  disabled: false,
  submitOnChange: false,
  isTransparent: false,
};

export default FormSelectFieldText;
